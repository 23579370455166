<!--================ Home Banner Area =================-->
<div class="hero-home">
    <div class="container header-div" id="header-div">
        <!-- Alert element to show feedback to user if necessary -->
        <!--    <div class="main-alert-wrapper" id="main-alert-wrapper">-->
        <!--        <div id="main-page-alert" role="alert" [hidden]="!showAlert" class="alert {{alertType}} main-page-alert">-->
        <!--            {{alertMessage}} <span class="main-alert-close-icon" id="main-alert-close-icon" (click)="closeAlert()">X</span></div>-->
        <!--    </div>-->

        <div class="row">
            <div class="col-md-12">
                <div class="banner">
                    <h1 class="banner__text">Start Connecting Here</h1>
                    <div class="start-search-btn-group">
                        <button type="button" (click)="goToSearchMap()" class="start-search__btn">Search Organizations</button>
                        <button type="button" (click)="goToResourceSearch()" class="start-search__btn">Search Resources</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--================    END HOME BANNER AREA      =================-->

<!--================ BEGIN MAIN CONTENT AREA =================-->
<div class="container-fluid">
    <!--================ BEGIN MISSION STATEMENT AREA =================-->
    <div class="row fluid-row-mission">
        <div class="container">
            <div class="row mission-statement">
                <div class="hidden-xs quote-spacer"></div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 healthEqQuote-container">
                    <blockquote class="healthEqQuote quote" id="quote">
        <!--                TODO: get these quote icons-->
        <!--                <i class="fas fa-quote-left"></i>-->
                        <p>
                            "<span class="colored colored-large">Health Equity</span> is the active assurance that
                            <span class="colored"> everyone is afforded the opportunities </span>
                            essential to attaining their
                            <span class="colored"> highest level of health</span>"
                        </p>
        <!--        <i class="fas fa-quote-right"></i>-->
                        <footer class="blockquote-footer pull-right cpha" style="">
                            Colorado Public Health Association
                        </footer>
                    </blockquote>
                </div>
            </div>
        </div>
    </div>
    <!--================ END MISSION STATEMENT AREA =================-->
<!--    <div class="col-xs-12 section-break section-break-left"></div>-->

    <!--================ BEGIN TIPS AREA =================-->
    <div class="row fluid-row-tips">
        <div class="container">
            <div class="row" style="padding: 10px; display: flex; justify-content: center;">
                <p class="tips-header">Tips:</p>
                <ul style="display: inline-block;">
                    <li class="section-desc tip"><b>Are you new here? </b>Browse <a routerLink="/map-search">organizations</a> and <a routerLink="/resources">resources</a>.</li>
                    <li class="section-desc tip"><b>Do you work for a health equity organization? </b><a routerLink="/map-search">Search for it here to claim it.</a></li>
                    <li class="section-desc tip"><b>Do you want to add a new organization? </b><a routerLink="/map-search">Search to see if it already exists before adding it.</a></li>
                    <li class="section-desc tip"><b>Do you want to manage an existing organization you have claimed? </b><a routerLink="/map-search">Search for it to access its details.</a></li>
                </ul>
            </div>
        </div>
    </div>
    <!--================ END TIPS AREA =================-->

    <!--================ BEGIN PURPOSE AREA =================-->
    <div class="row fluid-row-purpose">
        <div class="container">
            <div class="row purpose">
                <!-- <span class="purpose-outlines accordion">
                    <p class="big-purpose">Purpose</p>
                    <accordion class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <accordion-group heading="Provide a platform">
                            <p class="purpose-section"><u>Provide a platform</u> through which practitioners in many contexts can connect,
                                share resources and coordinate racial and health equity advocacy efforts</p>
                        </accordion-group>
                        <accordion-group heading="Emphasize the primary role of race">
                            <p class="purpose-section"><u>Emphasize the primary role of race</u> in creating and maintaining the social,
                            economic and environmental conditions that produce health inequities</p>
                        </accordion-group>
                        <accordion-group heading="Facilitate and amplify collective action">
                            <p class="purpose-section"><u>Facilitate and amplify collective action</u> by organizations across the field
                            to dismantle the systems that perpetuate racial and health inequities</p>
                        </accordion-group>
                        <accordion-group heading="Communicate news and announcements">
                            <p class="purpose-section"><u>Communicate news and announcements</u> about events, advocacy alerts,
                            funding and employment opportunities related to racial and health inequity</p>
                        </accordion-group>
                        <accordion-group heading="Educate the public and key stakeholders">
                            <p class="purpose-section"><u>Educate the public and key stakeholders</u> about racial and health inequity
                            and engage them in advocacy efforts to eliminate inequities</p>
                        </accordion-group>
                    </accordion>
                </span> -->
                <span>
                    <div class="column">
                        <p class="big-purpose">Purpose</p>

                        <p class="purpose-section"><u>Provide a platform</u> through which practitioners in many contexts can connect,
                            share resources and coordinate racial and health equity advocacy efforts</p>

                        <p class="purpose-section"><u>Emphasize the primary role of race</u> in creating and maintaining the social,
                        economic and environmental conditions that produce health inequities</p>
                    </div>

                    <div class="column">
                        <p class="purpose-section"><u>Facilitate and amplify collective action</u> by organizations across the field
                            to dismantle the systems that perpetuate racial and health inequities</p>
                        <p class="purpose-section"><u>Communicate news and announcements</u> about events, advocacy alerts,
                        funding and employment opportunities related to racial and health inequity</p>
                        <p class="purpose-section"><u>Educate the public and key stakeholders</u> about racial and health inequity
                        and engage them in advocacy efforts to eliminate inequities</p>
                    </div>
                </span>
            </div>
        </div>
    </div>
    <!--================ END PURPOSE AREA =================-->

    <!--================ BEGIN HEALTH EQ ABOUT AREA =================-->
    <div class="row fluid-row-about">
        <div class="container">
            <div class="row about">
<!--                <div class="col-xs-12 col-sm-12 about__header">-->
<!--                    Health Equity Community Exchange-->
<!--                </div>-->
                <div class="col-xs-12 about__content">
<!--                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 image-div about__image-div">-->
<!--&lt;!&ndash;                        <img class ="image resources__image" src="../../assets/images/compLogos/Health_equity_slideshow.gif" alt ="firstPicture" />&ndash;&gt;-->
<!--                    </div>-->
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 video-div about__video-div">
                        <video class="video resources__video" controls autoplay muted src="/832165a8bd7342472b065dfbb87978b8.mp4">
                        </video>
                    </div>
                    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 text-justify">
                        <p class="section-desc" id="about-us__text-box">
                            The Health Equity Community Exchange is a co-produced movement infrastructure for health equity advocacy powered by the contribution
                            of organizations across Colorado. The site is designed to connect and amplify the work of health equity focused entities including
                            non-profit organizations, public health agencies, policy institutes, foundations and research centers for the purpose of sharing
                            knowledge and creating cross-sectoral collaboration opportunities.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--================    END ABOUT AREA    =================-->

    <!--================ BEGIN WHAT'S NEW AREA =================-->
    <div class="row fluid-row-whats-new">
        <div class="container">
            <div class="row whats-new">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 whats-new__header">
                    <div class="whats-new__header--centered">What's New?</div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 whats-new__subtitle">
                    Latest announcements, opportunities and news of interest
                </div>

                <div class="carousel-container">
                    <p-carousel [value]="cards" [numVisible]="3" [numScroll]="3" [circular]="false" [responsiveOptions]="responsiveOptions" class="p-element">
                        <ng-template let-card pTemplate="news-card">
                            <div class="card-item">
                                <div class="card-item__content">
                                    <div class="news-card-img">
                                        <img alt="Card" [src]="card.imgUrl" loading="lazy">
                                    </div>
                                    <div class="card__text-box">
                                        <quill-view [content]="card.text" format="html" theme="snow"></quill-view>
                                        <a href="{{card.btn_link}}" target="_blank" type="button" class="card__button">{{card.btn_text}}</a>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-carousel>
                </div>
            </div>
        </div>
    </div>
    <!--================ END WHAT'S NEW AREA =================-->

    <!--================ BEGIN UPCOMING EVENTS AREA =================-->
<!--    <div class="row fluid-row-upcoming-events">-->
<!--        <div class="container">-->
<!--            <div class="row upcoming-events">-->
<!--                <div class="col-xs-12 col-sm-12 section-header section-header&#45;&#45;right upcomingEvents upcoming-events__header">-->
<!--                    <a class="upcoming-events&#45;&#45;centered " routerLink="/events">-->
<!--                        <h4 class="section-header upcoming-events__header">Upcoming Events</h4>-->
<!--                    </a>-->
<!--                </div>-->
<!--                <div class="col-12 row upcomingEvents-list upcoming-events__content">-->
<!--                    <div class="col-12 col-sm-6 col-md-6 col-lg-3 upcomingEvent upcoming-events__event" *ngFor="let event of allEvents">-->
<!--                        <div class="circle-date">-->
<!--                            <div class="circle-date__text">-->
<!--                                {{event.date | date: 'MMM d'}}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div *ngIf="event.title" class="events-container__event-title">-->
<!--                            {{event.title}}-->
<!--                        </div>-->
<!--                        <div *ngIf="event.time" class="events-container__event-time">-->
<!--                            {{event.time}}-->
<!--                        </div>-->
<!--                        <div *ngIf="event.location" class="events-container__event-location">-->
<!--                            {{event.location}}-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
    <!--================ END UPCOMING EVENTS AREA =================-->
    <!--================     BEGIN CALLS TO ACTION AND JOBS AREA      =================-->
<!--    <div class="row fluid-row-action-jobs">-->
<!--        <div class="container">-->
<!--            <div class="row action-jobs">-->
<!--                &lt;!&ndash;================ BEGIN CALLS TO ACTION AREA =================&ndash;&gt;-->
<!--                <div class="col-xs-12 col-sm-12 col-md-6 calls-to-action">-->
<!--                    <h4 class="section-header calls__header"><span class="calls&#45;&#45;centered">Calls to Action</span></h4>-->
<!--                    <div class="col-12 col-sm-12 calls__opp" *ngFor ="let calls of callsToAction">-->
<!--                        <div class="col-xs-2 col-sm-4 calls__img-div">-->
<!--                            <div class="calls__image">-->
<!--                                <img class="orgImage" src="{{calls.image}}">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-xs-10 col-sm-8 calls__text">-->
<!--                            <h3 class="calls__title">{{calls.title}}</h3>-->
<!--                            <p class="calls__desc">{{calls.desc}}</p>-->
<!--                            <a class="learn-more calls__learn-more">Learn more...</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;================ END CALLS TO ACTION AREA =================&ndash;&gt;-->
<!--                &lt;!&ndash;================      BEGIN JOBS AREA     =================&ndash;&gt;-->
<!--                <div class="col-xs-12 col-sm-12 col-md-6 jobs">-->
<!--                    <h4 class="section-header jobs__header"><span class="jobs&#45;&#45;centered">Jobs</span></h4>-->
<!--                    <div class="col-xs-12 col-sm-12 job__opp" *ngFor ="let job of jobs">-->
<!--                        <div class="col-xs-4 col-sm-4 job__img-div">-->
<!--                            <div class="job__img">-->
<!--                                <img class="orgImage" src="{{job.image}}">-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="col-xs-8 col-sm-8 job__text">-->
<!--                            <h3 class="job__title">{{job.title}}</h3>-->
<!--                            <p class="job__desc">{{job.desc}}</p>-->
<!--                            <a class="learn-more job__learn-more">Learn more...</a>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                &lt;!&ndash;================   END JOBS AREA   =================&ndash;&gt;-->
<!--            </div>-->
<!--        </div>-->
<!--    </div>-->
    <!--================ END CALLS TO ACTION AND JOBS AREA =================-->
</div>
<!--================ END MAIN CONTENT AREA =================-->
