<div class="container-fluid standard-div standard-div-light-gray">
    <div class="about-team-wrapper">
        <h2>Meet the Tech Team</h2>

        <div class="about-team-description-wrapper">
            <p class="about-team-description-text">
                This website is built and maintained by computer science
                students at the University of Denver. The members of the Web
                Development Projects course at the University have the
                opportunity to come together to collaborate as a software
                development team and work on a real world project.
            </p>
        </div>
        <section class="leaderTeam">
            <h3 class="about-team-role-title">
                Leader Team
            </h3>
            <div class="about-team-leaderteam-block">
                <ul class="row">
                    <li *ngFor="let member of leaderTeam" class="col-md-6 col-lg-6 about-team-member-block">
                        <a href="{{member.link}}" target="_blank">
                            <img src="https://secure.gravatar.com/avatar/{{member.id}}?" class="about-team-member-avatar">
                        </a>
                        <a href="{{member.link}}" target="_blank">
                            <p class="about-team-member-name">
                                {{member.name}}
                            </p>
                        </a>
                        <p class="about-team-member-bio">
                            {{member.bio}}
                        </p>
                    </li>
                </ul>
            </div>
        </section>
        <section>
            <h3 class="about-team-role-title">
                UI Team - Design
            </h3>
            <div class="about-team-subteam-block">
                <ul class="row designTeam">
                    <li *ngFor="let member of designTeam" class="col-md-6 col-lg-3 about-team-member-block">
                        <a href="{{member.link}}" target="_blank">
                            <img src="https://secure.gravatar.com/avatar/{{member.id}}?" class="about-team-member-avatar">
                        </a>
                        <a href="{{member.link}}" target="_blank">
                            <p class="about-team-member-name">
                                {{member.name}}
                            </p>
                        </a>
                        <p class="about-team-member-bio">
                            {{member.bio}}
                        </p>
                    </li>
                </ul>
            </div>
        </section>

        <br>

        <section>
            <h3 class="about-team-role-title">
                UI Team - Development
            </h3>
            <div class="about-team-subteam-block">
                <ul class="row devTeam">
                    <li *ngFor="let member of devTeam" class="col-md-6 col-lg-3 about-team-member-block">
                        <a href="{{member.link}}" target="_blank">
                            <img src="https://secure.gravatar.com/avatar/{{member.id}}?" class="about-team-member-avatar">
                        </a>
                        <a href="{{member.link}}" target="_blank">
                            <p class="about-team-member-name">
                                {{member.name}}
                            </p>
                        </a>
                        <p class="about-team-member-bio">
                            {{member.bio}}
                        </p>
                    </li>
                </ul>
            </div>
        </section>

        <section>
            <h3 class="about-team-role-title">
                Server Team
            </h3>
            <div class="about-team-subteam-block">
                <ul class="row serverTeam">
                    <li *ngFor="let member of serverTeam" class="col-md-6 col-lg-3 about-team-member-block">
                        <a href="{{member.link}}" target="_blank">
                            <img src="https://secure.gravatar.com/avatar/{{member.id}}?" class="about-team-member-avatar">
                        </a>
                        <a href="{{member.link}}" target="_blank">
                            <p class="about-team-member-name">
                                {{member.name}}
                            </p>
                        </a>
                        <p class="about-team-member-bio">
                            {{member.bio}}
                        </p>
                    </li>
                </ul>
            </div>
        </section>

    </div>
</div>
