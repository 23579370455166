import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { AboutTeamComponent } from './about-team.component';

export const ROUTES: Routes = [
    {
        path: 'about-team',
        component: AboutTeamComponent
    }
];

@NgModule({
    imports: [BrowserModule, RouterModule.forChild(ROUTES)],
    declarations: [AboutTeamComponent],

    exports: [AboutTeamComponent]
})
export class AboutTeamModule {}
