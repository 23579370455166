import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { FormControl } from '@angular/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageContentService} from '../../../services/page-content/page-content.service';
import { PageContent} from '../../../components/interfaces/PageContent';


@Component({
    selector: 'list-page-content',
    templateUrl: './list-page-content.html',
    styleUrls: ['./list-page-content.scss']
})

export class ListPageContentComponent implements OnInit {

    pageContent: PageContent[] = [];
    pageName;
    pageContentName;
    static parameters = [Router, ActivatedRoute, PageContentService];
    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public pageContentService: PageContentService) {}

    ngOnInit() {
        if (this.route) {
            this.route.paramMap.subscribe(params => {
                this.pageName = params.get('pageName');
            });
        }
        this.pageContentService.getContentByPage(this.pageName).subscribe((pageContent: any) => {
            this.pageContent = pageContent as PageContent[];
        });
    }
}
