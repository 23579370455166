import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map, tap} from 'rxjs/operators';
import {PageContent, Card, Text} from '../../components/interfaces/PageContent';
import {Image} from '../../components/interfaces/Image';

@Injectable({
    providedIn: 'root'
})

//Methods to communicate with the resources API
export class PageContentService {
    public baseUrl = '/api/pagecontents';
    static parameters = [HttpClient];
    constructor(public http: HttpClient) { }

    // getAllPageContent(): Observable<PageContentType[]> {
    //     return this.http.get('/api/pagecontent/') as Observable<PageContentType[]>;
    // }

    getAllPageContent() {
        return this.http.get(this.baseUrl);
    }

    create(pageContent) {
        return this.http.post(this.baseUrl, pageContent);
    }

    remove(pageContent) {
        return this.http.delete(`/api/pagecontents/${pageContent.id || pageContent._id}`)
            .pipe(map(() => pageContent));
    }

    update(pageContent: PageContent) {
        return this.http.put(this.baseUrl + '/' + pageContent._id, pageContent, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }).pipe(map( () => pageContent));
    }

    getContentByID(id): Observable<PageContent | Card | Text> {
        return this.http.get(`/api/pagecontents/${id}`) as Observable<PageContent | Card | Text>;
    }

    getContentByPage(pageName: string) {
        return this.http.get(this.baseUrl + '/page/' + pageName) as Observable<PageContent | Card | Text>;
    }

    //TODO: Refactor this
    // getContentByUrl(url) {
    //     return this.http.get(this.baseUrl + '/search-by-url/' + url);
    // }

    upsertImage(image: File, newName: string): Observable<Image> {
        console.log(image.name);
        const formData = new FormData();
        newName = newName.replace(/\s/g, '-');
        formData.append('file', image, newName);

        return this.http.put('/api/images', formData) as Observable<Image>;
    }

    download(fileKey): Observable<any> {
        return this.http.get(`api/images/${fileKey}`, { responseType: 'blob' });
    }

    getImageByID(id): Observable<Response> {
        return this.http.get('/api/images/' + id, {
            params: {
                imageId: id
            }
        }) as Observable<Response>;
    }
}
