import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { RouterModule } from '@angular/router';
import { AuthModule } from './auth/auth.module';

import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { SideMenuComponent} from './side-menu/side-menu.component';
import { OauthButtonsComponent } from './oauth-buttons/oauth-buttons.component';
import { QuillMaterialComponent } from './quill-material/quill-material.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { PhoneNormalizationPipe } from './pipes/phoneNormalization';

@NgModule({
    imports: [
        CommonModule,
        CollapseModule.forRoot(),
        AuthModule,
        RouterModule,
        AccordionModule,
        MatExpansionModule,
        MatIconModule,
        BsDropdownModule.forRoot()
    ],
    declarations: [
        NavbarComponent,
        FooterComponent,
        SideMenuComponent,
        OauthButtonsComponent,
        QuillMaterialComponent,
        PhoneNormalizationPipe,
    ],
    exports: [
        CommonModule,
        NavbarComponent,
        FooterComponent,
        SideMenuComponent,
        OauthButtonsComponent,
        QuillMaterialComponent,
        PhoneNormalizationPipe
    ]
})
export class DirectivesModule {}
