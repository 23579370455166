import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from './user.service';

@Injectable()
export class OrgAdminGuard implements CanActivate {
    router;
    userService;


    static parameters = [UserService, Router];
    constructor(userService: UserService, router: Router) {
        this.userService = userService;
        this.router = router;
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let canActivate = false;
        return this.userService.getMyOrg()
            .toPromise()
            .then((res) => {
                const current_org_id = localStorage.getItem('current_org_id');
                const organizations = res.organizations;
                for (const org of organizations) {
                    if (current_org_id === org._id) {
                        canActivate = true;
                    }
                }
                return this.userService.get().toPromise();
            })
            .then((foundUser) => {
                if (foundUser && foundUser.role === 'admin') {
                    canActivate = true;
                }
                if (canActivate) {
                    return true;
                } else {
                    this.router.navigate(['/home'], {
                        queryParams: {
                            error: 'unauthorized'
                        }
                    });
                    return false;
                }
            })
            .catch((err) => {
                this.router.navigate(['/login'], {
                    queryParams: {
                        return: state.url,
                        alert: 'auth-required'
                    }
                });
                return false;
            });
    }
}
