import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {OrgClaimRequest} from '../../components/interfaces/OrgClaimRequest';

@Injectable({
    providedIn: 'root'
})

//methods to communicate with the organizations claim API
export class OrganizationClaimService {
    public baseUrl = '/api/claims';
    static parameters = [HttpClient];
    constructor(
        public http: HttpClient
    ) {}

    getAllOrgClaims() {
        return this.http.get(this.baseUrl);
    }

    getOrgClaimByUrl(url) {
        return this.http.get(this.baseUrl + '/' + url);
    }

    getOrgClaimByStatus(status){
        return this.http.get(`${this.baseUrl}?status=${status}`);
    }

    getClaimsByOrg(orgId, status){
        return this.http.get(`/api/organizations/${orgId}/claims?status=${status}`);
    }

    remove(claim) {
        return this.http.delete(`${this.baseUrl}/${claim.id || claim._id}`);
    }

    create(claim: OrgClaimRequest): Promise<OrgClaimRequest> {
        return this.http
            .post<OrgClaimRequest>(`${this.baseUrl}`, claim)
            .toPromise();
    }

    update(claim) {
        return this.http.put(this.baseUrl + '/' + claim._id, claim, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        }).pipe(
            tap(_ =>
                console.log(`updated claim=${claim.id}`))
        );
    }

    approve(claim){
        console.log(claim);
        return this.http.put(`${this.baseUrl}/${claim._id}/approve`,claim, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        });
    }

    deny(claim){
        return this.http.put(`${this.baseUrl}/${claim._id}/reject`,claim, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        });
    }

    undo(claim){
        console.log(claim);
        return this.http.put(`${this.baseUrl}/${claim._id}/undo`,claim, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        });
    }
}


