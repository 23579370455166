import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {ResourceService} from '../../services/resource/resource.service';
import {ActivatedRoute, Router} from '@angular/router';

import {
    faArrowRight,
    faBalanceScale,
    faClinicMedical,
    faHandsHelping,
    faHeartbeat,
    faPodcast,
    faSeedling,
    faUniversity,
    faUsers
    // @ts-ignore
} from '@fortawesome/free-solid-svg-icons';
import {Category} from './category';
import {Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

interface ResourceResponse {
    numPages: string;
    results: any;
}

@Component({
    selector: 'resources',
    templateUrl: './resources.html',
    styleUrls: ['./resources.scss'],
})


export class ResourcesComponent implements OnInit {
    public isStartPage: boolean;
    static parameters = [ResourceService, ActivatedRoute, Router];

    icons = {
        balance: faBalanceScale,
        hands: faHandsHelping,
        university: faUniversity,
        seedling: faSeedling,
        users: faUsers,
        heartbeat: faHeartbeat,
        clinic: faClinicMedical,
        podcast: faPodcast,
        arrow: faArrowRight
    };
    //Cards for the different resource types
    issueAreas: Category[] = [
        {
            icon: this.icons.balance,
            area: 'What is Health Equity?',
            subCategories: []
        },
        {
            icon: this.icons.hands,
            area: 'Organizational Development',
            subCategories: []
        },
        {
            icon: this.icons.university,
            area: 'Public Policy, Equity and Health',
            subCategories: []

        },
        {
            icon: this.icons.users,
            area: 'Racial Health Equity',
            subCategories: []

        },
        {
            icon: this.icons.seedling,
            area: 'Structural Inequities that Impact Health',
            subCategories: [
                'Transportation',
                'Housing/Neighborhood',
                'Environment',
                'Food Access',
                'Education',
                'Income',
                'Stress, Trauma and Adverse Childhood Experience'
            ]
        },
        {
            icon: this.icons.heartbeat,
            area: 'Health Equity and Health Outcomes',
            subCategories: [
                'Covid 19',
                'Diabetes/Cardiovascular',
                'Cancer',
                'Environmentally related illness',
                'Mental and Behavioral Health'
            ]
        },
        {
            icon: this.icons.clinic,
            area: 'Healthcare Access and Quality',
            subCategories: []
        },
        {
            icon: this.icons.podcast,
            area: 'Health Equity Podcasts',
            subCategories: []
        },
        {
            icon: this.icons.podcast,
            area: 'Dashboards',
            subCategories: []
        }
    ];

    public DEFAULT_SUBCATEGORY_TEXT = 'All Sub-Categories';
    public currentResults;
    public simpleSearchResults;
    public currentCategory: Category = this.issueAreas[0];
    public currentSubCategory = this.DEFAULT_SUBCATEGORY_TEXT;
    public currentPageNum = 1;
    public numPages = 1;
    public currentSearchPage = 1;
    public isBrowseMode = true;
    placeholder: string;
    searchBarValue: string;
    public simpleOrBrowseButtonText: string;

    constructor(public resourceService: ResourceService, public route: ActivatedRoute, public router: Router) {
        this.resourceService = resourceService;
        this.route = route;
        this.router = router;
        this.placeholder = 'Search for resources';
    }


    // eslint-disable-next-line no-undef
    search(searchTerm: HTMLInputElement): void {
        let mySearchTerm: string = searchTerm.value;
        this.searchBarValue = mySearchTerm;
        if (mySearchTerm.length > 0) {
            this.resourceService.simpleTextSearchForResources(mySearchTerm)
                .then(results => {
                    this.simpleSearchResults = results;
                })
                .catch(err => {
                    console.error(err);
                    window.alert('Something went wrong with your search');
                });
        } else {
            window.alert('invalid search');
        }
    }
    onKeyDown(searchTerm) {
        this.search(searchTerm);
    }

    ngOnInit() {
        this.isStartPage = true; //assume that there is not a search being performed
        this.isBrowseMode = true;
        this.simpleOrBrowseButtonText = this.isBrowseMode ? 'Switch to Search Mode' : 'Switch to Browse Mode';
        this.route.queryParamMap.subscribe(queryParams => {
            let category = queryParams.get('category');
            let subCategory = queryParams.get('subCat');
            let pageNum = queryParams.get('pageNum');
            let resourceQuery = this.resourceService.getResourcesByCategory({category, subCategory, pageNum});
            if (resourceQuery) {
                resourceQuery.subscribe((result: ResourceResponse) => {
                    if (category) {
                        this.currentResults = result.results;
                        this.isStartPage = false;
                        this.currentPageNum = +pageNum;
                        this.numPages = +result.numPages;
                    } else {
                        this.isStartPage = true;
                    }
                });
            } else {
                this.isStartPage = true;
            }
        });
    }

    public updateSubCategory(target) {
        this.currentSubCategory = target;
        this.currentPageNum = 1;
    }

    //called when user presses button
    public toggleBrowseMode(): void {
        this.isBrowseMode = !this.isBrowseMode;
        this.simpleOrBrowseButtonText = this.isBrowseMode ? 'Switch to Search Mode' : 'Switch to Browse Mode';
    }

    public isCurrentPage(i) {
        return i === this.currentPageNum;
    }
}
