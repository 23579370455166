<div class="container standard-div standard-div-light-gray">

    <!-- Menu displayed on large screen widths -->
    <div class="menu-side-by-side" id="menu-side-by-side">
        <router-outlet name="sidemenu"></router-outlet>
    </div>

    <!-- Wrapper for about page -->
    <div class="about-wrapper" id="about-wrapper">

        <!-- Image for page -->
        <img src="/5816f637ffa6314da47b505f0895cba7.png" alt="Health Equity Colorado" width="100%">

        <!-- Page content below image -->
        <div class="content-below-image" id="content-below-image">

            <!-- Menu displayed on small screen widths -->
            <div class="menu-below-image" id="menu-below-image">
                <router-outlet name="sidemenu"></router-outlet>
            </div>

            <!-- Wrapper for page text -->
            <div class="text-wrapper" id="text-wrapper">
                <h4 class="text-header">About Us</h4>
                <div *ngFor="let par of aboutUsText" class="about-us-text">
                    <quill-view [content]="par.text" format="html" theme="snow"></quill-view>
                </div>
            </div>
        </div>
    </div>
</div>
