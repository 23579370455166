import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {MatChipsModule} from '@angular/material/chips';
import {AlertModule} from 'ngx-bootstrap/alert';
import {SiteAdminGuard} from '../../components/auth/site-admin-guard.service';
import {OrgAdminGuard} from '../../components/auth/org-admin-guard.service';
import {AuthModule} from '../../components/auth/auth.module';
import {AdminComponent} from './admin.component';
import {OrganizationsModule} from '../organizations/organizations.module';
import { EditOrganizationComponent} from '../edit-organization/edit-organization.component';
import {EditOrganizationModule} from '../edit-organization/edit-organization.module';
import {CreateResourceComponent} from './create-resource/create-resource.component';
import {CreateResourceModule} from './create-resource/create-resource.module';
import {EditResourceModule} from './edit-resource-details/edit-resource.module';
import {ListPageContentComponent} from './list-page-content/list-page-content.component';
import {EditPageContentComponent} from './edit-page-content/edit-page-content.component';
import {VerifyOrganizationClaimsComponent} from './verify-organization-claims/verify-organization-claims.component';
import {VerifyOrganizationsComponent} from './verify-organizations/verify-organizations.component';
import {OrganizationApprovalComponent} from './organization-approval/organization-approval.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ResourcesModule} from '../resources/resources.module';
import {MatTabsModule} from '@angular/material/tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule} from '@angular/material/core';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {VerifyClaimsModule} from '../../components/modals/verify-claims/verify-claims.module';
import {MatPaginatorModule} from '@angular/material/paginator';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatOptionModule} from '@angular/material/core';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
// @ts-ignore
import {QuillModule} from 'ngx-quill';
import { DirectivesModule } from '../../components/directives.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatTableModule} from '@angular/material/table';


//This module uses Tabs and Forms from Angular Material. For more information about Angular Material and how to use it,
// https://material.angular.io

//Ensures that these routes are protected routes
const adminRoutes: Routes = [
    {
        path: 'admin',
        component: AdminComponent,
        canActivate: [SiteAdminGuard]
    },
    {
        path: 'organizations/:organizationUrl/admin',
        component: EditOrganizationComponent,
        canActivate: [OrgAdminGuard]
    },
    {
        path: 'organizations/:organizationUrl/edit',
        component: EditOrganizationComponent,
        canActivate: [OrgAdminGuard]
    },
    // {
    //     path: 'resources/:resourcesUrl/admin',
    //     component: EditResourceDetailsComponent,
    //     canActivate: [OrgAdminGuard]
    // },
    {
        path: 'create-resource/:organizationName',
        component: CreateResourceComponent,
    },
    {
        path: 'pagecontent/:pageName/admin',
        component: ListPageContentComponent
    },
    {
        path: 'editcontent/:pageName/:contentID/edit/admin',
        component: EditPageContentComponent,
        canActivate: [SiteAdminGuard]
    },
    {
        path: 'verify-claims',
        component: VerifyOrganizationClaimsComponent,
        canActivate: [SiteAdminGuard]
    },
    {
        path: 'verify-organizations',
        component: VerifyOrganizationsComponent,
        canActivate: [SiteAdminGuard]
    },
    {
        path: 'verify-organizations/:organizationUrl',
        component: OrganizationApprovalComponent,
        canActivate: [SiteAdminGuard]
    }

];

@NgModule({
    imports: [
        RouterModule.forChild(adminRoutes),
        AlertModule.forRoot(),
        AuthModule,
        BrowserModule,
        CreateResourceModule,
        EditResourceModule,
        FormsModule,
        MatButtonModule,
        MatCardModule,
        MatChipsModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatMenuModule,
        MatNativeDateModule,
        MatRadioModule,
        MatSelectModule,
        MatTabsModule,
        MatToolbarModule,
        OrganizationsModule,
        ReactiveFormsModule,
        ResourcesModule,
        VerifyClaimsModule,
        MatPaginatorModule,
        DirectivesModule,
        QuillModule,
        MatProgressSpinnerModule,
        MatTableModule,
        NgxMatSelectSearchModule,
        MatOptionModule,
        MatCheckboxModule,
        TimepickerModule,
        EditOrganizationModule
    ],
    declarations: [
        AdminComponent,
        ListPageContentComponent,
        EditPageContentComponent,
        VerifyOrganizationClaimsComponent,
        VerifyOrganizationsComponent,
        OrganizationApprovalComponent
    ],
    exports: [
        AdminComponent,
        ListPageContentComponent,
        EditPageContentComponent,
        VerifyOrganizationClaimsComponent,
        VerifyOrganizationsComponent,
        OrganizationApprovalComponent
    ],
})
export class AdminModule {
}
