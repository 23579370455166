<div class="container standard-div">
    <!--<p>The delete user and user index api routes are restricted to users with the 'admin' role.</p>-->
    <div class="row">
        <mat-tab-group class="col-xs-12 col-lg-10 offset-lg-1" dynamicHeight (selectedTabChange)="handleTabChange($event)" [selectedIndex]="currPageTabIndex">
            <mat-tab label="Users">
                <ng-template matTabContent>
                    <div>
                        <h4>Users</h4>
                        <ul class="list-group user-list">
                            <li class="list-group-item" *ngFor="let user of users">

                                <div class="user-info">
                                    <strong>{{user.name.first}} {{user.name.last}}</strong><br>
                                    <span class="text-muted">{{user.email}}</span>
                                </div>
                                <a (click)="deleteUser(user)" class="trash"><span class="fa fa-trash fa-2x"></span></a>&nbsp;
                            </li>
                        </ul>
                    </div>
                </ng-template>
            </mat-tab>

            <mat-tab label="Organizations">
                <ng-template matTabContent>
                    <button mat-icon-button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
                        <mat-icon>menu</mat-icon>
                        Menu
                    </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="changeOrgTab(1)">Approve New Organizations</button>
                        <button mat-menu-item (click)="changeOrgTab(2)">Approve New Claims</button>
                        <button mat-menu-item (click)="changeOrgTab(3)">Manage Existing Organizations</button>
                    </mat-menu>
                    <div *ngIf="currentOrgTab == 1">
                        <verify-organizations></verify-organizations>
                    </div>
                    <div *ngIf="currentOrgTab == 2">
                        <verify-organization-claims></verify-organization-claims>
                    </div>
                    <div *ngIf="currentOrgTab == 3">
                        <h4>Organizations</h4>
                        <form [formGroup]="orgFilterForm" (ngSubmit)="submitOrgSearch()" class="filterResults">
                            <mat-form-field appearance="fill">
                                <mat-label>Organization Name</mat-label>
                                <input matInput formControlName="orgNameSearchString" (keydown.enter)="submitOrgSearch()">
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="filterField">
                                <mat-label for="populationServedOptions">Community Focus</mat-label>
                                <mat-select multiple="multiple" formControlName="selectedPopulationServed" ngDefaultControl id="selectedPopulationServed" #matSelect1 disableOptionCentering (selectionChange)="submitOrgSearch()">
                                    <div class="row no-gutters">
                                        <div class="col-sm-4 text-right">
                                            <button mat-raised-button class="clear-button clear1" type="button" (click)="clearOrgFilter('populationServed')">
                                                Clear
                                            </button>
                                        </div>
                                        <div class="col-sm-4 text-left">
                                            <button mat-raised-button class="clear-button clear1" type="button" (click)="closeFilter(matSelect1)">
                                                Save
                                            </button>
                                        </div>
                                    </div>
                                    <mat-option *ngFor="let populationServed of populationServedOptions" [value]="populationServed">
                                        {{populationServed}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="filterField">
                                <mat-label for="organizationFocusOptions">Organization Focus</mat-label>
                                <mat-select multiple="multiple" formControlName="selectedOrganizationFocus" ngDefaultControl id="selectedOrganizationFocus" #matSelect2 disableOptionCentering (selectionChange)="submitOrgSearch()">
                                    <div class="row no-gutters">
                                        <div class="col-sm-4 text-right">
                                            <button mat-raised-button class="clear-button clear2" type="button" (click)="clearOrgFilter('organizationFocus')">Clear</button>
                                        </div>
                                        <div class="col-sm-4 text-left">
                                            <button mat-raised-button class="clear-button clear2" type="button" (click)="closeFilter(matSelect2)">Save</button>
                                        </div>
                                    </div>
                                    <mat-option *ngFor="let organizationFocus of organizationFocusOptions" [value]="organizationFocus">
                                        {{organizationFocus}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field appearance="outline" class="filterField">
                                <mat-label for="organzationRoleOptions">Organization Role</mat-label>
                                <mat-select multiple="multiple" formControlName="selectedOrganizationRole" ngDefaultControl id="selectedOrganizationRole" #matSelect3 disableOptionCentering (selectionChange)="submitOrgSearch()">
                                    <div class="row no-gutters">
                                        <div class="col-sm-4 text-right">
                                            <button mat-raised-button class="clear-button clear2" type="button" (click)="clearOrgFilter('organizationRole')">Clear</button>
                                        </div>
                                        <div class="col-sm-4 text-left">
                                            <button mat-raised-button class="clear-button clear2" type="button" (click)="closeFilter(matSelect3)">Save</button>
                                        </div>
                                    </div>
                                    <mat-option *ngFor="let organizationRole of organizationRoleOptions" [value]="organizationRole">
                                        {{organizationRole}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button mat-raised-button color="primary" class="search_icon submitSearch" type="submit">
                                <i class="fa fa-search"></i>&nbsp;Filter
                            </button>
                            <button mat-raised-button class="search_icon resetSearch" type="reset">
                                Clear
                            </button>
                        </form>
                        <div class="map-search-results-text search-results-text">{{pageViewString()}}</div>

                        <!--                    List of Organizations -->
                        <ul class="list-group user-list">
                            <li class="list-group-item" *ngFor="let organization of allOrganizations; let i = index">

                                <span style="color: gainsboro" class="pull-left">{{orgOffset+i+1}}</span>
                                <div class="search-result-image col-lg-1">
                                    <i class="fa fa-building-o fa-4x" aria-hidden="true"></i>
                                </div>
                                <div class="search-result-text col-lg-10">
                                    <p style="margin: 0">
                                        <a [title]="organization.name + ' details'" [routerLink]="['/organizations', organization._id]">
                                            {{organization.name}}
                                        </a>
                                    </p>
                                    <p style="margin-top: 1px;" *ngIf="organization.city">
                                        {{ organization.city }}
                                    </p>
                                </div>

                                <a (click)="deleteOrganization(organization)" class="trash"><span class="fa fa-trash fa-2x"></span></a>&nbsp;
                                <!-- clicking on the edit button redirects to the edit-organization page -->
                                <a [title]="organization.name + ' details'" [routerLink]="['/organizations', organization._id, 'admin']" class="edit">
                                    <span class="fa fa-pencil-square-o fa-2x"></span></a>
                            </li>
                        </ul>

                        <div class="sidebar__pagination" *ngIf="allOrganizations && allOrganizations.length > 0">
                            <nav class="map-pagination results__pagination">
                                <mat-paginator [length]="orgNumTotalResults" [pageSize]="orgLimit" [pageSizeOptions]="PAGE_SIZE_OPTIONS" (page)="orgPageChange($event)">
                                </mat-paginator>
                            </nav>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>

            <mat-tab label="Resources">
                <ng-template matTabContent>
                    <div>
                        <h4>Resources</h4>
                        <div align="center"><a routerLink="/create-resource" routerLinkActive="active">Add resource</a></div>
                        <div class="map-search-results-text search-results-text">{{resourcePageViewString()}}</div>

                        <details>
                            <summary>Filter Results</summary>
                            <form [formGroup]="resourceFilterForm" (ngSubmit)="submitResourceSearch()" class="filterResults">
                                <mat-form-field appearance="fill">
                                    <mat-label>Resource Name</mat-label>
                                    <input matInput formControlName="resourceSearchString" (keydown.enter)="submitResourceSearch()">
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="filterField">
                                    <mat-label for="resourceTypes">Type</mat-label>
                                    <mat-select multiple="multiple" formControlName="resourceTypes" ngDefaultControl id="resourceTypes" #matSelect4 disableOptionCentering (selectionChange)="submitResourceSearch()">
                                        <div class="row no-gutters">
                                            <div class="col-sm-4 text-right">
                                                <button mat-raised-button class="clear-button clear2" type="button" (click)="clearResourceFilter('resourceTypes')">Clear</button>
                                            </div>
                                            <div class="col-sm-4 text-left">
                                                <button mat-raised-button class="clear-button clear2" type="button" (click)="closeFilter(matSelect4)">Save</button>
                                            </div>
                                        </div>
                                        <mat-option *ngFor="let resourceType of resourceTypeOptions" [value]="resourceType">
                                            {{resourceType}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                                <mat-form-field appearance="outline" class="filterField">
                                    <mat-label for="categories">Category</mat-label>
                                    <mat-select multiple="multiple" formControlName="categories" ngDefaultControl id="categories" #matSelect5 disableOptionCentering (selectionChange)="submitResourceSearch()">
                                        <div class="row no-gutters">
                                            <div class="col-sm-4 text-right">
                                                <button mat-raised-button class="clear-button clear2" type="button" (click)="clearResourceFilter('category')">Clear</button>
                                            </div>
                                            <div class="col-sm-4 text-left">
                                                <button mat-raised-button class="clear-button clear2" type="button" (click)="closeFilter(matSelect5)">Save</button>
                                            </div>
                                        </div>
                                        <mat-option *ngFor="let category of resourceCategoryOptions" [value]="category">
                                            {{category}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <button mat-raised-button color="primary" class="search_icon submitSearch" type="submit">
                                    <i class="fa fa-search"></i>&nbsp;Filter
                                </button>
                                <button mat-raised-button class="search_icon resetSearch" type="reset" (click)="clearAllResourceFilters()">
                                    Clear
                                </button>
                            </form>
                        </details>
                        <ul class="list-group user-list">
                            <li class="list-group-item" *ngFor="let resource of allResources; let i = index">

                                <span style="color: gainsboro" class="pull-left">{{resourceOffset+i+1}}</span>
                                <div class="search-result-image col-lg-1">
                                    <i class="fa fa-building-o fa-4x" aria-hidden="true"></i>
                                </div>
                                <div class="search-result-text col-lg-10">
                                    <p style="margin: 0">
                                        <a [title]="resource.resourceTitle" target="_blank" [href]="[resource.resourceURL]">
                                            {{resource.resourceTitle}}
                                        </a>

                                    </p>
                                    <p style="margin-top: 1px;">
                                        <span *ngIf="resource.organizationName">{{ resource.organizationName }}</span><br>
                                        <span *ngIf="resource.year">{{ resource.year }}</span><br>
                                        <span *ngIf="resource.author">{{ resource.author }}</span>
                                    </p>
                                </div>

                                <a (click)="deleteResource(resource)" class="trash"><span class="fa fa-trash fa-2x"></span></a>&nbsp;
                                <a [title]="resource.resourceTitle + ' details'" [routerLink]="['/resources', resource._id, 'admin']" class="edit"><span class="fa fa-pencil-square-o fa-2x"></span></a>
                            </li>
                        </ul>

                        <div class="sidebar__pagination" *ngIf="allResources && allResources.length > 0">
                            <nav class="map-pagination results__pagination">
                                <mat-paginator [length]="resourceNumTotalResults" [pageSize]="resourceLimit" [pageSizeOptions]="PAGE_SIZE_OPTIONS" (page)="resourcePageChange($event)">
                                </mat-paginator>
                            </nav>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>

            <!--            TODO: these tabs are placeholders until we have the proper infrastructure to
                                    perform CRUD operations on these        -->
            <!--TODO: Add Tabs to allow siteAdmin to edit events, funding, job postings -->
            <mat-tab label="Events">
                <ng-template matTabContent>
                    Manage Events from here
                </ng-template>
            </mat-tab>
            <mat-tab label="Funding">
                <ng-template matTabContent>
                    Manage Funding Opportunities from here
                </ng-template>
            </mat-tab>
            <mat-tab label="Job Postings">
                <ng-template matTabContent>
                    Manage Job Postings from here
                </ng-template>
            </mat-tab>

            <mat-tab label="Page Content" class="tab tab__pageContent">
                <ng-template matTabContent>
                    <div class="pageContent">
                        <h4 class="tab__header">Page Content</h4>

                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 row card-deck">
                            <div class="col-12 col-sm-6 col-md-4 col-lg-4 card-holder" *ngFor="let page of allPageNames">
                                <div class="card">
                                    <div class="card-body">
                                        <h5 class="card-title">{{page}}</h5>
                                        <p class="card-text">Edit the {{page}} page content here</p>
                                    </div>
                                    <div class="card-footer">
                                        <button type="button" class="card__button" [title]="page + ' details'" [routerLink]="['/pagecontent', page, 'admin']">
                                            Edit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
