import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { MatSelect } from '@angular/material/select';
import { Organization } from './new-organization';
import { OrganizationService } from '../../services/organization/organization.service';
import {ImageService} from "../../services/images/image.service";
import constants from '../app.constants';
import { phoneNormalization } from '../../components/util';

class ImageSnippet {
    pending = false;
    status = 'init';
    image = '';

    // eslint-disable-next-line no-useless-constructor,no-unused-vars
    constructor(public src: string, public file: File) {}
}

@Component({
    selector: 'create-organization',
    templateUrl: './create-organization.html',
    styleUrls: ['./create-organization.scss']
})
export class CreateOrganizationComponent implements OnInit {
    static parameters = [OrganizationService, Router, ImageService];
    constructor(public organizationService: OrganizationService, public router: Router, public imageService: ImageService) {
        this.organizationService = organizationService;
        this.router = router;
        this.imageService = imageService;
    }

    public requestObject = new Organization();

    // Variables for alert feedback
    showAlert = false;
    alertType = null; // 'alert-success' or 'alert-danger'
    alertMessage = null;
    selectedBannerFile: ImageSnippet;

    // Function to read values from input fields
    getInput(e: any) {
        if (e.target.classList.contains('invalid')) {
            e.target.classList.remove('invalid');
        }
        if (e.target.name === 'telephoneNumber') {
            this.requestObject[e.target.name] = phoneNormalization(e.target.value);
            e.target.value = phoneNormalization(e.target.value);
        }
        if (e.target.name === 'twitter' || e.target.name === 'facebook' || e.target.name === 'linkedin' || e.target.name === 'instagram') {
            this.requestObject.socialMedia[e.target.name] = e.target.value;
        } else {
            this.requestObject[e.target.name] = e.target.value;
        }
    }

    mondayStartTime: Date = new Date('October 12, 1988 09:00:00'); mondayEndTime: Date = new Date('October 12, 1988 17:00:00'); noMondayHours: Boolean = false;
    tuesdayStartTime: Date = new Date('October 12, 1988 09:00:00'); tuesdayEndTime: Date = new Date('October 12, 1988 17:00:00'); noTuesdayHours: Boolean = false;
    wednesdayStartTime: Date = new Date('October 12, 1988 09:00:00'); wednesdayEndTime: Date = new Date('October 12, 1988 17:00:00'); noWednesdayHours: Boolean = false;
    thursdayStartTime: Date = new Date('October 12, 1988 09:00:00'); thursdayEndTime: Date = new Date('October 12, 1988 17:00:00'); noThursdayHours: Boolean = false;
    fridayStartTime: Date = new Date('October 12, 1988 09:00:00'); fridayEndTime: Date = new Date('October 12, 1988 17:00:00'); noFridayHours: Boolean = false;
    saturdayStartTime: Date; saturdayEndTime: Date; noSaturdayHours: Boolean = true;
    sundayStartTime: Date; sundayEndTime: Date; noSundayHours: Boolean = true;

    // Drop down lists
    protected populations: string[] = constants.populationServedOptions;
    protected activities: string[] = constants.organizationFocusOptions;
    protected roles: string[] = constants.organizationRoleOptions;
    protected counties: string[] = constants.coloradoCounties;


    // Form action function that checks validity then calls the handle submit function
    formSubmit(e: any) {
        // let test = constants.
        if (!this.checkSelects()) {
            this.showAlert = true;
            this.alertType = 'alert-danger';
            this.alertMessage = 'Please fill out all required fields';
            window.scrollTo(0, 0);
        } else {
            this.handleSubmit();
        }
    }

    // Function to reset form inputs
    formReset() {
        // eslint-disable-next-line no-undef
        (<HTMLFormElement>document.getElementById('new-org-form') as HTMLFormElement).reset();
        this.popMultiCtrl.reset();
        this.activityMultiCtrl.reset();
        this.rolesMultiCtrl.reset();
        this.countyMultiCtrl.reset();
    }

    // Function to validate html form inputs
    validateInput(e: any) {
        e.target.classList.add('invalid');
        this.checkSelects();
    }

    // Custom function to check validity of select inputs
    checkSelects(): Boolean {
        let valid = true;
        if (!this.popMultiCtrl.value) {
            document.getElementById('new-org-populations').classList.add('invalid');
            valid = false;
        }
        if (!this.activityMultiCtrl.value) {
            document.getElementById('new-org-activity').classList.add('invalid');
            valid = false;
        }
        if (!this.rolesMultiCtrl.value) {
            document.getElementById('new-org-role').classList.add('invalid');
            valid = false;
        }
        if (!this.countyMultiCtrl.value) {
            document.getElementById('new-org-county').classList.add('invalid');
            valid = false;
        }
        return valid;
    }

    private getHoursOfOperation() {
        let hoursOfOperation = [];
        let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        let startTimes = [this.mondayStartTime, this.tuesdayStartTime, this.wednesdayStartTime, this.thursdayStartTime,
            this.fridayStartTime, this.saturdayStartTime, this.sundayStartTime];
        let endTimes = [this.mondayEndTime, this.tuesdayEndTime, this.wednesdayEndTime, this.thursdayEndTime,
            this.fridayEndTime, this.saturdayEndTime, this.sundayEndTime];
        let noWorkHours = [this.noMondayHours, this.noTuesdayHours, this.noWednesdayHours, this.noThursdayHours,
            this.noFridayHours, this.noSaturdayHours, this.noSundayHours];
        for (let i = 0; i < days.length; i++) {
            if (!noWorkHours[i]) {
                hoursOfOperation.push({
                    dayOfWeek: days[i],
                    noWorkHours: noWorkHours[i],
                    openingTime: startTimes[i].toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false }) || 'N/A',
                    closingTime: endTimes[i].toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false }) || 'N/A'
                });
            } else {
                hoursOfOperation.push({
                    dayOfWeek: days[i],
                    noWorkHours: noWorkHours[i]
                });
            }
        }
        return hoursOfOperation;
    }
    public baseUrl = 'https://maps.googleapis.com/maps/api/geocode/json?address=';
    // Function to create the new organization in the database on submit
    handleSubmit() {
        if (this.selectedBannerFile.status === 'ok') {
            this.requestObject.bannerImage = this.selectedBannerFile.image;
        }

        // Set country to USA - seemed like the best practice for now
        this.requestObject.country = 'USA';

        // Normalize and set requestObject Fields
        this.requestObject.name = this.capitalizeTitles(this.requestObject.name);
        this.requestObject.city = this.capitalizeFirstLetter(this.requestObject.city);
        this.requestObject.status = 'Pending';
        this.requestObject.email = this.requestObject.email.toLowerCase();
        this.requestObject.hoursOfOperation = this.getHoursOfOperation();

        // The current user becomes an admin for the org if they aren't a system admin
        let user = JSON.parse(localStorage.getItem('user'));
        if (user.role !== 'admin') {
            this.requestObject.administrators = [user._id];
            this.requestObject.isClaimed = true;
        } else {
            this.requestObject.isClaimed = false;
        }

        if (this.rolesMultiCtrl.value) this.requestObject.organizationRole = this.rolesMultiCtrl.value;
        if (this.activityMultiCtrl.value) this.requestObject.organizationFocus = this.activityMultiCtrl.value;
        if (this.popMultiCtrl.value) this.requestObject.populationServed = this.popMultiCtrl.value;
        if (this.countyMultiCtrl.value) this.requestObject.county = this.countyMultiCtrl.value;
        // Call to create organization on the database
        this.organizationService.create(this.requestObject).toPromise().then((org) => {
            this.formReset();
            this.showAlert = true;
            this.alertType = 'alert-success';
            if (user.role === 'admin') {
                this.alertMessage = `${this.requestObject.name} has been created.`;
            } else {
                this.alertMessage = `Your request to create ${this.requestObject.name} has been submitted.`;
            }

            window.scrollTo(0, 0);
        }).catch((err) => {
            this.showAlert = true;
            this.alertType = 'alert-danger';
            this.alertMessage = 'Sorry, there was an error trying to submit your request. Please try again later.';
            window.scrollTo(0, 0);
        });
    }

    // SETUP - input controls for selects
    public popMultiCtrl: FormControl = new FormControl();
    public popMultiFilterCtrl: FormControl = new FormControl();
    public filteredPopsMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

    public activityMultiCtrl: FormControl = new FormControl();
    public activityMultiFilterCtrl: FormControl = new FormControl();
    public filteredActivityMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

    public rolesMultiCtrl: FormControl = new FormControl();
    public rolesMultiFilterCtrl: FormControl = new FormControl();
    public filteredRolesMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

    public countyMultiCtrl: FormControl = new FormControl();
    public countyMultiFilterCtrl: FormControl = new FormControl();
    public filteredCountyMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

    @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;
    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();


    // Function to set initial values of select inputs and add change listeners
    ngOnInit() {
        this.requestObject.state = 'Colorado';
        this.requestObject.socialMedia = {};
        this.filteredPopsMulti.next(this.populations.slice());
        this.filteredActivityMulti.next(this.activities.slice());
        this.filteredRolesMulti.next(this.roles.slice());
        this.filteredCountyMulti.next(this.counties.slice());


        this.popMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterFunction(this.populations, this.popMultiFilterCtrl, this.filteredPopsMulti);
        });

        this.activityMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterFunction(this.activities, this.activityMultiFilterCtrl, this.filteredActivityMulti);
        });

        this.rolesMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterFunction(this.roles, this.rolesMultiFilterCtrl, this.filteredRolesMulti);
        });

        this.countyMultiFilterCtrl.valueChanges.pipe(takeUntil(this._onDestroy)).subscribe(() => {
            this.filterFunction(this.counties, this.countyMultiFilterCtrl, this.filteredCountyMulti);
        });

        window.scrollTo(0, 0);
    }

    // Destroy function for select inputs
    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    // Function to set initial values of select inputes
    ngAfterViewInit() {
        this.setInitialValue();

        let form = document.getElementById('new-org-form');
        form.removeAttribute('noValidate');
    }


    //  Sets the initial value after the filteredPops are loaded initially
    protected setInitialValue() {
        this.filteredPopsMulti
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.multiSelect.compareWith = this.filterCompare;
            });

        this.filteredActivityMulti
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.multiSelect.compareWith = this.filterCompare;
            });

        this.filteredRolesMulti
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.multiSelect.compareWith = this.filterCompare;
            });


        this.filteredCountyMulti
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.multiSelect.compareWith = this.filterCompare;
            });
    }

    // // STRING NORMALIZATION FUNCTIONS
    capitalizeFirstLetter(str: String): String {
        let first = str.charAt(0).toUpperCase();
        let rest = str.substring(1).toLowerCase();
        return first + rest;
    }

    capitalizeTitles(str: String): String {
        let arr = str.split(' ').map((e: String): String => this.capitalizeFirstLetter(e));
        return arr.join(' ');
    }

    // SELECT FILTER FUNCTION
    protected filterFunction(list: string[], control: any, filtered: any) {
        if (!list) {
            return;
        }
        let search = control.value;
        if (!search) {
            filtered.next(list.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        filtered.next(
            list.filter(str => str.toLowerCase().indexOf(search) > -1));
    }

    filterCompare(a: string, b: string) {
        return a && b && a === b;
    }

    private onSuccess() {
        this.selectedBannerFile.pending = false;
        this.selectedBannerFile.status = 'ok';
    }

    private onError() {
        this.selectedBannerFile.pending = false;
        this.selectedBannerFile.status = 'fail';
        this.selectedBannerFile.src = '';
    }

    processFile(imageInput: any) {
        const file: File = imageInput.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', (event: any) => {
            this.selectedBannerFile = new ImageSnippet(event.target.result, file);
            this.selectedBannerFile.pending = true;
            this.imageService.upsertImage(this.selectedBannerFile.file, this.requestObject.name || undefined).subscribe(
                (res) => {
                    this.onSuccess();
                    console.log(res);
                    this.selectedBannerFile.image = res._id;
                },
                (err) => {
                    this.onError();
                });
        });
        reader.readAsDataURL(file);
    }
}
