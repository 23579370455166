<footer class="container-fluid footer">
    <div class="container">
        <div class="row footer-sections">
            <div class="col-12 col-sm-5 col-xl-5 footer-section contactInfo">
                <h3 class="footer-section__header">Contact</h3>
                <address>
                    <span>2199 South University Blvd</span><br>
                    Denver, CO 80208
                </address>

                <ul class="social_footer_ul center-block">
                    <li>
                        <a target="_blank" href="https://www.facebook.com/HealthEquityCommunityExchange">
                            <i class="fa fa-facebook-f"></i>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://twitter.com/healthequity_co">
                            <i class="fa fa-twitter"></i>
                        </a>
                    </li>
                    <li>
                        <a target="_blank" href="https://www.instagram.com/hecommunityexchange">
                            <i class="fa fa-instagram"></i>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="col-12 col-sm-5 col-xl-5 footer-section quickLinks">
                <h3 class="footer-section__header">Quick Links</h3>
                <ul class="quickLinks__links">
                    <li><a routerLink="/news">News</a></li>
                    <!--                <li><a routerLink="jobs">Job Board</a></li>-->
                    <li [hidden]="isLoggedIn" routerLinkActive="active">
                        <a routerLink="/signup">Sign up</a>
                    </li>
                    <li [hidden]="isLoggedIn" routerLinkActive="active">
                        <a routerLink="/login">Login</a>
                    </li>
                    <li [hidden]="!isLoggedIn" routerLinkActive="active">
                        <a routerLink="/settings">Settings</a>
                    </li>
                    <li [hidden]="!isLoggedIn">
                        <a (click)="logout()" class="logoutLink">Log out</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>


    <div class="container" style="display: table; padding-top: 20px;">
        <p style="text-align: center; display: table-cell">
            &copy; 2019 | Powered by <a class="poweredBy" routerLink="/tech-team">DU Computer Science Students</a>
        </p>
    </div>
</footer>
