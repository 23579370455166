import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Organization } from '../../app/organizations/organization';
import { AuthService } from '../auth/auth.service';
import { UserService } from '../auth/user.service';
import { OrgClaimRequest } from '../interfaces/OrgClaimRequest';
import {Router} from '@angular/router';

@Component({
    selector: 'login-prompt',
    templateUrl: './login-prompt.html'
})
export class LoginPromptComponent {

    @Input()
    organization: Organization;

    Router;
    static parameters = [BsModalRef, AuthService, BsModalService, Router];

    constructor(public bsModalRef: BsModalRef,
                public authService: AuthService,
                public bsModalService: BsModalService,
                public router: Router) {
        this.authService = authService;
        this.bsModalRef = bsModalRef;
        this.Router = router;
        console.log(this.organization);
    }

    loginRoute() {
        this.bsModalRef.hide();
        this.Router.navigateByUrl('/login');
    }
}

