import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'events',
    templateUrl: './events.html',
    styleUrls: ['./events.scss'],
})
export class EventsComponent implements OnInit {
    //TODO: query events from Events API and populate this page with real content
    constructor() {

    }
    ngOnInit(): void {
    }
}
