<div class="container modal-container">
    <div class="popup-header">
        <a class="full-icon navbar-brand" style="cursor: pointer" (click)="home()">
            <img src="/237227a2630cbeb5184fbc882cedb47e.gif" alt="Health Equity Network Logo" width="200px" height="50px">
        </a>
        <h4>Reset Password</h4>
    </div>
    <div class="container">
        <div id="login-alert-wrapper">
            <div id="login-page-alert" role="alert" [hidden]="!showAlert" class="alert {{alertType}} alert-dismissible fade show">
                {{alertMessage}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true" (click)="closeAlert()">&times;</span>
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-lg-12 col-lg-offset-2">
                <form class="needs-validation form" name="resetPasswordForm" (ngSubmit)="resetPassword(resetPasswordForm)" novalidate #resetPasswordForm="ngForm">
                    <div class="form-group">
                        <input placeholder="Email" class="input" type="email" name="email" id="email" [ngModel]="email" [value]="email" required readonly="readonly" disabled="disabled">
                    </div>
                    <div class="form-group">
                        <input placeholder="New password" class="input" type="password" name="newpassword" id="newpassword" autocomplete="new-password" [(ngModel)]="user.password" #newpassword="ngModel" required>
                    </div>
                    <div class="form-group">
                        <input placeholder="Confirm password" class="input" type="password" name="confirmpassword" id="confirmpassword" autocomplete="new-password" [(ngModel)]="user.confirmPassword" #confirmpassword="ngModel" required>
                    </div>
                    <button class="btn-login" type="submit" [disabled]="!resetPasswordForm.form.valid">
                        Reset
                    </button>
                </form>
                <p class="not-register">Forgot your password?<a style="cursor: pointer" (click)="forgotPassword()"> Reset</a></p>
            </div>
        </div>

    </div>

</div>

