import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {OrgAdminType, Organization} from '../../organizations/organization';
import {OrganizationService} from '../../../services/organization/organization.service';
import {formatFullName} from '../../../components/util';

@Component({
    selector: 'organization-approval',
    templateUrl: './organization-approval.html',
    styleUrls: ['./organization-approval.scss']
})

export class OrganizationApprovalComponent implements OnInit {
    organizationUrl;
    pendingOrg: Organization;
    static parameters = [ActivatedRoute, OrganizationService];
    constructor(public route: ActivatedRoute, public organizationService: OrganizationService) {
        this.route = route;
        this.organizationService = organizationService;
    }

    ngOnInit() {
        if (this.route) {
            this.route.paramMap.subscribe(params => {
                if (params.has('organizationUrl')) {
                    this.organizationUrl = params.get('organizationUrl');
                    this.organizationService.getOrganizationByUrl(this.organizationUrl)
                        .toPromise()
                        .then((org: Organization) => {
                            this.pendingOrg = org;
                        })
                        .catch(err => {
                            console.error(err);
                        });
                }
            });
        }
    }

    deny(organization) {
        this.organizationService.reject(organization).subscribe();
    }
    approve(organization) {
        this.organizationService.approve(organization).subscribe();
    }

    formatFullAdminName(adminName?: OrgAdminType['name']): string {
        // @ts-ignore
        adminName = adminName ? adminName : this.pendingOrg.administrators[0].name;
        return formatFullName(adminName);
    }

}
