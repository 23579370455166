import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {ResetPasswordComponent} from './reset-password.component';
import {BsModalRef, BsModalService, ModalModule} from 'ngx-bootstrap/modal';

export const ROUTES: Routes = [
    {
        path: 'passwordreset/:email/:token',
        component: ResetPasswordComponent,
    },
];

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ModalModule,
        ReactiveFormsModule,
        RouterModule.forChild(ROUTES),
    ],
    declarations: [
        ResetPasswordComponent,
    ],
    providers: [
        BsModalService,
        BsModalRef
    ],
    exports: [
        ResetPasswordComponent,
    ],
})
export class ResetPasswordModule {
}
