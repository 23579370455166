<div class="container-fluid standard-div standard-div-light-gray">
    <div class="row">
        <div class="col-12 col-lg-3" style="padding: 1em;">
            <router-outlet name="sidemenu"></router-outlet>
        </div>
        <div class="col-12 col-lg-8" style="padding: 1em 3em 1em 3em;">
            <h4>News</h4>
            <div class="demo">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <div id="news-slider2" class="owl-carousel">
                                <div class="post-slide2">
                                    <div class="post-img">
                                        <a href="#"><img src="http://bestjquery.com/tutorial/news-slider/demo33/images/img-1.jpg" alt=""></a>
                                    </div>
                                    <div class="post-content">
                                        <h3 class="post-title"><a href="#">Latest News Post</a></h3>
                                        <p class="post-description">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec elementum mauris. Praesent vehicula gravida dolor, ac efficitur sem sagittis.
                                        </p>
                                        <ul class="post-bar">
                                            <li><i class="fa fa-calendar"></i> June 5, 2016</li>
                                            <li>
                                                <i class="fa fa-folder"></i>
                                                <a href="#">Mockup</a>
                                                <a href="#">Graphics</a>
                                                <a href="#">Flayers</a>
                                            </li>
                                        </ul>
                                        <a href="#" class="read-more">read more</a>
                                    </div>
                                </div>

                                <div class="post-slide2">
                                    <div class="post-img">
                                        <a href="#"><img src="http://bestjquery.com/tutorial/news-slider/demo33/images/img-2.jpg" alt=""></a>
                                    </div>
                                    <div class="post-content">
                                        <h3 class="post-title"><a href="#">Latest News Post</a></h3>
                                        <p class="post-description">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec elementum mauris. Praesent vehicula gravida dolor, ac efficitur sem sagittis.
                                        </p>
                                        <ul class="post-bar">
                                            <li><i class="fa fa-calendar"></i> June 7, 2016</li>
                                            <li>
                                                <i class="fa fa-folder"></i>
                                                <a href="#">Mockup</a>
                                                <a href="#">Graphics</a>
                                                <a href="#">Flayers</a>
                                            </li>
                                        </ul>
                                        <a href="#" class="read-more">read more</a>
                                    </div>
                                </div>

                                <div class="post-slide2">
                                    <div class="post-img">
                                        <a href="#"><img src="http://bestjquery.com/tutorial/news-slider/demo33/images/img-3.jpg" alt=""></a>
                                    </div>
                                    <div class="post-content">
                                        <h3 class="post-title"><a href="#">Latest News Post</a></h3>
                                        <p class="post-description">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec elementum mauris. Praesent vehicula gravida dolor, ac efficitur sem sagittis.
                                        </p>
                                        <ul class="post-bar">
                                            <li><i class="fa fa-calendar"></i> June 5, 2016</li>
                                            <li>
                                                <i class="fa fa-folder"></i>
                                                <a href="#">Mockup</a>
                                                <a href="#">Graphics</a>
                                                <a href="#">Flayers</a>
                                            </li>
                                        </ul>
                                        <a href="#" class="read-more">read more</a>
                                    </div>
                                </div>

                                <div class="post-slide2">
                                    <div class="post-img">
                                        <a href="#"><img src="http://bestjquery.com/tutorial/news-slider/demo33/images/img-4.jpg" alt=""></a>
                                    </div>
                                    <div class="post-content">
                                        <h3 class="post-title"><a href="#">Latest News Post</a></h3>
                                        <p class="post-description">
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec nec elementum mauris. Praesent vehicula gravida dolor, ac efficitur sem sagittis.
                                        </p>
                                        <ul class="post-bar">
                                            <li><i class="fa fa-calendar"></i> June 5, 2016</li>
                                            <li>
                                                <i class="fa fa-folder"></i>
                                                <a href="#">Mockup</a>
                                                <a href="#">Graphics</a>
                                                <a href="#">Flayers</a>
                                            </li>
                                        </ul>
                                        <a href="#" class="read-more">read more</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

