<div class="container modal-container">
    <div class="popup-header">
        <img src="/237227a2630cbeb5184fbc882cedb47e.gif" alt="Health Equity Network Logo" width="200px" height="50px">
        <div class="popup-text">
            <h4>Welcome to</h4>
            <h4>Health Equity Community Exchange</h4>
        </div>
    </div>
    <div class="container standard-div">
        <!--Centering a column in bootstrap 3 https://stackoverflow.com/questions/18153234/center-a-column-using-twitter-bootstrap-3 -->

        <!-- Alert element to show feedback to user if necessary -->
        <div id="login-alert-wrapper">
            <div id="login-page-alert" role="alert" [hidden]="!showAlert" class="alert {{alertType}} alert-dismissible fade show">
                {{alertMessage}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true" (click)="closeAlert()">&times;</span>
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-lg-12 col-lg-offset-2">
                <form class="needs-validation form" name="loginForm" (ngSubmit)="login(loginForm)" novalidate #loginForm="ngForm">

                    <div class="form-group">
                        <input placeholder="Email" class="input" type="email" name="email" id="email" [(ngModel)]="user.email" #email="ngModel" required>
                    </div>

                    <div class="form-group">
                        <input type="password" name="password" class="input" placeholder="Password" [(ngModel)]="user.password" #password="ngModel" autocomplete="current-password" required>
                    </div>
                    <p class="not-register">Forgot your password?<a style="cursor: pointer" (click)="forgotPassword()"> Reset</a></p>

                    <div class="form-group has-error invalid-feedback">
                        <p class="form-text text-muted help-block" [hidden]="(email.value && password.value) || !loginForm.submitted">
                            Please enter your email and password.
                        </p>
                        <p class="form-text text-muted help-block" [hidden]="email.valid || (email.pristine && !loginForm.submitted)">
                            Please enter a valid email.
                        </p>

                        <p class="form-text text-muted help-block">{{ errors.login }}</p>
                    </div>
                    <button class="btn-login" type="submit" [disabled]="!loginForm.form.valid">
                        Login
                    </button>
                </form>
                    <div>
<!--                        <button class="btn btn-default btn-lg btn-register" type="submit" [disabled]="!loginForm.form.valid">-->
<!--                            Login-->
<!--                        </button>-->
<!--                        <a class="btn btn-default btn-lg btn-register" type="register" href="/signup{{signupQueryParams}}">-->
<!--                            Register-->
<!--                        </a>-->

                        <p class="Or col-lg-12">OR</p>
                        <div class="row">
                            <div class="col-sm-4 col-md-3">
                                <oauth-buttons class="btn-block"></oauth-buttons>
                            </div>
                        </div>
                        <hr>
<!--                        <p class="not-register">Not registered yet?<a href="/signup{{signupQueryParams}}"> Sign up</a></p>-->
                        <p class="not-register">Not registered yet?<a style="cursor: pointer" (click)="signUp()"> Sign up</a></p>
                    </div>

<!--                    <hr />-->
<!--                    <div class="row">-->
<!--                        <div class="col-sm-4 col-md-3">-->
<!--                            <oauth-buttons class="btn-block"></oauth-buttons>-->
<!--                        </div>-->

<!--                    </div>-->
<!--                    <hr>-->
            </div>
        </div>

    </div>

</div>

