import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Organization } from '../../app/organizations/organization';
import { AuthService } from '../auth/auth.service';
import { OrgClaimRequest } from '../interfaces/OrgClaimRequest';
import { phoneNormalization } from '../util';
import {TypeaheadConfig} from 'ngx-bootstrap/typeahead';

export function getTypeaheadConfig(): TypeaheadConfig {
    return Object.assign(new TypeaheadConfig());
}
@Component({
    selector: 'claim-organization',
    templateUrl: './create-org-claim-request.html',
    styleUrls: ['./create-org-claim-request.scss'],
    providers: [{provide: TypeaheadConfig, useFactory: getTypeaheadConfig}]
})
export class CreateOrgClaimRequestComponent {

    /*===============================
            [typeahead] Organization Roles */
    public selectedRole: string;
    public orgRoles: string[] = [
        'Web/Digital Content Manager', 'Administrator', 'Marketing', 'Public Relations',
    ];


    @Input() organization: Organization;
    @Input() claimant: any;
    @Input() formError: String;
    @Input() formInfo: String;
    @Output() newOrgClaimReq: EventEmitter<OrgClaimRequest> = new EventEmitter<OrgClaimRequest>();

    public claimRequest: OrgClaimRequest = {
        orgId: undefined,
        claimant: {
            _id: undefined,
            orgEmail: undefined,
            directLine: undefined,
            orgRole: undefined
        },
        additionalInfo: undefined,
        requestDate: Date(),
        status: 'pending'
    };

    static parameters = [BsModalRef];

    constructor(public bsModalRef: BsModalRef, public authService: AuthService) {
        this.authService = authService;
    }

    ngOnInit() {
        this.claimRequest.claimant._id = this.claimant._id;
    }
    normalizePhone(e: string) {
        this.claimRequest.claimant.directLine = phoneNormalization(e);
    }

    submitOrgClaim() {
        this.claimRequest.orgId = this.organization._id;
        if (this.claimRequest.additionalInfo === undefined) {
            this.claimRequest.additionalInfo = '';
        }

        this.newOrgClaimReq.emit(this.claimRequest);
    }
}

