<div class="container modal-container">
    <div class="popup-header">
        <img src="/237227a2630cbeb5184fbc882cedb47e.gif" alt="Health Equity Network Logo" width="200px" height="50px">
        <h4>Forgot My Password</h4>
    </div>
    <div class="container">
        <div id="login-alert-wrapper">
            <div id="login-page-alert" role="alert" [hidden]="!showAlert" class="alert {{alertType}} alert-dismissible fade show">
                {{alertMessage}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true" (click)="closeAlert()">&times;</span>
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-lg-12 col-lg-offset-2">
                <form class="needs-validation form" name="forgotPasswordForm" (ngSubmit)="forgotPassword(forgotPasswordForm)" novalidate #forgotPasswordForm="ngForm">
                    <div class="form-group">
                        <input placeholder="Email" class="input" type="email" name="email" id="email" [(ngModel)]="user.email" #email="ngModel" required>
                    </div>

                    <button class="btn-login" type="submit" [disabled]="!forgotPasswordForm.form.valid">
                        Send me an email
                    </button>
                </form>
            </div>
        </div>

    </div>

</div>

