import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { AuthService } from '../../auth/auth.service';
import {VerifyClaimsComponent} from "./verify-claims.component";
import {OrganizationClaimService} from '../../../services/org-claim-request/org-claim-request.service';
import {VerifyOrganizationClaimsComponent} from "../../../app/admin/verify-organization-claims/verify-organization-claims.component";

@NgModule({
    imports: [
        ModalModule.forRoot(),
        BrowserModule,
        FormsModule,

        //Imports for typeahead
        BrowserAnimationsModule,
        TypeaheadModule.forRoot(),
    ],
    declarations: [
        VerifyClaimsComponent
    ],

    exports: [
        VerifyClaimsComponent
    ],

    providers: [
        OrganizationClaimService,
        AuthService
    ],
    entryComponents: [
        VerifyClaimsComponent
    ]
})
export class VerifyClaimsModule {}
