import { Component, OnInit } from '@angular/core';
import { DESIGN_TEAM, UI_DEV_TEAM, SERVER_TEAM } from '../about/team-information';

@Component({
    selector: 'about-team',
    templateUrl: './about-team.html',
    styleUrls: ['./about-team.scss']
})
export class AboutTeamComponent implements OnInit {
    designTeam = DESIGN_TEAM;
    devTeam = UI_DEV_TEAM;
    serverTeam = SERVER_TEAM;

    constructor() {}

    ngOnInit() {}
}
