import {Component} from '@angular/core';
import {DESIGN_TEAM, LEADER_TEAM, SERVER_TEAM, UI_DEV_TEAM} from "./team-information";

@Component({
    selector: 'about',
    templateUrl: './tech-team.html',
    styleUrls: ['./tech-team.scss'],
})
export class TechTeamComponent {
//    TODO: query About Us Text from PageContent.service.getContentByPage("About")
    designTeam = DESIGN_TEAM;
    devTeam = UI_DEV_TEAM;
    serverTeam = SERVER_TEAM;
    leaderTeam = LEADER_TEAM;

    constructor() {}

    ngOnInit() {}
}
