<div class="sidebar__search-results">
    <div *ngIf="allOrganizations != null">
        <div *ngFor="let org of allOrganizations">
            <div class="orgItem">
                <div *ngIf="org.name"><strong><a href="../organizations/{{org._id}}">{{org.name}}</a></strong></div>
                <div *ngIf="org.address">{{org.address}}</div>
                <!--            TODO: as of 8/15/20, the database only contains organizations within Colorado, thus ", CO" can be assumed,
                                        but as the database expands, it will likely contain orgs outside of CO and this line's
                                         <span> will need to be updated to {{org.state}}                     -->
                <div *ngIf="org.city">{{org.city}}<span>, CO</span></div>
<!--                <div *ngIf="org.telephoneNumber">{{org.telephoneNumber}}</div>-->

                <div *ngIf="org.isClaimed">
                    <button class="claimed-organization-button">
                        Verified <span class="fa fa-check fa" aria-hidden="true"></span>
                    </button>
                </div>

                <div *ngIf="!org.isClaimed">
                    <button class="claim-organization-button" (click)="createOrgClaimReq(org)">
                        Work here? Claim  <span style="color: gainsboro" class="fa fa-check" aria-hidden="true"></span>
                    </button>
                </div>
            </div>
        </div>
    </div>

<!--    If no results found-->
    <div *ngIf="allOrganizations.length === 0" class="sidebar__noResults noResults">
        <span class="sidebar__noResults__message noResults__message">
            <i class="fa fa-exclamation-triangle fa-2x"></i>
            <span class="noResults__message-text">
                We're sorry, we couldn't find any organizations that match your search</span>
        </span>
    </div>
</div>
