<div class="container fill-page">
    <div class="row">
        <div class="col-lg-8 offset-lg-2">
            <h2>Edit Page Content</h2>
            <div *ngIf="showAlert" class="col-xs-12">
                <div id="login-alert-wrapper">
                    <alert [type]="alert.type" [hidden]="!showAlert" [dismissible]="true" (onClose)="onClosed(alert)" id="login-page-alert" role="alert" class="alert {{alertType}}">
                        <span [innerHTML]="alert.msg"></span>
                    </alert>
                </div>
            </div>
            <div *ngIf="pageContent" class="col-xs-12 col-lg-12">
                <h3 class="content__name">{{ pageContent.name }}</h3>
                <div class="name-type">
                    <mat-form-field class="nameField" style="width: 100%;">
                        <mat-label>Page Content Name</mat-label>
                        <input matInput [formControl]="contentNameFormControl">
                    </mat-form-field>

                    <mat-form-field class="typeField" style="width: 100%;">
                        <mat-label>Content Type</mat-label>
                        <mat-select [formControl]="contentTypeFormControl">
                            <mat-option *ngFor="let type of contentTypes" [value]="type">{{type}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <ng-container *ngIf="isCard(pageContent)">
                    <div>
                        <mat-form-field class="contentField" style="width: 100%;">
                            <mat-label>Card Text</mat-label>
                            <quill-material id="summary" [formControl]="contentTextFormControl" placeholder="The text you want displayed in your Card">
                            </quill-material>
                        </mat-form-field>

                        <!--                    <quill-editor class="contentField"-->
                        <!--                                  [formControl]="contentTextFormControl"-->
                        <!--                                  placeholder="A brief summary of your study"-->
                        <!--                                  [modules]="quillConfig"-->
                        <!--                                  (onSelectionChanged)="onSelectionChanged($event)"-->
                        <!--                                  (onContentChanged)="onContentChanged($event)">-->
                        <!--                    </quill-editor>-->
                    </div>

                    <div class="cardBtnInfo">
                        <mat-form-field class="cardBtnInfo__text" style="width: 100%;">
                            <mat-label>Button Text</mat-label>
                            <input matInput [formControl]="contentBtnTextControl">
                        </mat-form-field>

                        <mat-form-field class="cardBtnInfo__link" style="width: 100%;">
                            <mat-label>Button URL</mat-label>
                            <input matInput [formControl]="contentBtnLinkControl">
                        </mat-form-field>
                    </div>
                    <br>
                </ng-container>

                <ng-container *ngIf="isText(pageContent)">
                    <div>
                        <mat-form-field class="contentField" style="width: 100%;">
                            <mat-label>Content Text</mat-label>
                            <quill-material id="summary" [formControl]="contentTextFormControl" placeholder="The text you want displayed in your content">
                            </quill-material>
                        </mat-form-field>

                        <!--                    <quill-editor class="contentField"-->
                        <!--                                  [formControl]="contentTextFormControl"-->
                        <!--                                  placeholder="A brief summary of your study"-->
                        <!--                                  [modules]="quillConfig"-->
                        <!--                                  (onSelectionChanged)="onSelectionChanged($event)"-->
                        <!--                                  (onContentChanged)="onContentChanged($event)">-->
                        <!--                    </quill-editor>-->
                    </div>
                    <br>
                </ng-container>


<!--                TODO: img upload -->
<!--                <label class="btn">-->
<!--                    <span>Select Image</span>-->
<!--                    <input #imageInput-->
<!--                           type="file"-->
<!--                           accept="image/*">-->
<!--                </label>-->
<!--                <div *ngIf="selectedFile" class="img-preview-container">-->

<!--                    <div class="img-preview{{selectedFile.status === 'fail' ? '-error' : ''}}"-->
<!--                         [ngStyle]="{'background-image': 'url('+ selectedFile.src + ')'}">-->
<!--                    </div>-->

<!--                    <div *ngIf="selectedFile.pending" class="img-loading-overlay">-->
<!--                        <div class="img-spinning-circle"></div>-->
<!--                    </div>-->

<!--                    <div *ngIf="selectedFile.status === 'ok'" class="alert alert-success"> Image Uploaded Successfully!</div>-->
<!--                    <div *ngIf="selectedFile.status === 'fail'" class="alert alert-danger"> Image Upload Failed!</div>-->
<!--                </div>-->
                <div class="btn-wrapper">
<!--                    TODO: img upload-->
<!--                    <button class="btn btn-primary" (click)="updatePageContent(imageInput, pageContent.name)">Update Page Content</button>-->
                    <button class="btn btn-primary" (click)="updatePageContent()">Update Page Content</button>

                </div>
                <br><br>
            </div>
        </div>
    </div>
</div>
