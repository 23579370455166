<div class="container-fluid standard-div standard-div-light-gray">
    <div class="row">
        <div class="col-12 col-md-3" style="padding: 1em;">
            <router-outlet name="sidemenu"></router-outlet>
        </div>
        <div class="col-12 col-md-9" style="padding: 1em 3em 1em 3em;">
            <img src="/116eefecd7bad9efd106b3e2b0aef019.jpg" alt="Health Equity Colorado" width="90%">
            <h4>Job Board</h4>
            <p>
                This section will be populated by subscribers through their page portal.
            </p>
        </div>
    </div>
</div>

