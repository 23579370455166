<div class="modal-content orgClaimModal">
    <form id="claim-organization" name="claim-organization" role="form" class="form-horizontal">
        <div class="modal-header">
            <h5 id="myModalLabel" class="modal-title pull-left">Claim Organization: {{ organization.name}}</h5>
            <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div role="alert" [hidden]="!formError" class="alert alert-danger">{{ formError }}</div>
            <div role="alert" [hidden]="!formInfo" class="alert alert-info">{{ formInfo }}</div>

            <form>
                <div class="form-row">
                    <div class="form-group col-6">
                        <label for="claimantFirstName" class="control-label">First Name</label>
                        <input id="claimantFirstName" name="claimantFirstName" required="false" placeholder="{{ claimant?.name?.first || 'claimant First Name'}}" class="form-control" readonly="readonly">
                    </div>

                    <div class="form-group col-6">
                        <label for="claimantLastName" class="control-label">Last Name</label>
                        <input id="claimantLastName" name="claimantLastName" required="false" placeholder="{{ claimant?.name?.last || 'claimant Last Name'}}" class="form-control" readonly="readonly">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="claimantAcctEmail" type="email" class="control-label">Account email address</label>
                        <input id="claimantAcctEmail" name="claimantAcctEmail" required="false" type="email" placeholder="{{ claimant?.email || 'userAcctEmail@example.com'}}" class="form-control" readonly="readonly">
                    </div>
                </div>

               <div class="form-row">
                    <div class="form-group col-12 col-md-5 col-lg-5">
                        <label for="claimantPhoneNumber" type="tel" class="control-label">Direct Phone Line</label>
                        <input id="claimantPhoneNumber" name="claimantPhoneNumber" [(ngModel)]="claimRequest.claimant.directLine" (ngModelChange)="normalizePhone($event)" type="tel" placeholder="Phone Number" class="form-control" required>
                    </div>
                    <div class="form-group col-12 col-md-7 col-lg-7">
                        <label for="claimerOrgRole" class="control-label">Role within the Organization</label>
                        <input id="claimerOrgRole" name="claimerOrgRole" required="required" placeholder="Role" [(ngModel)]="claimRequest.claimant.orgRole" [typeahead]="orgRoles" class="form-control">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="claimerWorkEmail" type="email" class="control-label">Work email address</label>
                        <input id="claimerWorkEmail" name="claimerWorkEmail" [(ngModel)]="claimRequest.claimant.orgEmail" required="true" type="email" placeholder="{{ claimant?.email || 'userAcctEmail@example.com'}}" [typeahead]="[claimRequest.claimant.orgEmail]" class="form-control">
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-12">
                        <label for="claimerAddInfo" class="control-label">Additional Information (optional)</label>
                        <textarea id="claimerAddInfo" name="claimerAddInfo" rows="5" required="false" placeholder="" [(ngModel)]="claimRequest.additionalInfo" class="form-control"></textarea>
                    </div>
                </div>
            </form>


        </div>
        <div class="modal-footer">
            <button (click)="bsModalRef.hide()" type="button" class="btn btn-secondary">
                Cancel</button>
            <button type="submit" class="btn btn-primary" (click)="submitOrgClaim()">Submit Claim Request</button>
        </div>
    </form>
</div>


