// @flow
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../components/auth/auth.service';
import constants from '../../app.constants';
import { FormControl, FormGroup, Validators } from '@angular/forms';

interface User {
    name: {
        title: string
        first: string
        middle: string
        last: string
        suffix: string
    };
    email: string;
    password: string;
    confirmPassword: string;
}

@Component({
    selector: 'signup',
    templateUrl: './signup.html',
    styleUrls: ['./signup.scss']

})
export class SignupComponent {
    user: User = {
        name: {
            title: 'None',
            first: '',
            middle: '',
            last: '',
            suffix: ''
        },
        email: '',
        password: '',
        confirmPassword: ''
    };

    errors: { field?: Error } = {};
    submitted = false;
    AuthService;
    Router;
    return = '/home';
    signupQueryParams = '';
    userForm: {};


    static parameters = [AuthService, Router];
    constructor(_AuthService_: AuthService, router: Router) {
        this.AuthService = _AuthService_;
        this.Router = router;
    }

    ngOnInit() {
        // Get the query params for redirect url
        if (this.Router.currentUrlTree.queryParams.return) {
            this.signupQueryParams = '?return=' + this.Router.currentUrlTree.queryParams.return;
            this.return = this.Router.currentUrlTree.queryParams.return;
        }
        this.userForm = new FormGroup({
            password: new FormControl(this.user.password, Validators.required),
            confirmPassword: new FormControl(this.user.confirmPassword, [Validators.required]),
        }); /*, {validator: matchPassword})*/

        function matchPassword() {
            const password = this.userForm.password;
            const confirmPassword = this.userForm.confirmPassword;
            if(password === confirmPassword){return null}
            return {passwordMismatch: true};
        }

    }

    titleEnums = constants.nameTitles;

    register(form) {
        if (form.invalid) return;

        this.submitted = true;

        return this.AuthService.createUser({
            name: this.user.name,
            email: this.user.email.toLowerCase(),
            password: this.user.password
        })
            .then(() => {
                // Account created, redirect to home test
                this.Router.navigateByUrl(this.return);
            })
            .catch((err: { errors: { field: any } }) => {
                this.errors = err.errors;
                // Update validity of form fields that match the mongoose errors
                Object.entries(err.errors).forEach(([field, error]: [string, any]) => {
                    this.errors[field] = error.message;

                    if (field === 'email' && error.kind === 'user defined') {
                        form.form.controls[field].setErrors({ inUse: true });
                    }
                });

                this.submitted = false;
            });
    }
}



