import {Card} from '../../../components/interfaces/PageContent';
/*FIXME: This is a temporary solution until the page content API is advanced enough to handle:
                - images
                - button text
                - button links (both internal and external)
                - inline or nested elements/styling (e.g. make certain words bold/italic/colored, a hyperlink within a text block)
     */
//Creates an array which populates slideshow on homepage
export var default_cards: Card[] = [
    {
        name: 'Homepage Card 1',
        image: '../../assets/images/homepage-cards/card-img-1.jpg',
        text: 'PLACES, a collaboration between CDC, the Robert Wood Johnson Foundation, and the CDC Foundation, ' +
            'allows local health departments and jurisdictions regardless of population size and urban-rural status ' +
            'to better understand the burden and geographic distribution of health-related outcomes in their areas ' +
            'and assist them in planning public health interventions.',
        btn_text: 'Learn More',
        btn_link: 'https://www.cdc.gov/places/',
        imgUrl: '../../assets/images/homepage-cards/card-img-1.jpg',
        relativeOrder: 1
    },
    {
        name: 'Homepage Card 2',
        image: '../../assets/images/homepage-cards/card-img-2.png',
        text: 'Health Equity: Expanding the Justice Conversation is an innovative multidisciplinary ' +
            'program designed to empower those interested in advancing health equity and in supporting diversity and ' +
            'inclusion in healthcare. Register for this two-day (May 3-4, 2022) conference full of keynote ' +
            'speakers, panels and as always our signature lunch, included in every ticket, hosted by Colorado ' +
            'Healthcare Ethics Forum.',
        btn_text: 'Register here',
        btn_link: 'https://www.eventbrite.com/e/health-equity-expanding-the-justice-conversation-tickets-291061120647',
        imgUrl: '../../assets/images/homepage-cards/card-img-2.png',
        relativeOrder: 0
    },
    {
        name: 'Homepage Card 3',
        image: '../../assets/images/homepage-cards/card-img-3.jpg',
        text: '\"Dear Black people in healthcare, My name is Ashley McMullen. I am a Black woman, sister, aunt, '
            + 'and daughter. I am queer. I am a doctor. I am an Assistant Professor of Medicine at UCSF. This past '
            + 'week I have reached new depths of exhaustion, rage, and despair watching yet another Black human being '
            + 'murdered in plain sight. But I have also reached new highs of compassion, dialogue, and finally '
            + 'having some visibility and validation of what it means to be Black in America.\"',
        btn_text: 'Black Voices in Healthcare',
        btn_link: 'http://thenocturnists.com/blackvoices',
        imgUrl: '../../assets/images/homepage-cards/card-img-3.png',
        relativeOrder: 3
    },
    {
        name: 'Homepage Card 4',
        image: '../../assets/images/homepage-cards/card-img-4.png',
        text: 'The 2021 State of the State Survey Final Report is Here!' +
            'In 2021, Envision:You surveyed nearly 600 LGBTQ+ Coloradans regarding their behavioral health ' +
            'concerns and experiences to inform LGBTQ+ behavioral health priorities for community organizations ' +
            'and state leaders.',
        btn_text: 'Read the Report',
        btn_link: 'https://www.envision-you.org/state-of-the-state',
        imgUrl: '../../assets/images/homepage-cards/card-img-4.png',
        relativeOrder: 2
    },
    {
        name: 'Homepage Card 5',
        image: '../../assets/images/homepage-cards/card-img-5.jpg',
        text: 'Taking Action to Promote Health Equity – Using the Lessons from Cutting-Edge Practices to Improve Health'
            + ' and Well Being by Trust for America’s Health, features national public health practitioners and '
            + 'community leaders sharing their experiences shaping and executing programs to increase health equity '
            + 'in their communities. The webinar series is designed to inform a broad, national audience about '
            + 'compelling and replicable health equity initiatives and how to address the grass roots issues that '
            + 'will impact their success.',
        btn_text: 'Learn more',
        btn_link: 'https://www.tfah.org/article/tfah-webinar-series-on-achieving-health-equity-through-collaborations-innovative-funding-and-leadership/',
        imgUrl: '../../assets/images/homepage-cards/card-img-5.jpg',
        relativeOrder: 4
    },
    {
        name: 'Homepage Card 6',
        image: '../../assets/images/homepage-cards/card-img-6.jpg',
        text: 'Latest research from Human Impact Partners! The COVID-19 pandemic — and the large number of cases and'
            + ' deaths in jails, prisons, and detention centers — has laid bare the urgent need for decarceration as '
            + 'a public health strategy. This resource includes 8 recommendations and specific actions health '
            + 'departments can take to address the harms of incarceration.',
        btn_text: 'Read the report',
        btn_link: 'https://humanimpact.org/hipprojects/decarceration-framework/?strategy=all',
        imgUrl: '../../assets/images/homepage-cards/card-img-6.png',
        relativeOrder: 5
    },
];
