import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {ResourcesComponent} from './resources.component';
// @ts-ignore
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';

export const ROUTES: Routes = [
    {
        path: 'resources/search/:query',
        component: ResourcesComponent,
    },
    {
        path: 'resources',
        component: ResourcesComponent,
    },
];

@NgModule({
    imports: [
        BrowserModule,
        RouterModule.forChild(ROUTES),
        FontAwesomeModule,
        BrowserAnimationsModule,
        BsDropdownModule.forRoot(),
        FormsModule,
        NgxPaginationModule
    ],
    declarations: [
        ResourcesComponent
    ],

    exports: [
        ResourcesComponent,
    ]
})
export class ResourcesModule {
}
