import {Component, OnInit} from '@angular/core';


declare var $: any;

@Component({
    selector: 'news',
    templateUrl: './news.html',
    styleUrls: ['./news.scss'],
})
export class NewsComponent implements OnInit {
    //TODO: Create a News API for Organizations' News stories + query for content


    ngOnInit(): void {
        $('#news-slider2').owlCarousel({
            items: 3,
            itemsDesktop: [1199, 2],
            itemsDesktopSmall: [980, 2],
            itemsMobile: [600, 1],
            pagination: true,
            navigationText: false,
            autoPlay: true
        });
        $('button').click(function () {
            alert('Button Clicked');
            console.log('Button Clicked');
        });
    }
}
