import {Component, OnInit} from '@angular/core';
import {PageContentService} from '../../services/page-content/page-content.service';
import {PageContent} from '../../components/interfaces/PageContent';
import {BreakpointObserver} from "@angular/cdk/layout";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'about',
    templateUrl: './about.html',
    styleUrls: ['./about.scss'],
})
export class AboutComponent implements OnInit {
    aboutUsText;

    static parameters = [PageContentService];
    constructor(public pageContentService: PageContentService) {
        this.pageContentService = pageContentService;
    }

    ngOnInit() {
        this.pageContentService.getContentByPage('About').subscribe((pageContent: any) => {
            this.aboutUsText = (pageContent as PageContent[])
                .filter(c => c.section.includes('Main Text'))
                .sort((a, b) => a.name.localeCompare(b.name));
        });
    }
}
