import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Image} from '../../components/interfaces/Image';
import {CustomEncoder} from '../customEncoder';

@Injectable({
    providedIn: 'root'
})

//Methods to communicate with the resources API
export class ImageService {
    public baseUrl = '/api/images';
    static parameters = [HttpClient];
    constructor(public http: HttpClient) { }

    upsertImage(image: any, organizationName?: any): Observable<Image> {
        const formData = new FormData();
        let newName = image.name.replace(/\s/g, '-');
        formData.append('file', image, newName);
        if (organizationName) {
            let params = new HttpParams({encoder: new CustomEncoder()})
                .set('organizationName', organizationName);
            return this.http.put(this.baseUrl, formData, {
                params: params
            }) as Observable<Image>;
        } else {
            return this.http.put(this.baseUrl, formData) as Observable<Image>;
        }
    }

    download(fileKey): Observable<any> {
        return this.http.get(`${this.baseUrl}/${fileKey}`, { responseType: 'blob' });
    }

    getImageByID(id): Observable<Response> {
        return this.http.get(this.baseUrl + id, {
            params: {
                imageId: id
            }
        }) as Observable<Response>;
    }
}
