import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { NewsComponent } from './news.component';
import {SideMenuComponent} from '../../components/side-menu/side-menu.component';


export const ROUTES: Routes = [
    {
        path: 'news',
        component: NewsComponent,
        children: [
            {
                path: '',
                outlet: 'sidemenu',
                component: SideMenuComponent
            }
        ]
    },
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
    ],
    declarations: [
        NewsComponent,
    ],

    exports: [
        NewsComponent,
    ],
})
export class NewsModule {}
