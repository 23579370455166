<div class="container-fluid standard-div" style="font-size: 14px">
    <div class="row verify-div">
        <div class="customized-verify-text-container bg-gradient-primary col-sm-12 col-md-5 offset-md-2 margin-top-5light">
            <strong style="font-size: 18px">Almost Ready!</strong>
            <p>Please verify that the email address displayed below is correct.</p>
            <p class="email">{{email}}</p>
            <p>If it is, click the verify button to the right to continue.</p>
            <p>if not, click <b (click)="signupAgain()" class="clickable">here</b> to return to the signup screen and reenter your information.</p>
            <div class="alert-container" *ngIf="alert.show">
                <alert type="{{alert.type}}" [dismissible]="true" (onClosed)="onClosed()">
                    <span>{{alert.message}}</span>
                </alert>
            </div>
        </div>
        <div class="customized-verify-button-container col-sm-12 col-md-2">
            <button class="customized-button btn-lg btn btn-primary" (click)="verfiy()">
                Verify
            </button>
        </div>
    </div>
</div>
