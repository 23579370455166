<div class="container-fluid standard-div">
    <div class="row">
<!--        <div class="d-none d-lg-block col-lg-3" style="padding: 1em;">-->
<!--            <router-outlet name="sidemenu"></router-outlet>-->
<!--        </div>-->

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-11 org-details">
            <div *ngIf="organization">
                <div *ngIf="organization.bannerImage; else bannerBlock" class="banner" style="margin-bottom: 0.5em">
                    <img [src]="organization.bannerImage" alt="banner" width="100%">
                </div>
                <ng-template #bannerBlock class="banner">
<!--                    <img src="../../../assets/images/compLogos/IRISEResized.png" alt="banner" width="100%">-->
                </ng-template>

                <div class="org-details__top-btns">
                    <a *ngIf="organization.website" target="_blank" [href]="organization.website">
                        <button class="claim-organization-button claim-button--unclaimed">
                            Visit Website
                        </button>
                    </a>

                    <button *ngIf="organization.isClaimed" class="claim-organization-button claim-button--claimed" tooltip="All data is provided by the organization">
                        Claimed
                        <span class="fa fa-check fa" aria-hidden="true"></span>
                    </button>

                    <button *ngIf="!organization.isClaimed" class="claim-organization-button claim-button--unclaimed" (click)="createOrgClaimReq(organization)" tooltip="All data is publicly sourced until claimed by organization">
                        Work here? Claim
                        <span style="color: gainsboro" class="fa fa-check" aria-hidden="true"></span>
                    </button>

<!--                    <button class="pull-right follow-button" *ngIf="hasAuth" [routerLink]="['/organizations', organization._id, 'edit']">-->
<!--                        Edit-->
<!--                    </button>-->

                    <div *ngIf="organization.socialMedia" class="socialmediaicons">
                        <a *ngIf="organization.socialMedia.facebook" target="_blank" href="{{'https://facebook.com/' + organization.socialMedia.facebook}}" class="fa fa-facebook" role="button"></a>
                        <a *ngIf="organization.socialMedia.linkedin" target="_blank" href="{{'https://linkedin.com/' + organization.socialMedia.linkedin}}" class="fa fa-linkedin" role="button"></a>
                        <a *ngIf="organization.socialMedia.twitter" target="_blank" href="{{'https://twitter.com/' + organization.socialMedia.twitter}}" class="fa fa-twitter" role="button"></a>
                        <a *ngIf="organization.socialMedia.instagram" target="_blank" href="{{'https://instagram.com/' + organization.socialMedia.instagram}}" class="fa fa-instagram" role="button"></a>
                    </div>
                </div>
                <br>
                <div class="title">
                    <h4>{{ organization.name }}</h4>
                </div>
<!--                <div class="web pull-right">-->
<!--                    <a *ngIf="organization.website" target="_blank" [href]="organization.website"><button class="website">Visit Website</button></a>-->
<!--                </div>-->
<!--                <div class="follow pull-right">-->
<!--                    <button class="follow-button">Follow</button>-->
<!--                </div>-->

                <h5>About</h5>
                <div *ngIf="organization.about && organization.about.length > 0; else aboutBlock">
                    <p *ngIf="!defaultAbout && organization.about.length < 200">
                        {{organization.about}}
                    </p>
                    <p style="width:95%" *ngIf="organization.about.length >= 200">
                        {{organization.about.slice(0, organization.about.length/4)}}
                        <span class="dots">...</span>
                            <button (click)="revealOrHide()" class="reveal">See more</button>
                            <span class="more">
                                {{organization.about.slice(organization.about.length/4)}}
                            </span>
                            <br> <button (click)="revealOrHide()" class="hide">See less</button>
                    </p>
                </div>
                <ng-template #aboutBlock>
                    <p class="about">
                        {{defaultAbout}}
                    </p>
                    <p class="about" style="width: 95%">
                        Do you work at this organization? Claim it to change the description!
                    </p>
                </ng-template>
                <br>

                <!--all tabs need to be implemented -->
                <mat-tab-group>
                    <mat-tab label="Contact Us">
                        <div class="contact">
                            <h4>{{organization.name}}</h4>
                            <p *ngIf="organization.contactMessage && organization.contactMessage.length > 0; else contactBlock" class="message">
                                {{organization.contactMessage}}
                            </p>
                            <ng-template #contactBlock>
                                <p class="message">
                                    {{defaultContact}}
                                </p>
                            </ng-template>
                            <div class="contactInfo">
                                <h5>Connect With Us:</h5>
                                <p *ngIf="organization.telephoneNumber">Telephone: <span class="pull-right">{{organization.telephoneNumber }}</span></p>
                                <div *ngIf="organization.email">
                                    <p *ngIf="!organization.email.startsWith('N/A')">Email: <a href="mailto:{{organization.email}}" class="pull-right">{{organization.email}}</a></p>
                                    <p *ngIf="organization.email.startsWith('N/A')">Email: &#9;{{organization.email}}</p>
                                </div>
                                <div *ngIf="organization.socialMedia" class="socialmediaicons">
                                    <a *ngIf="organization.socialMedia.facebook" target="_blank" href="{{ organization.socialMedia.facebook}}" class="fa fa-facebook" role="button"></a>
                                    <a *ngIf="organization.socialMedia.linkedin" target="_blank" href="{{ organization.socialMedia.linkedin}}" class="fa fa-linkedin" role="button"></a>
                                    <a *ngIf="organization.socialMedia.twitter" target="_blank" href="{{ organization.socialMedia.twitter}}" class="fa fa-twitter" role="button"></a>
                                    <a *ngIf="organization.socialMedia.instagram" target="_blank" href="{{ organization.socialMedia.instagram}}" class="fa fa-instagram" role="button"></a>
                                </div>
                            </div>
                            <div class="otherInfo">
                                <h5>Address:</h5>
                                <p *ngIf="organization.address">{{organization.address}},
                                    <span *ngIf="organization.city">{{organization.city}}, </span>
                                    <span *ngIf="organization.state">{{organization.state}} </span>
                                    <span *ngIf="organization.zip">{{organization.zip}}</span>
                                </p>
                                <p style="font-weight: bold;">Hours:</p>
                                <div *ngIf="organization.hoursOfOperation.length!==0; else:hoursBlock" class="hours">
                                    <p *ngFor="let day of organization.hoursOfOperation">{{day.dayOfWeek}} <span *ngIf="!day.noWorkHours" class="pull-right">{{day.openingTime}}-{{day.closingTime}}</span><span *ngIf="day.noWorkHours" class="pull-right">Closed</span></p>
                                </div>
                                <ng-template #hoursBlock>
                                    <p>No hours set</p>
                                </ng-template>
                            </div>
                        </div>
                    </mat-tab>
                    <mat-tab label="Resources">
                        <ng-template matTabContent>
                            <!--                If org DOES have Resources -->
                            <div class="results-tab org-resources__resources" *ngIf="organizationResources.length > 0">
                                <h4>Resources</h4>
                                <ul class="resources-container">
                                    <li *ngFor="let resource of organizationResources" class="resource-container">
                                        <p class="resource-title">{{resource.resourceTitle}}</p>
                                        <p class="resource-content">{{resource.organizationName}} - <i>{{resource.year}}</i></p>
                                        <a href="{{resource.resourceURL}}" target="_blank" type="button" class="read-more">Read More </a>
                                        <div class="buttonSection">
                                            <a (click)="deleteResource(resource)" class="trash"><span class="fa fa-trash fa-2x"></span></a>&nbsp;
                                            <!-- clicking on the edit button redirects to the edit-organization page -->
                                            <a [title]="organization.name + ' details'" [routerLink]="['/resources', resource._id, 'admin']" class="edit"><span class="fa fa-pencil-square-o fa-2x"></span></a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <!--                If Org does NOT have any resouces   -->
                            <div class="results-tab org-resources__resources org-resources__resources--none" *ngIf="organizationResources.length === 0">

                                <div class="row search-result resource resource--none">
                                    <div class="col-12 row no-results">
                                        <div class="col-12 col-xl-1 search-result-image resource__img">
                                            <i class="fa fa-exclamation-triangle fa-4x"></i>
                                        </div>
                                        <div class="col-12 col-xl-10 search-result-text resource__text" style="align-self: center">
                                            <p><em>{{organization.name}}</em> has not posted any resources yet.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </mat-tab>
                    <!--                    class="col-xs-12 col-lg-10 offset-lg-1" dynamicHeight (selectedTabChange)="handleTabChange($event)">-->
                    <!--                    TODO: events implementation -->
                    <mat-tab label="Events">
                        <ng-template matTabContent>
                            <div class="contact">
                                <h4>Events to Come</h4>
                            </div>
                        </ng-template>
                    </mat-tab>
                    <!--                    TODO: news implementation-->
                    <mat-tab label="News">
                        <ng-template matTabContent>
                            <div class="contact">
                                <h4>News to Come</h4>
                            </div>
                        </ng-template>
                    </mat-tab>

                    <!--                    TODO: Job opportunities implementation -->
                    <mat-tab label="Job Opportunities">
                        <ng-template matTabContent>
                            <div class="contact">
                                <h4>Jobs to Come</h4>
                            </div>
                        </ng-template>
                    </mat-tab>

                    <mat-tab *ngIf="hasAuth" label="Admin">
                        <ng-template matTabContent>
                            <edit-organization-details></edit-organization-details>
                        </ng-template>
                    </mat-tab>
                </mat-tab-group>
<!--                <div class="lower-content">-->
<!--                    <div class="org-navbar">-->
<!--                        <a>Events</a>-->
<!--                        <a>News</a>-->
<!--                        <a href="../resources/{{organization._id}}">Resources</a>-->
<!--                        <a>Job Opportunities</a>-->
<!--                        <a class="active">Contact Us</a>-->
<!--                    </div>-->
<!--                <div class="jobs">-->
<!--                    Jobs-->
<!--                </div>-->

                </div>
            </div>
        </div>
    </div>

