<div class="col-lg-12 sidebar-offcanvas" id="sidebar">
    <div class="list-group">
        <a routerLink="/news" class="list-group-item"><i class="fa fa-newspaper-o"></i>&nbsp;&nbsp;News & Calls to Action</a>
        <p class="list-group-item">This section will feature scrolling health equity updates posted by our team and also by subscribers through their pages.
            Subscribers can post a call to action, news event, or collaboration opportunity for a specified date range.  The most recent postings will appear first</p>
        <p class="list-group-item">&nbsp;&nbsp;</p>
        <!--<p class="list-group-item">&nbsp;&nbsp;</p>-->
        <p class="list-group-item" style="border-bottom: 2px solid #ddd">&nbsp;&nbsp;</p>
        <!--<p class="list-group-item">&nbsp;&nbsp;</p>-->
        <!--<p class="list-group-item">&nbsp;&nbsp;</p>-->
        <!--<p class="list-group-item">&nbsp;&nbsp;</p>-->

        <!--<a routerLink="/home" class="list-group-item"><i class="fa fa-home"></i>&nbsp;&nbsp;Home</a>-->
        <!--<a routerLink="/about" class="list-group-item"><i class="fa fa-building"></i>&nbsp;&nbsp;About Us</a>-->
        <!--<a routerLink="/funding" class="list-group-item"><i class="fa fa-money"></i>&nbsp;&nbsp;Funding Opportunities</a>-->
        <!--<a routerLink="/jobs" class="list-group-item"><i class="fa fa-user-md"></i>&nbsp;&nbsp;Job Board</a>-->
        <!--<a routerLink="/news" class="list-group-item"><i class="fa fa-newspaper-o"></i>&nbsp;&nbsp;News & Calls to Action</a>-->
        <!--<a routerLink="/subscribe" class="list-group-item"><i class="fa fa-envelope"></i>&nbsp;&nbsp;Subscribe</a>-->
        <!--<a routerLink="/contact" class="list-group-item"><i class="fa fa-envelope-o"></i>&nbsp;&nbsp;Contact</a>-->
        <!--<a routerLink="/events" class="list-group-item"><i class="fa fa-calendar"></i>&nbsp;&nbsp;Events</a>-->
        <!--<a routerLink="/hubs" class="list-group-item" style="border-bottom: 2px solid #ddd"><i class="fa fa-anchor"></i>&nbsp;&nbsp;Hubs</a>-->
        <a routerLink="/account/searches" class="list-group-item"><i class="fa fa-google"></i>&nbsp;&nbsp;My Searches</a>
        <a routerLink="/account/lists" class="list-group-item" style="border-bottom: 2px solid #ddd"><i class="fa fa-list"></i>&nbsp;&nbsp;My Lists</a>
        <a routerLink="/signup" class="list-group-item"><i class="fa fa-plus-square"></i>&nbsp;&nbsp;Add New Profile</a>
        <div class="list-group-item"></div>
    </div>
</div>
