<div class="container modal-content modal-lg col-12">
    <div class="row">
        <div class="modal-header col-12">
            <h5 id="myModalLabel" class="modal-title pull-left">All Pending Claims</h5>
            <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="left-side col-6">
            <h2 style="text-decoration: underline">Claim {{index + 1}}/{{allClaims.length}}</h2>
            <p>Claimant Name: <span class="org-data">{{allClaims[index].claimant.name.first}} {{allClaims[index].claimant.name.last}}</span>
            </p>
            <p>Claimant Role: <span class="org-data">{{allClaims[index].claimant.orgRole}}</span></p>
            <p>Claimant Email: <span class="org-data">{{allClaims[index].claimant.orgEmail}}</span></p>
            <p>Claimant Phone Number: <span class="org-data">{{allClaims[index].claimant.directLine}}</span></p>
            <p>Date Submitted: <span class="org-data">{{allClaims[index].requestDate}}</span></p>
            <p>Additional Information: <span class="org-data">{{allClaims[index].additionalInfo}}</span></p>

        </div>

        <div class="right-side col-6">
            <h2 style="text-decoration: underline">{{allClaims[index].organization.name}}</h2>
            <!--        I'm assuming the first administrator is the point of contact-->
            <!--        <p>Point of Contact Name: {{claim.organization.administrators[0].name.first || "N/A"}} {{claim.organization.administrators[0].name.last || ""}}</p>-->
            <!--        <p>Point of Contact Role: <span *ngIf ></p>-->
            <p>Organization Email: <span class="org-data">{{allClaims[index].organization.email || "N/A"}}</span></p>
            <p>Organization Number: <span class="org-data">{{allClaims[index].organization.telephoneNumber}}</span></p>
            <!--        We are not keeping track of an org's creation date-->
            <!--        <p>Organization Creation Date: {{claim.requestDate}}</p>-->

        </div>
        <div class="bottom-part col-12">
            <button class="btn btn-outline-primary nav-button" (click)="prev()" [disabled]="index < 1"> &lt;&lt; Prev. claim
            </button>
            <button class="btn btn-outline-success approve-button" (click)="approveClaim(allClaims[index])">Approve
                Claim
            </button>
            <button class="btn btn-outline-danger deny-button" (click)="denyClaim(allClaims[index])">Deny Claim</button>
            <button class="btn btn-outline-primary nav-button" (click)="next()" [disabled]="index >= allClaims.length-1">Next claim >>
            </button>
        </div>
    </div>
</div>

