import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MainComponent } from './main.component';
import { SideMenuComponent } from '../../components/side-menu/side-menu.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import {LoginModalComponent} from '../../components/modals/login-modal/login-modal.component';
import {DirectivesModule} from '../../components/directives.module';
import {SignupModalComponent} from '../../components/modals/signup-modal/signup-modal.component';
import {ForgotPasswordModalComponent} from '../../components/modals/forgot-password-modal/forgot-password-modal.component';
import {ResetPasswordModalComponent} from '../../components/modals/reset-password-modal/reset-password-modal.component';
import {QuillModule} from 'ngx-quill';
import { CarouselModule } from 'primeng/carousel';
import { CardModule, } from 'primeng/card';
import {ButtonModule} from 'primeng/button';
import {ToastModule} from 'primeng/toast';

export const ROUTES: Routes = [
    {
        path: 'home',
        component: MainComponent,
        children: [
            {
                path: '',
                outlet: 'sidemenu',
                component: SideMenuComponent
            }
        ]
    },
];

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        CarouselModule,
        CardModule,
        ButtonModule,
        ToastModule,
        TooltipModule.forRoot(),
        AccordionModule.forRoot(),
        DirectivesModule,
        ReactiveFormsModule,
        QuillModule
    ],
    declarations: [
        MainComponent,
        LoginModalComponent,
        SignupModalComponent,
        ForgotPasswordModalComponent,
        ResetPasswordModalComponent
    ],

    exports: [
        MainComponent,
        LoginModalComponent,
        SignupModalComponent,
        ForgotPasswordModalComponent,
        ResetPasswordModalComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ],
    entryComponents: [
        LoginModalComponent,
        SignupModalComponent,
        ForgotPasswordModalComponent,
        ResetPasswordModalComponent
    ]
})
export class MainModule {}
