<div class="container standard-div col-12">
    <div class="login-alert-wrapper login-page-alert" style="text-align: center" *ngIf="searchString != '' && searchClaims.length == 0">
        {{alertMessage}}
    </div>
    <div class="row">
        <div class="top_bar">
            <h1>All Pending Claims</h1>
            <input class="search__bar" type="text" placeholder="Search claims..." (keyup)="search()" [(ngModel)]="searchString">
        </div>
        <div *ngIf="!!recentlyChanged && !!recentlyChanged[0]">
            <p> {{recentlyChanged[0].claimant.name?.first}} {{recentlyChanged[0].claimant.name?.last}}'s claim for {{recentlyChanged[0].organization?.name}} was {{recentlyChanged[0].status}}</p>
            <button (click)="undo()">Undo</button>
        </div>
    </div>
    <div>
        <table mat-table [dataSource]="dataSource" style="width: 100%">
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef> Date</th>
                <td mat-cell *matCellDef="let claim">{{claim.requestDate}}</td>
            </ng-container>
            <ng-container matColumnDef="organization">
                <th mat-header-cell *matHeaderCellDef> Organization</th>
                <td mat-cell *matCellDef="let claim">{{claim.organization?.name}}</td>
            </ng-container>
            <ng-container matColumnDef="claimantName">
                <th mat-header-cell *matHeaderCellDef> Claimant Name</th>
                <td mat-cell *matCellDef="let claim">{{claim.claimant.name?.first}} {{claim.claimant.name?.last}}</td>
            </ng-container>
            <ng-container matColumnDef="role">
                <th mat-header-cell *matHeaderCellDef> Role</th>
                <td mat-cell *matCellDef="let claim">{{claim.claimant.orgRole}}</td>
            </ng-container>
            <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef> Email</th>
                <td mat-cell *matCellDef="let claim">{{claim.claimant.orgEmail}}</td>
            </ng-container>
            <ng-container matColumnDef="decision">
                <th mat-header-cell *matHeaderCellDef> Decision</th>
                <td mat-cell *matCellDef="let claim; index as i">
                    <button class="claim-button" (click)="clicked(i, allClaims)">Pending</button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
    </div>
</div>

