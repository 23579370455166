import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap/modal';
import { UserService } from '../../components/auth/user.service';
import { OrganizationService } from '../../services/organization/organization.service';
import { ResourceSearchFilters, ResourceService } from '../../services/resource/resource.service';
import { Organization } from '../organizations/organization';
import { PageContentService } from '../../services/page-content/page-content.service';
import { PageContent } from '../../components/interfaces/PageContent';
import { Resource } from '../resources/resource';
import shared from '../../../server/config/environment/shared';
import { FormBuilder, FormGroup } from '@angular/forms';
import constants from '../app.constants';
import { Subscription } from 'rxjs';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';

type TabType = {
    name: string,
    loadFunctionName: string,
    beenLoaded: boolean
};

@Component({
    selector: 'admin',
    templateUrl: './admin.html',
    styleUrls: ['./admin.scss'],
})
export class AdminComponent implements OnInit, OnDestroy {
    @ViewChild(MatTabGroup) tabGroup: MatTabGroup;

    PAGE_SIZE_OPTIONS = [10, 25, 50, 100];
    DEFAULT_PAGE_SIZE = this.PAGE_SIZE_OPTIONS[1];

    queryParamSubscription: Subscription;

    users: Object[];
    usersOffset = 0;
    usersResultsPerPage = 20;

    allOrganizations: Organization[];
    orgFilterForm: FormGroup;
    orgOffset = 0;
    orgLimit = 25;
    orgCurrentPage = 1;
    orgNameSearchString = '';
    orgFilters: {populationServed?: string, organizationFocus?: string, organizationRole?: string};
    populationServedOptions: string[] = constants.populationServedOptions;
    organizationFocusOptions: string[] = constants.organizationFocusOptions;
    organizationRoleOptions: string[] = constants.organizationRoleOptions;
    orgNumTotalResults: number;

    currPageTabIndex = 0;
    currentOrgTab = 3;

    allResources: Resource[];
    resourceFilterForm: FormGroup;
    resourceOffset = 0;
    resourceLimit = 25;
    resourceCurrentPage = 1;
    resourceSearchString = '';
    resourceFilters: ResourceSearchFilters = {};
    resourceCategoryOptions: string[] = shared.resourceIssueAreaOptions;
    resourceTypeOptions = shared.resourceTypeOptions;
    resourceNumTotalResults: number;

    allPageNames: string[] = [];
    specificPageContent: PageContent[] = [];
    allPageContent: Object[];
    tabs: TabType[] = [
        {
            name: 'users',
            loadFunctionName: 'loadUsersTabContent',
            beenLoaded: false
        },
        {
            name: 'organizations',
            loadFunctionName: 'loadOrgTabContent',
            beenLoaded: false
        },
        {
            name: 'resources',
            loadFunctionName: 'loadResourcesTabContent',
            beenLoaded: false
        },
        {
            name: 'events',
            loadFunctionName: 'loadEventsTabContent',
            beenLoaded: false
        },
        {
            name: 'funding',
            loadFunctionName: 'loadFundingTabContent',
            beenLoaded: false
        },
        {
            name: 'job postings',
            loadFunctionName: 'loadJobsTabContent',
            beenLoaded: false
        },
        {
            name: 'page content',
            loadFunctionName: 'loadPageContentTabContent',
            beenLoaded: false
        },
    ];

    //TODO: Get all reports and show reports on admin page, allow admin to edit reports
    static parameters = [BsModalService, ActivatedRoute, Router, UserService, OrganizationService, ResourceService, PageContentService, FormBuilder];

    constructor(public modalService: BsModalService,
                public route: ActivatedRoute,
                public router: Router,
                public userService: UserService,
                public organizationService: OrganizationService,
                public resourceService: ResourceService,
                public pageContentService: PageContentService,
                public formBuilder: FormBuilder) {
        this.modalService = modalService;
        this.route = route;
        this.router = router;
        this.organizationService = organizationService;
        this.resourceService = resourceService;
        this.pageContentService = pageContentService;
        this.userService = userService;
        this.formBuilder = formBuilder;
        // Use the user service to fetch all users
        this.userService.query().subscribe(users => {
            this.users = users;
        });

        this.allPageNames = shared.pages.PageNames;
        // this.pageContentService = pageContentService;
        this.populationServedOptions = constants.populationServedOptions;
        this.organizationFocusOptions = constants.organizationFocusOptions;
        this.organizationRoleOptions = constants.organizationRoleOptions;

        /*
        forms moved from ngOnInit to constructor because if the Organization or Resources
            tab are routed to, their load functions would be called prior to ngOnInit,
            and an error would be thrown
         */
        this.orgFilterForm = this.formBuilder.group({
            orgNameSearchString: '',
            selectedPopulationServed: undefined,
            selectedOrganizationFocus: undefined,
            selectedOrganizationRole: undefined
        });

        this.resourceFilterForm = this.formBuilder.group({
            resourceSearchString: '',
            resourceTypes: undefined,
            yearRange: undefined,
            categories: undefined
        });

        this.queryParamSubscription = this.route.queryParamMap.subscribe(queryParams => {
            if (queryParams.has('tab')) {
                let tabName = this.parseTabName(queryParams.get('tab'));
                let tabIndex = this.tabs.findIndex(t => t.name === tabName);
                this.currPageTabIndex = tabIndex >= 0 ? tabIndex : 0;
                if (!this.tabs[this.currPageTabIndex].beenLoaded) {
                    this.loadTab(this.tabs[this.currPageTabIndex]);
                }
            } else {
                // default to Users tab
                this.router.navigate([], {
                    queryParams: {
                        tab: 'users'
                    }
                });
            }
        });
    }

    ngOnDestroy(): void {
        // prevent memleak
        this.queryParamSubscription.unsubscribe();
    }

    ngOnInit() {

    }

    getOrganizations(query?: {limit?: number, offset?: number, orgNameSearchString?: string,
        filters?: {populationServed?: string, organizationFocus?: string, organizationRole?: string}}) {
        if (query) {
            this.orgOffset = query.offset ? query.offset : 0;
            this.orgLimit = query.limit ? query.limit : this.orgLimit;
            this.orgNameSearchString = query.orgNameSearchString ? query.orgNameSearchString : '';
            this.orgFilters = query.filters ? query.filters : {};
        } else {
            this.orgNameSearchString = '';
            this.orgFilters = {};
            this.orgOffset = 0;
            this.orgLimit = this.DEFAULT_PAGE_SIZE;
        }
        this.orgNameSearchString = this.orgFilterForm.value.orgNameSearchString ? this.orgFilterForm.value.orgNameSearchString : '';
        const searchQuery = this._buildOrgSearchObject();
        this.organizationService.getAllOrganizationsPaginated(searchQuery)
            .subscribe(res => {
                this.orgNumTotalResults = res['total'];
                this.allOrganizations = res['results'];
            });
    }

    getResources(query?: {limit?: number, offset?: number, searchString?: string, filters?: ResourceSearchFilters}) {
        if (query) {
            this.resourceLimit = query.limit ? query.limit : this.resourceLimit;
            this.resourceOffset = query.offset ? query.offset : 0;
            this.resourceSearchString = query.searchString ? query.searchString : '';
            this.resourceFilters = query.filters ? query.filters : {};
        } else {
            this.resourceLimit = this.DEFAULT_PAGE_SIZE;
            this.resourceOffset = 0;
            this.resourceSearchString = '';
            this.resourceFilters = {};
        }
        const searchQuery = this._buildResourceSearchObject();
        this.resourceService.getPaginatedResources(searchQuery)
            .subscribe(res => {
                this.resourceNumTotalResults = res['total'];
                this.allResources = res['results'];
            });
    }

    changeOrgTab(tabNumber) {
        this.currentOrgTab = tabNumber;
    }

    public _buildOrgSearchObject() {
        return <any>{
            searchString: this.orgNameSearchString,
            filters: this.orgFilters,
            limit: this.orgLimit,
            offset: this.orgOffset,
        };
    }
    public _buildResourceSearchObject() {
        return <any>{
            searchString: this.resourceSearchString,
            filters: this.resourceFilters,
            limit: this.resourceLimit,
            offset: this.resourceOffset,
        };
    }

    submitOrgSearch() {
        const {
            orgNameSearchString,
            selectedPopulationServed,
            selectedOrganizationFocus,
            selectedOrganizationRole
        } = this.orgFilterForm.value;
        const query = {
            orgNameSearchString: orgNameSearchString,
            filters: {
                populationServed: selectedPopulationServed,
                organizationFocus: selectedOrganizationFocus,
                organizationRole: selectedOrganizationRole
            }
        };
        this.getOrganizations(query);
    }

    submitResourceSearch() {
        const {
            resourceSearchString,
            resourceTypes,
            yearRange,
            categories
        } = this.resourceFilterForm.value;
        const query = {
            searchString: resourceSearchString,
            filters: {
                resourceType: resourceTypes,
                yearRange: yearRange,
                category: categories,
            }
        };
        this.getResources(query);
    }

    orgPageChange(ev) {
        const { pageIndex, pageSize } = ev;
        const {
            orgNameSearchString,
            selectedPopulationServed,
            selectedOrganizationFocus,
            selectedOrganizationRole
        } = this.orgFilterForm.value;
        const updatedQuery = {
            limit: pageSize,
            offset: pageSize * pageIndex,
            orgNameSearchString: orgNameSearchString,
            filters: {
                populationServed: selectedPopulationServed,
                organizationFocus: selectedOrganizationFocus,
                organizationRole: selectedOrganizationRole
            }
        };
        this.getOrganizations(updatedQuery);
    }

    resourcePageChange(ev) {
        const { pageIndex, pageSize } = ev;
        const {
            resourceSearchString,
            resourceTypes,
            yearRange,
            categories
        } = this.resourceFilterForm.value;
        const updatedQuery = {
            limit: pageSize,
            offset: pageSize * pageIndex,
            searchString: resourceSearchString,
            filters: {
                resourceType: resourceTypes,
                yearRange: yearRange,
                category: categories
            }
        };
        this.getResources(updatedQuery);
    }

    clearOrgFilter(filterName: string): void {
        this.orgFilters[filterName] = '';
    }
    clearResourceFilter(filterName: string): void {
        this.resourceFilters[filterName] = '';
    }

    clearAllResourceFilters(): void {
        this.getResources();
    }

    closeFilter(matSelect): void {
        matSelect.close();
    }

    /**
     * pageViewString: returns a string for displaying the current page result numbers, called in html
     *      contains the result numbers of the current page as well as the total number of results found by the search
     */
    pageViewString(): string {
        let range = `Showing ${this.orgOffset + 1} - `; //first result number in the current page
        //last result number in the current page by incrementing offset by limit
        if (this.orgOffset + this.orgLimit > this.orgNumTotalResults) {
            //if greater than actual number of results, add actual number to the string
            range += this.orgNumTotalResults;
        } else {
            range += this.orgOffset + this.orgLimit; //otherwise, add the incremented number
        }

        range += ` of ${this.orgNumTotalResults} search results`; //also show total number of results returned
        return range;
    }
    /**
     * pageViewString: returns a string for displaying the current page result numbers, called in html
     *      contains the result numbers of the current page as well as the total number of results found by the search
     */
    resourcePageViewString(): string {
        let range = `Showing ${this.resourceOffset + 1} - `; //first result number in the current page
        //last result number in the current page by incrementing offset by limit
        if (this.resourceOffset + this.resourceLimit > this.resourceNumTotalResults) {
            //if greater than actual number of results, add actual number to the string
            range += this.resourceNumTotalResults;
        } else {
            range += this.resourceOffset + this.resourceLimit; //otherwise, add the incremented number
        }

        range += ` of ${this.resourceNumTotalResults} search results`; //also show total number of results returned
        return range;
    }

    /**
     * Deletes user from database
     * Also removes deleted user from Array of users
     * @param {Object} user - user being deleted
     */
    deleteUser(user) {
        this.userService.remove(user).subscribe(deletedUser => {
            this.users.splice(this.users.indexOf(deletedUser), 1);
        });
    }

    /**
     * Edits user from database
     * @param {Object} user - user being edited
     */
    //TODO
    // editUser(user) {
    //
    //     });
    // }
    /**
     * Deletes organization from database
     * Also removes organization from allOrganizations array.
     * @param {Object} organization - organization being deleted
     */
    deleteOrganization(organization) {
        this.organizationService.remove(organization).subscribe(deletedOrganization => {
            this.allOrganizations.splice(this.allOrganizations.indexOf(deletedOrganization), 1);
        });
    }

    deleteResource(resource) {
        this.resourceService.remove(resource).subscribe(deletedResource => {
            this.allResources.splice(this.allResources.indexOf(deletedResource), 1);
        });
    }

    /**
     * Deletes Page Content from database
     * Also removes page content from allPageContent array.
     * @param {Object} pageContent - pageContent being deleted
     */
    deletePageContent(pageContent) {
        this.pageContentService.remove(pageContent).subscribe(deletedContent => {
            this.allPageContent.splice(this.allPageContent.indexOf(deletedContent), 1);
        });
    }

    // initTabs() {
    //     this.tabs = [
    //         {
    //             name: 'users',
    //             loadFunctionName: 'loadUsersTabContent',
    //             beenLoaded: false
    //         },
    //         {
    //             name: 'organizations',
    //             loadFunctionName: 'loadOrgTabContent',
    //             beenLoaded: false
    //         },
    //         {
    //             name: 'resources',
    //             loadFunctionName: 'loadResourcesTabContent',
    //             beenLoaded: false
    //         },
    //         {
    //             name: 'events',
    //             loadFunctionName: 'loadEventsTabContent',
    //             beenLoaded: false
    //         },
    //         {
    //             name: 'funding',
    //             loadFunctionName: 'loadFundingTabContent',
    //             beenLoaded: false
    //         },
    //         {
    //             name: 'job postings',
    //             loadFunctionName: 'loadJobsTabContent',
    //             beenLoaded: false
    //         },
    //         {
    //             name: 'page content',
    //             loadFunctionName: 'loadPageContentTabContent',
    //             beenLoaded: false
    //         },
    //     ];
    //     //Since MatTabChangeEvent does not emit onInit, 'manually' load the default tab's content
    //     // this.loadTab(this.tabs[this.currPageTabIndex]);
    //     // this[this.tabs[this.currPageTabIndex].loadFunctionName](this.tabs[this.currPageTabIndex]);
    // }

    loadTab(tab: TabType) {
        this[tab.loadFunctionName](tab);
    }

    /**
     * handleTabChange:
     *  when the active tab is changed, load the required content as dictated by its loader function
     * @param ev
     * @author Brian Weir
     */
    handleTabChange(ev: MatTabChangeEvent) {
        // console.log(ev);
        this.currPageTabIndex = ev.index;
        // console.log(`currPageTabIndex: ${this.currPageTabIndex}`);
        const selectedTabName = ev.tab.textLabel.toLowerCase();
        const selectedTab = this.tabs.find(t => t.name === selectedTabName);
        const parsedTabName = this.parseTabName(selectedTabName);
        this.router.navigate([], {
            queryParams: {
                tab: parsedTabName
            }});
        if (selectedTab && !selectedTab.beenLoaded) {
            this.loadTab(selectedTab);
        }
    }

    /**
     * parseTabName: can be passed either the tab name from the URL or from the list of tab op
     * @param {string} tabName
     * @author Brian Weir
     */
    parseTabName(tabName: string): string {
        //if it has a space, parse to have hyphen
        let parsedName = tabName.trim();
        let urlDelimiter = '-'; //the delimiter to be used in the URL
        let displayDelimiter = ' '; // the delimiter to be used when displayed in the UI

        // parse to become URL-friendly w underscore
        if (tabName.includes(displayDelimiter)) {
            parsedName = tabName
                .split(displayDelimiter)
                .join(urlDelimiter);
        } else if (tabName.includes(urlDelimiter)) {
            // parse to adhere to desired UI formatting
            parsedName = tabName
                .split(urlDelimiter)
                .join(displayDelimiter);
        }
        // if neither of the above if-statements hit, then the tabName must be one-word (thus, no delimiter)
        return parsedName.toLowerCase();
    }

    /**
     * tab loader functions: used to lazy-load tab content to save us from having to run
     *      all tab queries onInit
     * @param tab
     * @private
     * @author Brian Weir
     */
    private loadUsersTabContent(tab: TabType) {
        this.userService.query().subscribe(u => {
            this.users = u;
            tab.beenLoaded = true;
        });
    }

    private loadOrgTabContent(tab: TabType) {
        // console.log('loadOrgs');
        this.getOrganizations();
        tab.beenLoaded = true;
    }

    private loadResourcesTabContent(tab: TabType) {
        // console.log('loadResources');
        this.getResources();
        tab.beenLoaded = true;
    }

    private loadEventsTabContent(tab: TabType) {
        //   TODO: ... load content
        tab.beenLoaded = true;
    }

    private loadFundingTabContent(tab: TabType) {
        //  TODO:  ... load content
        tab.beenLoaded = true;
    }

    private loadJobsTabContent(tab: TabType) {
        //   TODO: ... load content
        tab.beenLoaded = true;
    }

    private loadPageContentTabContent(tab: TabType) {
        this.pageContentService.getAllPageContent().subscribe((allPageContents: PageContent[]) => {
            this.allPageContent = allPageContents;
            tab.beenLoaded = true;
        });
    }
}
