import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'jobs',
    templateUrl: './jobs.html',
    styleUrls: ['./jobs.scss'],
})
export class JobsComponent implements OnInit {
    constructor() {

    }



    ngOnInit(): void {
    }
}
