import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';


import { RouterModule, Routes } from '@angular/router';

import { TooltipModule } from 'ngx-bootstrap/tooltip';

import { EventsComponent } from './events.component';
import {SideMenuComponent} from '../../components/side-menu/side-menu.component';


export const ROUTES: Routes = [
    {
        path: 'events',
        component: EventsComponent,
        children: [
            {
                path: '',
                outlet: 'sidemenu',
                component: SideMenuComponent
            }
        ]
    },
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
    ],
    declarations: [
        EventsComponent,
    ],

    exports: [
        EventsComponent,
    ],
})
export class EventsModule {}
