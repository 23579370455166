import { Component, OnInit} from '@angular/core';

import {OrganizationClaimService} from '../../../services/org-claim-request/org-claim-request.service';
import {OrgClaimRequest} from '../../../components/interfaces/OrgClaimRequest';
import {Organization} from '../../organizations/organization';
import {OrganizationService} from '../../../services/organization/organization.service';
import {UserService} from '../../../components/auth/user.service';
import {VerifyClaimsComponent} from '../../../components/modals/verify-claims/verify-claims.component';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: 'verify-organization-claims',
    templateUrl: './verify-organization-claims.html',
    styleUrls: ['./verify-organization-claims.scss']
})

export class VerifyOrganizationClaimsComponent implements OnInit {
    allClaims: OrgClaimRequest[] = [];
    dataSource: OrgClaimRequest[] = [];
    displayedColumns = ['date', 'organization', 'claimantName', 'role', 'email', 'decision'];
    recentlyChanged: any[] = [];
    searchClaims: any[] = [];
    searchString: string = '';
    alertMessage: string = 'No Results Found!';
    static parameters = [OrganizationClaimService, OrganizationService, UserService, BsModalService] ;
    constructor(public organizationClaimService: OrganizationClaimService, public organizationService: OrganizationService,
        public userService: UserService, public modalService: BsModalService) {}

    ngOnInit() {
        this.getPendingClaims()
            .catch((err) => console.error(err));
    }

    getPendingClaims() {
        return this.organizationClaimService.getOrgClaimByStatus('pending').toPromise()
            .then((orgClaims: {claims: OrgClaimRequest[]}) => {
                const { claims } = orgClaims;
                // console.log(claims);
                claims.forEach((claim) => {
                    this.organizationService.getOrganizationByUrl(claim.orgId).subscribe((org: Organization) => {
                        claim.organization = org;
                    });

                    this.userService.get({_id: claim.claimant._id}).subscribe((user) => {
                        claim.claimant.name = user.name;
                    });
                });
                return claims;
            })
            .then(pendingClaims => {
                this.dataSource = [...pendingClaims];
                this.allClaims = [...pendingClaims];
                return pendingClaims;
            })
            .catch(err => console.error(err));
    }

    clicked(index, allClaims) {
        //TODO add modal for additional info for each claim
        const initialState = {
            index,
            allClaims
        };
        const modalRef = this.modalService.show(VerifyClaimsComponent, {initialState});
        modalRef.content.recentlyChangedClaims.subscribe((recentClaims) => {
            this.recentlyChanged = recentClaims;
            this.getPendingClaims();
        });
    }

    undo() {
        this.recentlyChanged.forEach((claim) => {
            this.organizationClaimService.undo(claim).subscribe();
        });
        this.recentlyChanged = undefined;
        this.getPendingClaims();
    }

    search() {
        this.searchClaims = [];
        var str = this.searchString;

        this.allClaims.forEach((claim) => {
            if (claim.organization.name.includes(str)) {
                this.searchClaims.push(claim);
            }
        });
    }
}
