<div class="map-container">
    <div class="sidebar">
        <div class="contents sidebar__contents" id="map-search">
            <div class="sidebar__inputs">
                <div class="search-btn__container">
                    <input type="checkbox" class="k-checkbox" id="counties" [(ngModel)]="showCounties">
                    <label class="k-checkbox-label" for="disabled">Show county labels</label>
                </div>
                <div class="search-btn__container">
                    <input type="checkbox" class="k-checkbox" id="disabled" [(ngModel)]="isDisabled">
                    <label class="k-checkbox-label" for="disabled">Search by keywords</label>
                </div>
                <div class="search-btn__container">
                    <input type="text" class="form-control inputs__searchbar" [disabled]="!isDisabled" (keydown.enter)="mapSearch()" placeholder="Enter keywords..." [(ngModel)]="searchString" #search1>
                </div>
                    <div class="search-btn__container">
                        <input type="text" class="form-control inputs__searchbar" [disabled]="isDisabled" (keydown.enter)="$event.preventDefault()" placeholder="Enter a location" [(ngModel)]="addressString" #search2>
                    </div>
                    <div class="search-btn__container">
                        <button (click)="mapSearch()" class="search_icon">
                            <i class="fa fa-search"> </i>&nbsp;SEARCH
                        </button>
                    </div>

                <form [formGroup]="filterForm">
                    <mat-form-field appearance="outline" class="filterField">
                        <mat-label for="populationServedOptions">Community Focus</mat-label>
                        <mat-select (openedChange)="onFilterToggle($event)" multiple="multiple" formControlName="selectedPopulationServed" ngDefaultControl id="selectedPopulationServed" [(ngModel)]="filters.populationServed" #matSelect1 disableOptionCentering>
                            <div class="row no-gutters">
                                <div class="col-sm-4 text-right">
                                    <button mat-raised-button class="clear-button clear1" type="button" (click)="clearFilter('populationServed')">Clear</button>
                                </div>
                                <div class="col-sm-4 text-left">
                                    <button mat-raised-button class="clear-button clear1" type="button" (click)="closeFilter(matSelect1)">Save</button>
                                </div>
                            </div>
                            <mat-option *ngFor="let populationServed of populationServedOptions" [value]="populationServed">
                                {{populationServed}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="filterField">
                        <mat-label for="organizationFocusOptions">Organization Focus</mat-label>
                        <mat-select (openedChange)="onFilterToggle($event)" multiple="multiple" formControlName="selectedOrganizationFocus" ngDefaultControl id="selectedOrganizationFocus" [(ngModel)]="filters.organizationFocus" #matSelect2 disableOptionCentering>
                            <div class="row no-gutters">
                                <div class="col-sm-4 text-right">
                                    <button mat-raised-button class="clear-button clear2" type="button" (click)="clearFilter('organizationFocus')">Clear</button>
                                </div>
                                <div class="col-sm-4 text-left">
                                    <button mat-raised-button class="clear-button clear2" type="button" (click)="closeFilter(matSelect2)">Save</button>
                                </div>
                            </div>
                            <mat-option *ngFor="let organizationFocus of organizationFocusOptions" [value]="organizationFocus">
                                {{organizationFocus}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline" class="filterField">
                        <mat-label for="organzationRoleOptions">Organization Role</mat-label>
                        <mat-select (openedChange)="onFilterToggle($event)" multiple="multiple" formControlName="selectedOrganizationRole" ngDefaultControl id="selectedOrganizationRole" [(ngModel)]="filters.organizationRole" #matSelect3 disableOptionCentering>
                            <div class="row no-gutters">
                                <div class="col-sm-4 text-right">
                                    <button mat-raised-button class="clear-button clear2" type="button" (click)="clearFilter('organizationRole')">Clear</button>
                                </div>
                                <div class="col-sm-4 text-left">
                                    <button mat-raised-button class="clear-button clear2" type="button" (click)="closeFilter(matSelect3)">Save</button>
                                </div>
                            </div>
                            <mat-option *ngFor="let organizationRole of organizationRoleOptions" [value]="organizationRole">
                                {{organizationRole}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </form>
                <mat-checkbox [(ngModel)]="updateResults">Update results when map moves</mat-checkbox>

            </div>

            <!--            The list of Results     -->
            <map-search-items [allOrganizations]="allOrganizations"></map-search-items>


<!--            Pagination at end of results      -->
            <div class="sidebar__pagination" *ngIf="allOrganizations && allOrganizations.length > 0">
                <!-- inspiration for this implementation from: https://mdbootstrap.com/docs/angular/components/pagination/ -->
                <nav class="map-pagination results__pagination">
                    <ul class="map-page-list page-list">
                        <li *ngIf="currentPage != 1 && currentPage != 2" class="map-page-item page-item">
                            <a class="map-page-arrow page-arrow" (click)="pageButton(currentPage - 2)">
                                <span aria-hidden="true">&laquo;&laquo;</span>
                                <span class="sr-only">First</span>
                            </a>
                        </li>
                        <li *ngIf="currentPage != 1" class="map-page-item page-item">
                            <a class="map-page-arrow page-arrow" (click)="pageButton(currentPage - 1)">
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                            </a>
                        </li>
                        <li *ngIf="currentPage != 1" class="map-page-item page-item">
                            <a class="map-page-number page-number" (click)="pageButton(1)">1</a>
                        </li>
                        <li *ngIf="currentPage > 4" class="map-page-item page-item">...</li>
                        <li *ngIf="currentPage - 2 > 1" class="map-page-item page-item">
                            <a class="map-page-number page-number" (click)="pageButton(currentPage - 2)">{{currentPage - 2}}</a>
                        </li>
                        <li *ngIf="currentPage - 1 > 1" class="map-page-item page-item">
                            <a class="map-page-number page-number" (click)="pageButton(currentPage-1)">{{currentPage - 1}}</a>
                        </li>
                        <li class="map-page-item page-item">
                            <div class="map-page-active page-active">{{currentPage}}</div>
                        </li>
                        <li *ngIf="currentPage + 1 < lastPage" class="map-page-item page-item">
                            <a class="map-page-number page-number" (click)="pageButton(currentPage+1)">{{currentPage+1}}</a>
                        </li>
                        <li *ngIf="currentPage + 2 < lastPage" class="map-page-item page-item">
                            <a class="map-page-number page-number" (click)="pageButton(currentPage+2)">{{currentPage+2}}</a>
                        </li>
                        <li *ngIf="lastPage - currentPage > 3" class="map-page-item page-item">...</li>
                        <li *ngIf="currentPage != lastPage" class="map-page-item page-item">
                            <a class="map-page-number page-number" (click)="pageButton(lastPage)">{{lastPage}}</a>
                        </li>
                        <li *ngIf="currentPage != lastPage" class="map-page-item page-item">
                            <a class="map-page-arrow page-arrow" (click)="pageButton(currentPage+1)">
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                            </a>
                        </li>
                        <li *ngIf="currentPage != lastPage && currentPage != lastPage" class="map-page-item page-item">
                            <a class="map-page-arrow page-arrow" (click)="pageButton(currentPage+2)">
                                <span aria-hidden="true">&raquo;&raquo;</span>
                                <span class="sr-only">Last</span>
                            </a>
                        </li>
                    </ul>
                </nav>
                <div class="map-search-results-text search-results-text">{{pageViewString()}}</div>
            </div>
        </div>
    </div>

    <!-- The Actual Map-->
    <div class="map" #viewDiv>
        <div class="floatingButtonContainer map__btn-container" *ngIf="!updateResults">
            <button mat-raised-button type="button" (click)="mapSearch()"><i class="fa fa-search"></i>&nbsp;Search this area</button>
        </div>
        <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" (boundsChange)="onBoundsChanged($event)" (idle)="onIdle()" (centerChange)="onCenterChanged($event)" (zoomChange)="onZoomChanged($event)" id="map">
            <agm-marker *ngFor="let marker of this.allMarkers; let i = index" [latitude]="marker.lat" [longitude]="marker.lng" (markerClick)="clickedMarker(marker.name, i)" [label]="A" [openInfoWindow]="true">
                <agm-info-window>
                    <strong>{{marker.name}}</strong>
                    <p>{{marker.address}} <br> {{marker.city}}, CO</p>
                    <a href="../organizations/{{marker.orgID}}">Details</a>
                </agm-info-window>
            </agm-marker>
            <agm-kml-layer *ngIf="showCounties" [url]="'https://health-equity-colorado-prod.herokuapp.com/api/organizations/counties'" [preserveViewport]="true"> <!-- It is still unverified that this works, should be able to test once sprint-03 (from spring 2021) is pushed to prod --></agm-kml-layer>
        </agm-map>
    </div>
</div>
