<div class="container modal-container">
    <div class="popup-header">
        <img src="/237227a2630cbeb5184fbc882cedb47e.gif" alt="Health Equity Network Logo" width="200px" height="50px">
        <div class="popup-text">
            <h4>Welcome to</h4>
            <h4>Health Equity Community Exchange</h4>
        </div>
    </div>
    <div class="container standard-div">
        <!--Centering a column in bootstrap 3 https://stackoverflow.com/questions/18153234/center-a-column-using-twitter-bootstrap-3 -->

        <!-- Alert element to show feedback to user if necessary -->
        <div id="login-alert-wrapper">
            <div id="login-page-alert" role="alert" [hidden]="!showAlert" class="alert {{alertType}} alert-dismissible fade show">
                {{alertMessage}}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true" (click)="closeAlert()">&times;</span>
                </button>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-lg-12 col-lg-offset-2">
                <form class="needs-validation form" id="signup-form" name="form" (ngSubmit)="register(userForm)" novalidate [formGroup]="userForm">

                    <div id="first-middle-last-signup-wrapper" class="row-of-three">
                        <div class="form-group" [class.has-success]="!userForm.get('firstName').errors" [class.has-error]="userForm.get('firstName').errors">
                            <input type="text" name="first" id="firstName" class="inputName" required formControlName="firstName" placeholder="First name" (change)="printValue('firstName')">
                            <div class="alert alert-danger" *ngIf="userForm.get('firstName').errors && (!userForm.get('firstName').pristine || submitted)">
                                <p class="help-block form-text" *ngIf="userForm.get('firstName').errors.required">
                                    A first name is required
                                </p>
                                <p class="help-block form-text" *ngIf="userForm.get('firstName').errors.pattern">
                                    A first name can only contain letters
                                </p>
                            </div>
                        </div>

                        <div class="form-group" [class.has-success]="!userForm.get('lastName').errors" [class.has-error]="userForm.get('lastName').errors">
                            <input type="text" name="last" class="inputName" required formControlName="lastName" (change)="printValue('lastName')" placeholder="Last name">
                            <div class="alert alert-danger" *ngIf="userForm.get('lastName').errors && (!userForm.get('lastName').pristine || submitted)">
                                <p class="help-block form-text" *ngIf="userForm.get('lastName').errors.required">
                                    A last name is required
                                </p>
                                <p class="help-block form-text" *ngIf="userForm.get('lastName').errors.pattern">
                                    A last name can only contain letters
                                </p>
                            </div>
                        </div>
                    </div>


                    <div class="form-group" [class.has-success]="userForm.get('email').valid" [class.has-error]="!userForm.get('email').valid">
                        <!--                            <label>Email</label>-->
                        <input type="email" name="email" class="input" required placeholder="Email address" formControlName="email" (change)="printValue('email')">
                        <div class="alert alert-danger" *ngIf="userForm.get('email').errors && (!userForm.get('email').pristine || submitted)">
                            <p class="help-block form-text" *ngIf="userForm.get('email').errors.required">
                                An email is required
                            </p>
                            <p class="help-block form-text" *ngIf="userForm.get('email').errors.pattern">
                                Invalid Email
                            </p>
                            <p class="help-block form-text" *ngIf="userForm.get('email').errors.inUse">
                                This email address is already in use.
                            </p>
                        </div>
                    </div>

                    <div class="form-group" [class.has-success]="userForm.get('password').valid" [class.has-error]="!userForm.get('password').valid && (!userForm.get('password').pristine || submitted)">
                        <!--                            <label>Password</label>-->
                        <input type="password" name="password" class="input" minlength="8" formControlName="password" maxlength="128" required placeholder="Password" (change)="printValue('password')">
                        <div class="alert alert-danger" *ngIf="userForm.get('password').errors && (!userForm.get('password').pristine || submitted)">
                            <p class="help-block form-text" *ngIf="userForm.get('password').errors.required">
                                A password is required
                            </p>
                            <p class="help-block form-text" *ngIf="userForm.get('password').errors.minlength || userForm.get('password').errors.maxlength">
                                Password must be between 8 and 128 characters.
                            </p>
                        </div>
                    </div>

                    <div class="form-group" [class.has-success]="!userForm.get('confirmPassword').errors" [class.has-error]="userForm.get('confirmPassword').errors && (!userForm.get('confirmPassword').pristine || submitted)">
                        <!--                            <label>Confirm Password</label>-->
                        <input type="password" name="confirmPassword" class="input" formControlName="confirmPassword" ac-match-control="password" required placeholder="Confirm password" (change)="printValue('confirmPassword')">
                        <div class="alert alert-danger" *ngIf="(userForm.get('confirmPassword').errors && (!userForm.get('confirmPassword').pristine || submitted))">
                            <p class="help-block form-text" *ngIf="userForm.get('confirmPassword').errors.required">
                                A password is required
                            </p>
                            <p class="help-block form-text" *ngIf="userForm.get('confirmPassword').errors.matchPasswordValidator">
                                Please confirm your password. Passwords must match.
                            </p>
                        </div>
<!--                        <p class="help-block form-text"-->
<!--                           [hidden]="userForm.get('confirmPassword').valid || (userForm.get('confirmPassword').pristine && !signupForm.submitted)">-->
<!--                            Please confirm your password. Passwords must match.-->
<!--                        </p>-->
                    </div>

                    <button class="btn-login" type="submit">
                        Register
                    </button>
                </form>
                <div>
                    <p class="Or col-lg-12">OR</p>
                    <div class="row">
                        <div class="col-sm-4 col-md-3">
                            <oauth-buttons class="btn-block"></oauth-buttons>
                        </div>
                    </div>
                    <hr>
                    <p class="not-register">Already a member?<a (click)="logIn()"> Log in</a></p>
                </div>
            </div>
        </div>

    </div>

</div>

