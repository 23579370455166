import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {AuthService} from '../../components/auth/auth.service';
import { EditOrganizationComponent } from './edit-organization.component';
import { DirectivesModule } from '../../components/directives.module';

import {OrgAdminGuard} from '../../components/auth/org-admin-guard.service';
import {MatTabsModule} from '@angular/material/tabs';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatCardModule} from '@angular/material/card';
import {MatRadioModule} from '@angular/material/radio';
import {MatButtonModule} from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatInputModule} from '@angular/material/input';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatNativeDateModule, MatOptionModule} from '@angular/material/core';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';

//TODO: Add route guards that only allow a valid user to manage this organization
export const ROUTES: Routes = [
    {
        path: 'edit-organization',
        component: EditOrganizationComponent,
        canActivate: [OrgAdminGuard]
    },
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
        MatTabsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatCardModule,
        MatRadioModule,
        MatButtonModule,
        MatMenuModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        ReactiveFormsModule,
        NgxMatSelectSearchModule,
        MatOptionModule,
        MatSelectModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatInputModule,
        MatDatepickerModule,
        TimepickerModule.forRoot(),
        MatNativeDateModule,
        DirectivesModule
    ],
    declarations: [
        EditOrganizationComponent,
    ],

    exports: [
        EditOrganizationComponent,
    ],

    providers: [
        AuthService
    ],
})
export class EditOrganizationModule { }
