<div class="container standard-div standard-div-light-gray">
    <div class="create-resource-wrapper">
        <div class="create-resource-form">
            <!-- Alert for user feedback -->
            <div role="alert" [hidden]="!showAlert" class="alert {{alertType}}">{{alertMessage}}</div>


            <h2>{{resourceName}}</h2>

            <form class="md-form" id="new-resource-form" (submit)="handleSubmit()">

                <!-- Resource Title -->
                <div class="new-resource-input">
                    <label for="resource-title">Resource Title<span class="required">*</span></label>
                    <input mdbInput type="text" id="resource-title" name="resourceTitle" class="form-control" [(ngModel)]="resourceName" (keyup)="getInput($event)" required>
                </div>

                <div class="new-resource-input">
                    <label for="org-name">Organization</label>
                    <input [(ngModel)]="searchOrg" (invalid)="validateInput($event)" [typeahead]="allOrgNames" [isAnimated]="true" id="org-name" class="form-control" name="org-typeahead">
                </div>

                <div class="new-resource-input">
                    <label for="resource-url">Resource URL</label>
                    <input mdbInput type="text" id="resource-url" name="resourceURL" class="form-control" [(ngModel)]="resourceURL" (keyup)="getInput($event)">
                </div>

                <!-- Publication Year -->
                <div class="new-resource-input">
                    <label for="publication-year">Publication Year</label>
                    <input mdbInput type="number" id="publication-year" name="year" class="form-control" [(ngModel)]="publicationYear" (keyup)="getInput($event)" placeholder="Publication Year">
                </div>

                <!-- Author -->
                <div class="new-resource-input">
                    <label for="author">Author</label>
                    <input mdbInput type="text" id="author" name="author" class="form-control" [(ngModel)]="author" (keyup)="getInput($event)" placeholder="Author">
                </div>

                <div class="new-resource-input">
                    <label for="resource-type">Resource Type</label>
                    <mat-form-field class="new-resource-select-search" id="resource-type">
                        <mat-select [formControl]="resourceTypeFormControl" [(value)]="resourceType" appearance="none">
                            <mat-option *ngFor="let type of types" [value]="type">
                                {{type}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Keywords -->
                <div class="new-resource-input">

                    <mat-form-field class="example-chip-list" appearance="none">
                        <label for="resource-keywords">Keywords </label>
                        <input mdbInput class="keyword-input" [formControl]="keywordsFormControl" placeholder="Keywords..." id="resource-keywords" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                        <mat-chip-list #chipList>
                            <mat-chip *ngFor="let keyword of Keywords" [selectable]="selectable" [removable]="removable" (removed)="remove(keyword)">
                                {{keyword.keyName}}
                                <mat-icon matChipRemove *ngIf="removable">x</mat-icon>
                            </mat-chip>

                        </mat-chip-list>
                    </mat-form-field>
                </div>


                <!-- Categories -->
                <div class="new-resource-input">
                    <label for="resource-category">Resource Category<span class="required">*</span></label>
                    <mat-form-field class="new-resource-select-search" id="resource-category">
                        <mat-select [(value)]="selected" [formControl]="resourceCategoryFormControl" placeholder="Resource Category" [multiple]="true" #multiSelect>

                            <mat-option *ngFor="let category of categories" [value]="category">
                                {{category}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div *ngIf="selected.includes('Structural Inequities that Impact Health') || !!(this.structuralInequitiesThatImpactHealthFormControl.value && this.structuralInequitiesThatImpactHealthFormControl.value !== '')" class="new-resource-input">
                    <label for="resource-category-SDoH">Structural Inequities that Impact Health Subcategory</label>
                    <mat-form-field class="new-resource-select-search" id="resource-category-SDoH">
                        <mat-select [formControl]="structuralInequitiesThatImpactHealthFormControl" placeholder="Structural Inequities that Impact Health" [multiple]="true" #multiSelect>

                            <mat-option *ngFor="let SDOHcat of structuralInequitiesThatImpactHealthCategories" [value]="SDOHcat">
                                {{SDOHcat}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div *ngIf="selected.includes('Health Equity and Health Outcomes') || !!(this.healthEquityAndHealthOutcomesFormControl.value && this.healthEquityAndHealthOutcomesFormControl.value !== '')" class="new-resource-input">
                    <label for="resource-category-HEHO">Health Equity and Health Outcomes Subcategory</label>
                    <mat-form-field class="new-resource-select-search" id="resource-category-HEHO">
                        <mat-select [formControl]="healthEquityAndHealthOutcomesFormControl" placeholder="Health Equity and Health Outcomes Subcategories" [multiple]="true" #multiSelect>

                            <mat-option *ngFor="let HEHOcat of healthEquityAndHealthOutcomesCategories" [value]="HEHOcat">
                                {{HEHOcat}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


                <div class="new-org-submit">
                    <button type="submit" class="card-button">Submit</button>
                </div>
            </form>
        </div>

    </div>
</div>
