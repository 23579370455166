import { TeamMember } from "./team-member";

// *NOTE - The id field of a TeamMember is the MD5 hash of the student's Gravatar account email.
//              this allows us to make the call to the Gravatar image for each student.
//              You can find the a members information in the "About Page Bio" google sheet in slack
//              and calculate the hash here: https://www.md5hashgenerator.com/

export const LEADER_TEAM: TeamMember[] = [
    {
        id: "",
        name: "Dan Pittman",
        bio: "",
        link: ""
    },
    {
        id: "",
        name: "Lombe Chileshe",
        bio: "                             ",
        link: ""
    }
]
export const DESIGN_TEAM: TeamMember[] = [
    {
        id: "",
        name: "Ryan O'Leary",
        bio: "I am a graduate student at the University of Denver, and am especially interested in web development and design! I enjoy any chance I get to read or learn about new things!",
        link: "https://www.linkedin.com/in/rince-kingson-6a963b211/"
    },
    {
        id: "824475cb9feb3616cc2dd32834ba57a4",
        name: "Tedi Krouchkov",
        bio: "Double major student in a computer science and finance always looking to learn new things!",
        link: "https://www.linkedin.com/in/tedi-krouchkov-a876541b8/"
    },
    {
        id: "694928605aa121a513a13130d39982d8",
        name: "Lexxi Reddington",
        bio: "Lexxi Reddington holds degrees in Physics and Applied Computing from the University of Denver and is currently finishing her master’s degree in Computer Science. She is working on a thesis about virtual reality education and is a Graduate Teaching Assistant too. ",
        link: "http://lexxireddington.com/"
    },
    {
        id: "6fd76bc4fb1ae853e7cb03e4138b0f1c",
        name: "Kyle Pecci",
        bio:
            "Kyle is a Master's student in the 5 year program at the universtiy. With several years of web development experience in React and Angular, he has spent time learning from the development teams at OpenTable as well as several startups.",
        link: "https://www.linkedin.com/in/kyle-pecci/"
    },
    {
        id: "a5b7936d731e74a61582184500395184",
        name: "Haoyu Liu",
        bio: "MET (B.S degree) in Central Washington University and CS (MS degree) in Denver University",
        link: "/about-team"

    },
    {
        id: "834045bc46d537447a82df59fe69e56f",
        name: "Cesar Estrada",
        bio: "Mexico native. Moved to Colorado when he was 13. Favorite coding languages are JavaScript C# and Java. 15+ years of communicating in Spanish, English, and French along with 5+ years communicating in a professional environment with customers and co-workers.",
        link: "https://www.linkedin.com/in/cesar-estrada-202433170/"

    },
    {
        id: "1dd92a617d42aec49712e73e01ad642d",
        name: "Isabelle Ro",
        bio: "A coding/graphics enthusiast!",
        link: "https://www.linkedin.com/in/isabellero/"
    }
];

export const UI_DEV_TEAM: TeamMember[] = [
    {
        id: "",
        name: "Liangyin Yu",
        bio: "",
        link: "/about-team"
    },
    {
        id: "",
        name: "Noelle Decker",
        bio: "Senior at the University of Denver pursuing computer science",
        link: "https://www.linkedin.com/in/noelle-decker-a2a93a1b6/"
    },
    {
        id: "43bcdf9aa76005e05031c431c0690941",
        name: "Stuart Ryan",
        bio: "I'm a Junior at the University of Denver majoring in Computer Science and minoring in Math, Statistics, and Entrepreneurship. I'm planning on continuing my education at DU with a Masters degree in Data Science and in my free time, I enjoy listening to music and spending time with friends",
        link: "https://www.linkedin.com/in/stuart-ryan-586548182/"
    },
    {
        id: "9719d5c618ae59e5f68dcef07d6d27b5",
        name: "Jason Quan",
        bio: "Jason is an undergraduate student studying Computer Science. He graduates in 2022 and has an interest in web and software development.",
        link: "/about-team"
    },
    {
        id: "a4aafe11570e7682169c0d5a249181b2",
        name: "Zoe Stille",
        bio: "Senior at the University of Denver with an interest in web design and game development",
        link: "https://www.linkedin.com/in/zoe-stille/"
    },

    {
        id: "2f433ea095a71a961e0768b77510fdb4",
        name: "Andrew Neel",
        bio: "Andrew is an undergraduate student at the University of Denver double majoring in Computer Science and Emergent Digital Practices with a minor in Math. He will be graduating from DU in June 2022. Aside from Web Development he enjoys swimming and playing table top role playing games. ",
        link: "https://www.linkedin.com/in/andrew-neel-91117417b/"
    },
    {
        id: "8d3d2c0baec980202bc3af298642e803",
        name: "Jing Dong",
        bio: "Senior at the University of Denver with a passion for full stack development.",
        link: "/about-team"
    },
    {
        id: "89f69bca90854edee7aec3aa80d61c74",
        name: "Oscar Ozeta",
        bio:
            "Oscar is a University of Denver student majoring in Computer Science. He is a Mexican-American and he loves to play soccer, video games, and resell shoes.",
        link: "https://www.linkedin.com/in/oscar-ozeta-17481416a/"
    },
    {
        id: "bb00b1c3f8234c0f2b5315b06c8715a3",
        name: "Cameron Griffith",
        bio:
            "Cameron is an undergraduate student from San Diego, California. Graduating class of 2020, majoring in both Computer Science and Studio Art at the University of Denver.",
        link: "https://www.linkedin.com/in/cameron-griffith"
    },
    {
        id: "3cf5900e4bd7e993516a482df2d32d81",
        name: "Brian Weir",
        bio:
            "Originally from Pittsburgh, Brian is in the graduating class of 2022 at the University of Denver. He is a Computer Science major interested in web development and cybersecurity.",
        link: "https://www.linkedin.com/in/brian-weir-b76777171/"
    },
    {
        id: "eabf70c2975ceffb86f1f7dedc9fb5f8",
        name: "Chris Richter",
        bio: "Chris Richter is in the University of Denver's graduating class of 2020, where he received a BS in computer science. He has worked in the medical device industry, working as a software engineer for Carl Zeiss Meditec, as well as providing web maintenance for a law firm in the Denver area.",
        link: "https://www.linkedin.com/in/david-richter-249188142/"
    },
    {
        id: "a9b81af1afd279cdc243ce9a1ed9ef36",
        name: "Neha Agarwal",
        bio: "Neha is a junior at the University of Denver studying Computer Science and Game Development. Alongside programming, she has a strong interest in digital art.",
        link: "https://www.linkedin.com/in/neha-agarwal-01a54b177/"
    }
];

export const SERVER_TEAM: TeamMember[] = [
    {
        id: "cce00a7cfee290a91e72d3937be84f40",
        name: "Tam Nguyen",
        bio: "I think and read in my free time.",
        link: "https://www.linkedin.com/in/tamng0905/"
    },
    {
        id: "1f54eb36ee763e1d130834714ce36fc4",
        name: "Declan Kahn",
        bio: "Declan is an undergraduate in computer science graduating in June 2021 from the University of Denver.",
        link: "https://www.linkedin.com/in/declan-kahn-292936173/"
    },
    {
        id: "4933a3ba867ab24022c7331d19bc82bb",
        name: "Joseph Brooksbank",
        bio: "Senior at the University of Denver and Software Engineer at Navex Global.",
        link: "/about-team"
    },
    {
        id: "d35854bb7d3bf72dcfaf32436ac19568",
        name: "Ethan Mackin",
        bio: "Junior at the University of Denver with a passion for Software Engineering and Cyber-Security.",
        link: "https://www.linkedin.com/in/ethan-mackin-72aab5180/"
    },
    {
        id: "9c7ccd8d90620eb7eaf185bff6c48fbb",
        name: "Chris Lieder",
        bio: "Chris is a Senior at the University of Denver majoring in CS. In his free time he enjoys rock climbing, skiing and playing guitar",
        link: "https://www.linkedin.com/in/chris-lieder/"
    },
    {
        id: "",
        name: "Maimonah Aldubaiyan",
        bio: "Graduate Student at the University of Denver",
        link: "/about-team"
    },
    {
        id: "f053582c5d6feebb6429bb870c98ef12",
        name: "Jacob Mendel",
        bio: "Jacob is an undergraduate student at the University of Denver. He is a computer science major in the graduating class of 2022 and is interested in software engineering and data analytics.",
        link: "https://www.linkedin.com/in/jacob-mendel-409a7b200/"
    },
    {
        id: "53ccd957da243cbbc4081bd06028740f",
        name: "Jeremy Osborne",
        bio: "Senior at the University of Denver passionate about blockchain, startups, and venture capital.",
        link: "https://www.linkedin.com/in/jeremy-o-76a932127/"
    },
    {
        id: "f3872a16e25e9f50e43016d58aae3e0d",
        name: "Sally Jane Stern",
        bio: "Sally Jane Stern is currently a Senior at University of Denver, graduating in June 2020. She will be graduating with a major in Computer Science  and minors in Math and Chemistry.",
        link: "https://www.linkedin.com/in/sally-jane-s-603090112/"
    },
    {
        id: "39bec2f93d6a44faf456282de2b6ded2",
        name: "Sedak Puri",
        bio:
            "Sedak is a current Junior pursuing a dual degree in Finance and Computer Science at the University of Denver. Outside of his passion for software development, he loves to box, ski, and is currently pursuing his public aviation license.",
        link: "https://www.linkedin.com/in/sedak-puri-702b41148/"
    },
    {
        id: "c2813020bd6d2f5590db0c08b18ab981",
        name: "Dana Futakami",
        bio:
            "A reliable and personable computer scientist, Dana F. is currently working as a TA at the University of Denver. She has a strong interest in operating systems, artificial intelligence, web programming, and digital art/animation.",
        link: "https://www.linkedin.com/in/danfutak/"
    },
    {
        id: "1b62ed18498454a9d60f8cd4b3ca455e",
        name: "Charlie Menke",
        bio: "Currently a Senior at University of Denver focusing on leveraging agile frameworks to provide robust products for small and large companies. Working as a developer and project manager in the tech startup space. Additional experience and course work in financial analysis in client and business side environments.",
        link: "http://charliemenke.s3-website.us-east-2.amazonaws.com/"
    },

    {
        id: "76fdcd685e76f151775124304568d3b6",
        name: "Evan O'Rourke",
        bio: "From Philadelphia, Pennsylvania",
        link: "https://www.linkedin.com/in/evan-o-rourke-59662113a/"
    }
];

// {
//     id: "",
//         name: "",
//     bio: "",
//     link: ""
// },
