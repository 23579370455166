import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {CustomEncoder} from '../customEncoder';

export interface ResourceSearchFilters {
    population?: string[];
    keywords?: string[];
    resourceType?: string[];
    yearRange?: [number, number];
    category?: string[];
}

@Injectable({
    providedIn: 'root'
})

//Methods to communicate with the resources API
export class ResourceService {
    public baseUrl = '/api/resources';
    static parameters = [HttpClient];
    constructor(public http: HttpClient) { }

    getAllResources() {
        return this.http.get(this.baseUrl);
    }

    getResourceById(id) {
        return this.http.get(this.baseUrl + '/' + id);
    }

    getPaginatedResources(query: {limit: number, offset: number, searchString: string, filters: ResourceSearchFilters}) {
        return this.http.get(`${this.baseUrl}/paginate`, {
            params: new HttpParams({encoder: new CustomEncoder()})
                .set('limit', JSON.stringify(query.limit))
                .set('offset', JSON.stringify(query.offset))
                .set('searchString', query.searchString)
                .set('filters', JSON.stringify(query.filters))
        });
    }

    //TODO: refactor this to use queryParams instead of manually building the url
    getResourcesByCategory({category, subCategory, pageNum}) {
        if(!category) return null;

        let url = this.baseUrl + '/category/' + encodeURIComponent(category);
        if(subCategory || pageNum) {
            url += '?';
        }
        if(subCategory) {
            url += 'subCat=' + encodeURIComponent(subCategory);
        }
        if(subCategory && pageNum) {
            url += '&';
        }
        if(pageNum) {
            url += 'pageNum=' + encodeURIComponent(pageNum);
        }

        return this.http.get(url);
    }

    /**
     *  performs an advanced search with an advanced search object passed to the API
     *  @deprecated
     */
    searchForResources(query) {
        // return this.http.get(this.baseUrl + '/advanced-search/', {params: query});
        return this.http.get(this.baseUrl + '/search/', {params: query});
    }

    /**
     *  performs a simple text search
     */
    simpleTextSearchForResources(query) {
        return this.http.get(`${this.baseUrl}/simple-search/${query}`).toPromise();
    }

    create(resource) {
        return this.http.post(this.baseUrl, resource);
    }

    remove(resource) {
        return this.http.delete(`/api/resources/${resource.id || resource._id}`)
            .pipe(map(() => resource));
    }
    update(resource) {
        return this.http.put(this.baseUrl + '/' + resource._id, resource, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })

        }).pipe(
            tap(_ =>
                console.log(`updated resource=${resource._id} + ${resource.resourceTitle}`))
        );
    }
}
