import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {OrgClaimRequest} from '../../components/interfaces/OrgClaimRequest';
import { Organization} from '../organizations/organization';
import { AuthService } from '../../components/auth/auth.service';
import { OrganizationClaimService } from '../../services/org-claim-request/org-claim-request.service';
import {LoginPromptComponent} from '../../components/modals/login-prompt.component';
import {OrganizationService} from '../../services/organization/organization.service';
import {BsModalService} from 'ngx-bootstrap/modal';

@Component({
    selector: 'manage-organization',
    templateUrl: './manage-organization.html',
    styleUrls: ['./manage-organization.scss'],
})

export class ManageOrganizationComponent implements OnInit {

/*
    TODO:
    Step 1. make sure logged in user is a verified organization admin.
    From the server side, every user has an array of organizations that they manage.
    If the array is empty, then the user isn't managing any organizations and this page shouldn't even show up.
    If the array's size is greater than 0, then populate a list of the organizations this user is managing.

    It might make sense to have a separate component and html page that handles the population and selection of an Organization,
    so that when a user selects an organization they want to manage, then Step 2 comes into play.That will reduce the amount of
    code required for this particular component.

    Step 2. Using methods from the The different Services e.g OrganizationService, ResourceService, EventsService, JobsService e.t.c,
    create instance variables and initialize those variable by calling those methods. Also initialize any necessary
    components for FormControl to allow a user to edit/create new data

    Step 3: Create various methods to update/edit/delete any of the necessary fields from the database

    Step 4: Make sure those methods are called from the HTML file

*/

    public claimRequests: OrgClaimRequest[];
    static parameters = [ActivatedRoute, OrganizationService, OrganizationClaimService, BsModalService];


    constructor(public route: ActivatedRoute, public organizationService: OrganizationService, public organizationClaimService: OrganizationClaimService, public modalService: BsModalService) {
        this.route = route;
        this.organizationService = organizationService;
        this.organizationClaimService = organizationClaimService;
        this.modalService = modalService;
    }

    ngOnInit(): void {
        this.organizationClaimService.getAllOrgClaims().subscribe((claimRequests: OrgClaimRequest[]) => {
            this.claimRequests = claimRequests;
        });
    }
}

