import { Component} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../../auth/auth.service';
import {Router} from '@angular/router';

interface User {
    email: string;
}

@Component({
    selector: 'forgot-password-modal',
    templateUrl: './forgot-password-modal.html',
    styleUrls: ['./forgot-password-modal.scss'],
})

export class ForgotPasswordModalComponent {
    user: User = {
        email: '',
    };
    errors = { login: undefined };
    submitted = false;
    AuthService;
    BsModalRef;
    ModalService;
    Router;
    return = '/home';
    showAlert = false;
    alertType = '';
    alertMessage = '';


    static parameters = [BsModalRef, AuthService, Router, BsModalService];

    constructor(public bsModalRef: BsModalRef, _AuthService_: AuthService, router: Router, private modalService: BsModalService) {
        this.AuthService = _AuthService_;
        this.Router = router;
        this.BsModalRef = bsModalRef;
        this.ModalService = modalService;
    }
    ngOnInit() {
    }

    forgotPassword(form) {
        this.closeAlert();
        if (form.invalid) {
            this.showAlert = true;
            this.alertType = 'warning';
            this.alertMessage = 'Please complete all fields.';
            return;
        }
        return this.AuthService.forgotPassword(this.user.email)
            .then(() => {
                this.showAlert = true;
                this.alertType = 'alert-success';
                this.alertMessage = 'Check your email for further instructions.';
                let delay = 2000; //5 second
                //add a delay to see the message before redirecting back home
                setTimeout(() => {
                    this.bsModalRef.hide();
                    this.Router.navigateByUrl('/home');
                }, delay);
            })
            .catch(err => {
                this.showAlert = true;
                this.alertType = 'alert-warning';
                this.alertMessage = err.error;
            });
    }

    closeAlert() {
        this.showAlert = false;
        this.alertType = '';
        this.alertMessage = '';
    }
}
