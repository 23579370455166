import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BsModalService} from 'ngx-bootstrap/modal';
import {OrganizationService} from '../../../services/organization/organization.service';
import {ResourceService} from '../../../services/resource/resource.service';
import {AuthService} from '../../../components/auth/auth.service';
import {OrganizationClaimService} from '../../../services/org-claim-request/org-claim-request.service';
import {Organization} from '../organization';
import {CreateOrgClaimRequestComponent} from '../../../components/modals/create-org-claim-request.component';
import {LoginPromptComponent} from '../../../components/modals/login-prompt.component';
import {UserService} from '../../../services/user/user.service';
import {ImageService} from '../../../services/images/image.service';
import { PageContentService} from '../../../services/page-content/page-content.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'organization-details',
    templateUrl: './organization-details.html',
    styleUrls: ['../organizations.scss']
})

export class OrganizationDetailsComponent implements OnInit {
    organization: any;
    organizationUrl;
    organizationResources = [];
    me;
    myOrganization: Organization;
    hasAuth = false;

    defaultAbout;
    defaultContact;

    static parameters = [ActivatedRoute, OrganizationService, ResourceService, AuthService, OrganizationClaimService,
        BsModalService, UserService, Router, PageContentService, DomSanitizer, ImageService];

    constructor(
        public route: ActivatedRoute,
        public organizationService: OrganizationService,
        public resourceService: ResourceService,
        public authService: AuthService,
        public claimService: OrganizationClaimService,
        public modalService: BsModalService,
        public userService: UserService,
        public router: Router,
        public pageContentService: PageContentService,
        public domSanitizer: DomSanitizer,
        public imageService: ImageService
    ) {
        this.modalService = modalService;
        this.authService = authService;
        this.claimService = claimService;
        this.pageContentService = pageContentService;
        this.domSanitizer = domSanitizer;
        this.imageService = imageService;
    }

    //Open the ClaimOrg Modal, and make a new Claim object
    public createOrgClaimReq(organization: Organization) {
        //Only allow a claim to be submitted by users that are signed in
        if (this.authService.isLoggedInSync()) {
            const claimant = this.authService.getCurrentUserSync();
            const initialState = {
                organization,
                claimant
            };
            const modalRef = this.modalService.show(CreateOrgClaimRequestComponent, {initialState});
            modalRef.content.newOrgClaimReq.subscribe(claimRequest => {
                this.claimService.create(claimRequest)
                    .then(createdClaim => {
                        modalRef.content.formInfo = `Claim request for '${organization.name}' submitted!`;
                    })
                    .catch(err => {
                        console.error(err);
                        modalRef.content.formError = err.error.message;
                    });
            });
        } else {
            //If User is not logged in, show prompt
            //TODO: Login Prompt
            // console.log('open Login prompt');
            const initialState = {organization};
            const modalRef = this.modalService.show(LoginPromptComponent, {initialState});
            // alert('Must be logged in');
        }
    }
    public revealOrHide() {
        var reveal = document.getElementsByClassName('reveal')[0] as HTMLElement;
        var hide = document.getElementsByClassName('hide')[0] as HTMLElement;
        var moreText = document.getElementsByClassName('more')[0] as HTMLElement;
        var dots = document.getElementsByClassName('dots')[0] as HTMLElement;

        var reveal2 = document.getElementById('show');
        var hide2 = document.getElementById('hidden');
        var moreText2 = document.getElementById('moreContent');
        var dots2 = document.getElementById('dot');

        if (this.organization.about) {
            if (reveal.style.display === 'none') {
                reveal.style.display = 'inline';
                dots.style.display = 'inline';
                hide.style.display = 'none';
                moreText.style.display = 'none';
            } else {
                reveal.style.display = 'none';
                dots.style.display = 'none';
                moreText.style.display = 'inline';
                hide.style.display = 'inline';
            }
        } else if (reveal2.style.display === 'none') {
            reveal2.style.display = 'inline';
            dots2.style.display = 'inline';
            hide2.style.display = 'none';
            moreText2.style.display = 'none';
        } else {
            reveal2.style.display = 'none';
            dots2.style.display = 'none';
            moreText2.style.display = 'inline';
            hide2.style.display = 'inline';
        }
    }

    ngOnInit() {
        //When a user gets search results and clicks on an organization, we pass along the unique organizationUrl in the route.
        //At this stage, we check the route, and retrieve the url from it's parameter
        if (this.route) {
            this.route.paramMap.subscribe(params => {
                this.organizationUrl = params.get('organizationUrl');
                localStorage.setItem('current_org_id', this.organizationUrl);
            });
        }
        //We then use that URL to get this organizations' details and use that to populate the organizations page
        this.organizationService.getOrganizationByUrl(this.organizationUrl).subscribe((organization: Organization) => {
            this.organization = organization;
            if (this.organization.bannerImage) {
                this.getImageURL(this.organization.bannerImage);
            }
            //Set default "About" message if this org does not have one
            if (!this.organization.about || this.organization.about.length <= 0) {
                this.defaultAbout = `${this.organization.name} has not yet provided a description for their organization.`;
            }
            //Set default "contactMessage" if this org does not have one
            if (!this.organization.contactMessage || this.organization.contactMessage.length <= 0) {
                this.defaultContact = `${this.organization.name} has not yet provided a contact message for their organization.`;
            }

            //We also subsequently get all resources belonging to this organization, and show them on the organization's page as well.
            this.organizationService.getResourcesForOrganization(this.organization._id).subscribe((resources: any) => {
                this.organizationResources = resources.resources;
            });

            // Not worth checking if currentUser is an Org admin if they aren't even logged in
            if (this.authService.isLoggedInSync()) {
                //first get list of organizations this person is an admin for
                this.userService.getOrganizationForOrgAdmin().toPromise()
                    .then((organizations: any) => {
                        if (organizations && organizations.organizations && organizations.organizations.length > 0) {
                            //then check if they are an admin of the current organization
                            if (organizations.organizations.map(org => org._id).includes(this.organizationUrl)) {
                                this.myOrganization = organizations.organizations.find(org => org._id === this.organizationUrl) as Organization;
                                //if they are, you can show the edit button
                                this.hasAuth = true;
                            }
                        }
                        return this.userService.getMe().toPromise();
                    })
                    .then((foundUser: any) => {
                        //we can also show edit button to users who are system administrators
                        if (foundUser && foundUser.role === 'admin') {
                            this.hasAuth = true;
                        }
                    })
                    .catch(err => {
                        console.error(err);
                    });
            } else {
                this.hasAuth = false;
            }
        });
    }

    getImageURL(fileKey) {
        this.imageService.download(fileKey).subscribe({
            next: (blob) => {
                const unsafeImgUrl = URL.createObjectURL(blob);
                const sanitizedImgUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(unsafeImgUrl);
                this.organization.bannerImage = sanitizedImgUrl;
            },
            error: (err) => {
                console.error(err);
            }
        });
    }
}
