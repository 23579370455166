import {Component, ElementRef, Input, NgZone, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MapsAPILoader} from '@agm/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Organization} from '../organizations/organization';
import {OrganizationService} from '../../services/organization/organization.service';
import constants from '../app.constants';
// import { getResourceByOrganizationId } from '../../../server/api/resource/resource.controller';

// declare var google: any;

interface Marker {
    lat: Number;
    lng: Number;
    name?: string;
    address?: string;
    city?: string;
    orgID?: { ObjectId };
}

interface Filter {
    filterCategory: string;
    filterName: string;
}

@Component({
    selector: 'map-search',
    templateUrl: './map-search.html',
    styleUrls: ['./map-search.scss'],
})

export class MapSearchComponent implements OnInit {

    @ViewChild('search1', { static: true })
    public search1ElementRef: ElementRef;
    @ViewChild('search2', { static: true })
    public search2ElementRef: ElementRef;

    public isDisabled = false;
    showCounties: boolean = false;

    lat: number;
    lng: number;
    zoom: number;

    bounds: google.maps.LatLngBounds;
    center: google.maps.LatLngLiteral;

    searchBarBounds: google.maps.LatLngBounds;

    searchString: string;
    addressString: string;

    place: google.maps.places.PlaceResult;
    isAddress: boolean;

    queryUrl;
    mapQuery;

    public noResultsFound: boolean; //TODO: implement html to display when there are no results returned by server
    public isCurrentlySearching: boolean; //will allow us to display a loading image when searching

    allOrganizations: Organization[] = [];
    allMarkers: Marker[] = [];

    filterForm: FormGroup;
    populationServedOptions: string[];
    organizationFocusOptions: string[];
    organizationRoleOptions: string[];

    filters: {populationServed: string, organizationFocus: string, organizationRole: string};

    @Input() inputPopulationServed: string;
    //populationServed: string;

    @Input() inputOrganizationFocus: string;
    //organizationFocus: string;

    @Input() inputOrganizationRole: string;
    //organizationRole: string;

    public filterItems;
    public filterItemsSet;
    public filterCategories;

    queryFilters: Filter[];
    activeFilters: Filter[];
    isFiltered: boolean;

    allFilters: Filter[] = [];

    updateResults: boolean;

    map: google.maps.Map;
    placemarkLayer: google.maps.KmlLayer;
    coloradoBounds: google.maps.LatLngBounds;

    limit: number; //the number of results per page
    offset: number; //offset of results ignored to show the current page requested
    numTotalResults: number; //the actual number of results retrieved by search
    lastOffset: number;
    currentPage: number;
    lastPage: number;

    hasIdled: boolean;

    static baseValues = {
        lat: 39.113014, lng: -105.358887, zoom: 8.0, searchBarBounds: {
            east: -104.75481205709076,
            north: 39.81839092319901,
            south: 39.65998965810583,
            west: -105.22568994290924
        }
    };
    static parameters = [MapsAPILoader, NgZone, OrganizationService, Router, ActivatedRoute, FormBuilder];

    constructor(
        public mapsAPILoader: MapsAPILoader,
        public ngZone: NgZone,
        public organizationService: OrganizationService,
        public router: Router,
        public route: ActivatedRoute,
        public formBuilder: FormBuilder) {}


    public ngOnInit() {
        this.lat = MapSearchComponent.baseValues.lat;
        this.lng = MapSearchComponent.baseValues.lng;
        this.center = { lat: this.lat, lng: this.lng };
        this.zoom = MapSearchComponent.baseValues.zoom;
        // this.initMap();
        this.numTotalResults = 0; //actual number of results returned by search

        this.limit = 20; //number of organizations displayed per page
        this.offset = 0; //number of results before current list of organizations
        //last number offset able to be sent to server for a search. Must be a multiple of the limit and less than numTotalResults.
        this.lastOffset = 0;

        this.currentPage = 1; //current page number
        this.lastPage = 1; //last page number able to be viewed

        this.populationServedOptions = constants.populationServedOptions;
        this.organizationFocusOptions = constants.organizationFocusOptions;
        this.organizationRoleOptions = constants.organizationRoleOptions;

        this.filterForm = this.formBuilder.group({
            selectedPopulationServed: this.populationServedOptions[0],
            selectedOrganizationFocus: this.organizationFocusOptions[0],
            selectedOrganizationRole: this.organizationRoleOptions[0]
        });
        this.filters = {populationServed: '', organizationFocus: '', organizationRole: ''};

        this.updateResults = false;
        this.isAddress = false;

        this.placemarkLayer = null;

        this.hasIdled = false;

        if(this.route) {
            //first get the url/path to determine whether we need to perform simple or advanced search
            this.route.url.subscribe(url => {
                if(url.length > 1) {
                    this.queryUrl = url[1].path;
                }
                if(this.queryUrl === 'search') {
                    this.route.queryParams
                        .subscribe(params => {
                            this.mapQuery = JSON.parse(params.filter);
                            if (this.mapQuery) {
                                this.filters = {...this.mapQuery.filters};
                                this.bounds = {...this.mapQuery.bounds};
                                this.center = {...this.mapQuery.center};
                                this.zoom = this.mapQuery.zoom;
                                this.lat = this.mapQuery.center.lat;
                                this.lng = this.mapQuery.center.lng;
                                this.addressString = this.mapQuery.addressString;
                                this.searchString = this.mapQuery.searchString;
                                this.isAddress = this.mapQuery.isAddress;
                                this.limit = this.mapQuery.limit;
                                this.offset = this.mapQuery.offset;
                                this.updateResults = this.mapQuery.updateResults;
                            }
                        }
                     );
                }
            });
        } else { }

        if(this.mapQuery) {
            this.isCurrentlySearching = true;
            //We then call the method from our OrganizationService that subsequently sends a request to the Organizations API
            this.organizationService.searchForOrganizationByLongLat(this.mapQuery).subscribe(
                (toReturn: {}) => { //numTotalResults: number, filters: [], kml: string, results: Organization[]
                    this.allOrganizations = toReturn['results'];
                    this.allMarkers = this.createMarkers(this.allOrganizations);
                    this.noResultsFound = (this.allOrganizations.length === 0); //if no results are found,
                    this.numTotalResults = toReturn['total'];
                    //highest offset index available for last results
                    this.lastOffset = Math.floor(this.numTotalResults / this.limit) * this.limit;
                    //or, total number of pages needed for all results
                    this.lastPage = Math.ceil(this.numTotalResults / this.limit);
                    //finds the current page being viewed based on offset. offset and limit should be multiples
                    this.currentPage = Math.floor(this.offset / this.limit) + 1;
                    this.isCurrentlySearching = false;
                });
        }

        this.mapsAPILoader.load().then(() => {
            let autocomplete = new google.maps.places.Autocomplete(this.search2ElementRef.nativeElement);
            autocomplete.setComponentRestrictions({country: 'us'});
            this.coloradoBounds = new google.maps.LatLngBounds(
                new google.maps.LatLng({ lat: 37, lng: -109 }),
                new google.maps.LatLng({ lat: 41, lng: -102 })
            );
            autocomplete.setBounds(this.coloradoBounds);
            autocomplete.addListener('place_changed', () => {
                this.ngZone.run(() => {
                    // get the place result
                    this.place = autocomplete.getPlace();

                    // verify result
                    if (this.place.geometry) {
                        if (!this.coloradoBounds.contains(this.place.geometry.location)) {
                            alert('The search location is outside of the bounds of Colorado.');
                            return;
                        }
                        // set latitude, longitude and zoom
                        this.lat = this.place.geometry.location.lat();
                        this.lng = this.place.geometry.location.lng();
                        this.center = { lat: this.lat, lng: this.lng };
                        this.bounds = this.place.geometry.viewport;

                        if (this.placemarkLayer) {
                            this.placemarkLayer.setMap(this.map);
                        }
                    }

                    // search term consisting of the search string and flag if it is a searchable address
                    // tslint:disable-next-line:max-line-length
                    this.addressString = this.place && this.place.formatted_address ? this.place.formatted_address : this.search2ElementRef.nativeElement.value;
                    if (this.place.formatted_address) {
                        this.zoom = 12;
                        this.mapSearch();
                    }
                });
            });
        });
    }

    initMap(): void {
        let center = this.center;
        let zooming = this.zoom;
        this.map = new google.maps.Map(document.getElementById('viewDiv') as HTMLElement, {
            center,
            zoom: zooming
        });
    }

    /**
     * verifyAddress: re-verify address in case it is typed in correct address format,
     *              but is not selected from autocomplete dropdown.
     */
    verifyAddress(): void {
        let map = new google.maps.Map(this.search2ElementRef.nativeElement && this.search2ElementRef.nativeElement);
        let service = new google.maps.places.PlacesService(map);

        let request = {
            fields: ['name', 'geometry', 'formatted_address'],
            query: this.addressString
        };

        service.findPlaceFromQuery(request, (place, status) => {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                if (place[0].name === request.query) {
                    this.isAddress = false;
                    this.zoom = 12;

                    if (place[0].geometry) {
                        if (!this.coloradoBounds.contains(place[0].geometry.location)) {
                            alert('The search location is outside of the bounds of Colorado.');
                            return;
                        }

                        // set latitude, longitude and zoom
                        this.lat = place[0].geometry.location.lat();
                        this.lng = place[0].geometry.location.lng();
                        this.center = { lat: this.lat, lng: this.lng };
                        this.bounds = place[0].geometry.viewport;
                    }
                }
            }
        });
    }

    /**
     * createMarkers: creates the map markers basked on the
     * @param organizations
     * TODO: Make markers for ALL orgs that fit search, not just paginated results
     */
    createMarkers(organizations): Marker[] {
        return organizations.map((org) => {
            let marker: Marker = {
                lat: org.coords.coordinates[1] as Number,
                lng: org.coords.coordinates[0] as Number,
                name: org.name,
                address: org.address,
                city: org.city,
                orgID: org._id
            };
            return marker as Marker;
        });
    }

    clickedMarker(name: string, index: number) {
        // console.log(`clicked the marker: ${name || index}`);
    }

    onBoundsChanged(bounds: google.maps.LatLngBounds): void {
            this.bounds = bounds;
    }
    onIdle(){
        if (this.updateResults === true && this.hasIdled === true) {
            this.mapSearch();
        }
        if(this.hasIdled === false){
            this.hasIdled= true;
        }

    }

    onCenterChanged(center: google.maps.LatLngLiteral): void {
        this.center = center;
    }

    onZoomChanged(zoom: number): void {
        this.zoom = zoom;
    }

    onFilterToggle(isOpened: boolean): void {
        if (!isOpened) {
            this.mapSearch();
        }
    }

    clearFilter(filterName: string): void {
        this.filters[filterName] = '';
    }
    closeFilter(matSelect): void {
        matSelect.close();
    }

    mapSearch(): void {
        let searchObject = this._buildSearchObject();
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                this.router.navigate(['/map-search/search'], { queryParams: { filter: JSON.stringify(searchObject) } }
            ));
    }


    public _buildSearchObject() {
        return <any>{
            searchString: this.searchString,
            addressString: this.addressString,
            isAddress: this.isAddress,
            bounds: this.bounds,
            center: this.center,
            zoom: this.zoom,
            filters: this.filters,
            limit: this.limit,
            offset: this.offset,
            updateResults: this.updateResults
        };
    }

    /**
     * pageButton: takes in a number (based on actual page numbers ranging from 1 to lastPage)
     *          and sets a new offset based on that number (adjusted to be 0 to lastPage-1) multiplied by the limit value
     * @param button
     */
    pageButton(button: number): void {
        this.offset = this.limit * (button - 1); //calculate offset with limit and button number.
        if (this.offset > this.lastOffset) { //if new offset is out of scope, set it to the boundaries
            this.offset = this.lastOffset;
        }
        if (this.offset < 0) { //if new offset is out of scope, set it to the boundaries
            this.offset = 0;
        }

        this.pageURL(); //send a new search with the new offset
    }

    /**
     * pageURL: sends a new search request using the new offset and limit,
     *          but preserves the parameters of the original search
     */
    pageURL(): void {
        if (this.mapQuery) { //if there was no original search, page change should not be possible
            //we want to use the original search when changing pages. This overrides any variables that have been overridden
            let pageObject = <any>{
                addressString: this.mapQuery.addressString,
                searchString: this.mapQuery.searchString,
                isAddress: this.mapQuery.isAddress,
                bounds: this.mapQuery.bounds,
                center: this.mapQuery.center,
                zoom: this.mapQuery.zoom,
                filters: this.mapQuery.filters,
                limit: this.limit, //only limit and offset are to be changed when changing page view
                offset: this.offset
            };

            this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
                    this.router.navigate(['/map-search/search'], { queryParams: { filter: JSON.stringify(pageObject) } }
                ));
        }
    }

    /**
     * pageViewString: returns a string for displaying the current page result numbers, called in html
     *      contains the result numbers of the current page as well as the total number of results found by the search
     */
    pageViewString(): string {
        let range = 'showing ' + (this.offset + 1) + ' - '; //first result number in the current page
        //last result number in the current page by incrementing offset by limit
        if (this.offset + this.limit > this.numTotalResults) { //if greater than actual number of results, add actual number to the string
            range += this.numTotalResults;
        } else {
            range += (this.offset + this.limit); //otherwise, add the incremented number
        }

        range += ' of ' + this.numTotalResults + ' search results'; //also show total number of results returned
        return range;
    }
}

