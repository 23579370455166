import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {DirectivesModule} from '../../components/directives.module';
import {AboutComponent} from './about.component';
import {SideMenuComponent} from '../../components/side-menu/side-menu.component';
import {QuillModule} from "ngx-quill";

export const ROUTES: Routes = [
    {
        path: 'about',
        component: AboutComponent,
        children: [
            {
                path: '',
                outlet: 'sidemenu',
                component: SideMenuComponent
            }
        ]
    },
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
        DirectivesModule,
        QuillModule
    ],
    declarations: [
        AboutComponent,
    ],

    exports: [
        AboutComponent,
    ],
})
export class AboutModule {
}
