import { Pipe, PipeTransform } from '@angular/core';
import { phoneNormalization } from '../util';

@Pipe({
    name: 'phoneNormalization'
})
export class PhoneNormalizationPipe implements PipeTransform {
    transform(value: any): string {
        if (value.length === 0) return value;
        return phoneNormalization(value);
    }
}
