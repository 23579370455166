import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {CustomEncoder} from '../customEncoder';
import { Organization } from '../../app/organizations/organization';

@Injectable({
    providedIn: 'root'
})
//methods to communicate with the organizations API
export class UserService {
    public baseUrl = '/api/users';
    static parameters = [HttpClient];
    constructor(public http: HttpClient) { }

    getAllUsers() {
        return this.http.get(this.baseUrl);
    }

    getMe() {
        const id_token = localStorage.getItem('id_token');
        return this.http.get(`${this.baseUrl}/me`, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', "Authorization": `Bearer ${id_token}` })

        }).pipe(
            tap(_ =>
                console.log('me'))
        );
    }

    getOrganizationForOrgAdmin(id: string = 'me') {
        return this.http.get(`${this.baseUrl}/${id}/organizations`);
    }
}


