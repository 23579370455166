<div class="container">
    <div class="row">
        <h2>Content for {{pageName}} page</h2>
    </div>
    <div class="row" *ngIf="pageContent.length > 0">
        <div class="card-deck">
            <mat-card class="" *ngFor="let content of pageContent">
                <mat-card-header>
                    <mat-card-title>{{content.name}}</mat-card-title>
                    <mat-card-subtitle>{{content.contentType}} in "{{content.section}}" section</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
<!--      TODO: Implement a text-collapse directive to truncate long sections of text
                such as the example found at: https://codepen.io/rakeshnayak/pen/WKqwxz?editors=0010-->
<!--                    <p>-->
<!--&lt;!&ndash;     <p text-collapse text-collapse-max-length="120" text-collapse-text="{{ content.text }}">&ndash;&gt;-->
<!--                        {{content.text}}-->
<!--                    </p>-->
                    <quill-view [content]="content.text" format="html" theme="snow"></quill-view>
                </mat-card-content>
                <mat-card-actions>
                    <button type="button" class="card__button" [title]="'Edit ' + content.name" [routerLink]="['/editcontent', content.page, content._id, 'edit', 'admin']">
                        Edit
                    </button>
                </mat-card-actions>
            </mat-card>
        </div>
    </div>

<!--    If there is no content for this page    -->
    <div class="row" *ngIf="pageContent.length === 0">
        <div class="col-xs-12 empty">
            <p class="text-muted">Hmmmm.... looks like there's nothing here...</p>
        </div>
    </div>
</div>

