export class Resource {
    _id: string;
    organizationName: string;
    organization: string; //organization ObjectId
    description: string;
    resourceTitle: string;
    resourceType: string;
    url: string;
    author: string;
    resourceURL: string;
    population: [string];
    keywords: String[];
    year: number;
    category: [string];
    sdohFilter: String[];
    healthOutcomesFilter: String;
    constructor(values: Object = {}) {
        Object.assign(this, values);
    }
}

