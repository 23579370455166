<div class="container standard-div">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-lg-8 col-lg-offset-2">
      <h2>Sign up</h2>
    </div>
    <div class="col-xs-12 col-sm-12 col-lg-8 col-lg-offset-2">
      <form class="needs-validation form" id="signup-form" name="form" (ngSubmit)="register(signupForm)" novalidate #signupForm="ngForm">
        <div id="title-wrapper" class="title-suffix-wrapper">
          <div class="form-group" [class.has-success]="title.valid && submitted" [class.has-error]="!title.valid && submitted">
            <label for="title-input">Title</label>
            <select name="title" id="title-input" [(ngModel)]=" user.name.title" #title="ngModel" class="form-control">
              <option hidden value="None" selected="selected">Title</option>
              <option *ngFor="let title of titleEnums" [value]="title">{{title}}</option>
            </select>

          </div>
        </div>

        <div id="first-middle-last-signup-wrapper" class="row-of-three">
          <div class="form-group" [class.has-success]="firstName.valid && submitted" [class.has-error]="!firstName.valid && submitted">
            <label for="firstName">First name</label>
            <input type="text" name="first" id="firstName" class="form-control" [(ngModel)]=" user.name.first" required #firstName="ngModel" placeholder="First name">
            <p class="help-block form-text" [hidden]="firstName.valid || (firstName.pristine && !signupForm.submitted)">
              A first name is required
            </p>
          </div>

          <div class="form-group" [class.has-success]="middleName.valid && submitted" [class.has-error]="!middleName.valid && submitted">
            <label>Middle name</label>

            <input type="text" name="middle" class="form-control" [(ngModel)]=" user.name.middle" #middleName="ngModel" placeholder="Middle name">
          </div>

          <div class="form-group" [class.has-success]="lastName.valid && submitted" [class.has-error]="!lastName.valid && submitted">
            <label>Last name</label>

            <input type="text" name="last" class="form-control" [(ngModel)]=" user.name.last" required placeholder="Last name" #lastName="ngModel">
            <p class="help-block form-text" [hidden]="lastName.valid || (lastName.pristine && !signupForm.submitted)">
              A last name is required
            </p>
          </div>
        </div>

        <div id="suffix-wrapper" class="title-suffix-wrapper">

          <div class="form-group" [class.has-success]="suffix.valid && submitted" [class.has-error]="!suffix.valid && submitted">
            <label>Suffix</label>

            <input type="text" name="suffix" class="form-control" [(ngModel)]=" user.name.suffix" #suffix="ngModel" placeholder="Suffix">
          </div>
        </div>


        <div class="form-group" [class.has-success]="email.valid && signupForm.submitted" [class.has-error]="!email.valid && signupForm.submitted">
          <label>Email</label>

          <input type="email" name="email" class="form-control" [(ngModel)]=" user.email" required mongoose-error #email="ngModel" placeholder="Email address">
          <p class="help-block form-text" [hidden]="email.valid || (email.pristine && !signupForm.submitted) || (email.errors ? email.errors.inUse : true)">
            Please enter a valid email address.
          </p>
          <p class="help-block form-text" [hidden]="email.errors ? !email.errors.inUse : true">
            This email address is already in use.
          </p>
        </div>

        <!-- || (password.pristine && !signupForm.submitted) -->
        <div class="form-group" [class.has-success]="password.valid" [class.has-error]="!password.valid && !password.pristine">
          <label>Password</label>

          <input type="password" name="password" class="form-control" [(ngModel)]=" user.password" minlength="8" maxlength="128" required mongoose-error #password="ngModel" placeholder="Password">
          <p class="help-block form-text" [hidden]="password.valid || (password.pristine && !signupForm.submitted)">
            Password must be between 8 and 128 characters.
          </p>
        </div>

        <div class="form-group" [class.has-success]="confirmPassword.valid" [class.has-error]="!confirmPassword.valid && !password.pristine">
          <label>Confirm Password</label>
          <input type="password" name="confirmPassword" class="form-control" [(ngModel)]=" user.confirmPassword" ac-match-control="password" required #confirmPassword="ngModel" placeholder="Confirm password">
          <p class="help-block form-text" [hidden]="confirmPassword.valid || (confirmPassword.pristine && !signupForm.submitted)">
            Please confirm your password. Passwords must match.
          </p>
            <div *ngIf="confirmPassword.errors?.required">
                Confirm Password is required.
            </div>
          <div *ngIf="confirmPassword.errors?.matchPassword()">
                Password and Confirm Password must match.
            </div>
        </div>
          <div *ngIf="confirmPassword.errors?.required">
              Confirm Password is required.
          </div>
          <div *ngIf="confirmPassword.errors?.matchPassword()">
              Password and Confirm Password must match.
          </div>

        <div>
          <button class="btn btn-inverse btn-lg btn-register" type="submit" [disabled]="!signupForm.form.valid">
            Sign up
          </button>
          <a class="btn btn-default btn-lg btn-login" href="/login{{signupQueryParams}}">
            Login
          </a>
        </div>

        <hr>
        <div class="row">
          <div class="col-sm-4 col-md-3">
            <oauth-buttons classes="btn-block"></oauth-buttons>
          </div>
        </div>
        <hr>
      </form>
    </div>
  </div>

</div>
