import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'funding',
    templateUrl: './funding.html',
    styleUrls: ['./funding.scss'],
})
export class FundingComponent implements  OnInit {
    //TODO: query for funding opportunities, and populate page with content

    constructor() {
    }

    ngOnInit(): void {
    }
}
