<div class="modal-content loginPromptModal">
    <div class="modal-header">
        <h5 class="modal-title pull-left">Must be logged-in</h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>
            Please <a routerLink="/login" style="cursor:pointer;" (click)="loginRoute()">log in</a> to submit a
            Claim for <span style="font-weight: 500">{{ organization.name }}</span>
        </p>
    </div>
</div>
