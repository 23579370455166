<div class="container-fluid standard-div standard-div-light-gray">
    <div class="create-org-wrapper">
        <div class="create-org-form">
            <!-- Alert for user feedback -->
            <div role="alert" [hidden]="!showAlert" class="alert {{alertType}}">{{alertMessage}}</div>

            <h4>Create a New Organization</h4>
            <p class="co-restriction-info">
                Currently this website is focused on creating a network for organizations in Colorado.
                If you are trying to add an organization that does not have a Colorado address, please
                <a routerLink="/contact">contact our site administrators</a>
                to discuss a plan to expand our site in the future.
            </p>

            <form class="md-form" id="new-org-form" (submit)="formSubmit($event)">

                <!-- Org Name -->
                <div class="new-org-input">
                    <label for="new-org-name">Organization name <span class="required">*</span></label>
                    <input mdbInput type="text" id="new-org-name" name="name" class="form-control" (invalid)="validateInput($event)" (keyup)="getInput($event)" placeholder="Organization name" required>
                </div>

                <!-- Org Nickname -->
                <div class="new-org-input">
                    <label for="new-org-nickname">Organization Nickname</label>
                    <input mdbInput type="text" id="new-org-nickname" name="nickname" class="form-control" (keyup)="getInput($event)" placeholder="Organization Nickname">
                </div>

                <!-- Org Initials -->
                <div class="new-org-input">
                    <label for="new-org-initials">Organization Initials</label>
                    <input mdbInput type="text" id="new-org-initials" name="initials" class="form-control" (keyup)="getInput($event)" placeholder="Organization Initials">
                </div>

                <!-- Org About -->
                <div class="new-org-input">
                    <label for="new-org-about">About Organization <span class="required">*</span></label>
                    <textarea mdbInput id="new-org-about" name="about" class="form-control" (keyup)="getInput($event)" placeholder="A short description of your organization" rows="6" required></textarea>
                </div>


                <!-- Org Twitter -->
                <div class="new-org-input">
                    <label for="new-org-twitter">Organization Twitter</label>
                    <input mdbInput type="text" id="new-org-twitter" name="twitter" class="form-control" (invalid)="validateInput($event)" placeholder="Twitter" (keyup)="getInput($event)">
                </div>

                <!-- Org Facebook -->
                <div class="new-org-input">
                    <label for="new-org-facebook">Organization Facebook</label>
                    <input mdbInput type="text" id="new-org-facebook" name="facebook" class="form-control" (invalid)="validateInput($event)" placeholder="Facebook" (keyup)="getInput($event)">
                </div>

                <!-- Org LinkedIn -->
                <div class="new-org-input">
                    <label for="new-org-linkedin">Organization Linkedin </label>
                    <input mdbInput type="text" id="new-org-linkedin" name="linkedin" class="form-control" (invalid)="validateInput($event)" placeholder="Linkedin" (keyup)="getInput($event)">
                </div>

                <!-- Org Instagram -->
                <div class="new-org-input">
                    <label for="new-org-instagram">Organization Instagram </label>
                    <input mdbInput type="text" id="new-org-instagram" name="instagram" class="form-control" (invalid)="validateInput($event)" placeholder="Instagram" (keyup)="getInput($event)">
                </div>

                <!-- Org Phone -->
                <div class="new-org-input">
                    <label for="new-org-phone">Organization phone number</label>
                    <input mdbInput type="text" id="new-org-phone" name="telephoneNumber" class="form-control" (keyup)="getInput($event)" placeholder="Organization phone" (value)="requestObject.telephoneNumber">
                </div>

                <!-- Org Address -->
                <div class="new-org-input">
                    <label for="new-org-address">Organization Address<span class="required">*</span> </label>
                    <input mdbInput type="text" id="new-org-address" name="address" class="form-control" required (invalid)="validateInput($event)" (keyup)="getInput($event)" placeholder="Should be a physical address not a P.O. Box">
                </div>

                <!-- City -->
                <div class="new-org-split-input-wrapper">
                    <div class="new-org-half-input">
                        <label for="new-org-city">City <span class="required">*</span></label>
                        <input mdbInput type="text" id="new-org-city" name="city" class="form-control" required (invalid)="validateInput($event)" (keyup)="getInput($event)" placeholder="City">
                    </div>

                    <!-- State -->
                    <div class="new-org-half-input">
                        <label for="new-org-state">State <span class="required">*</span></label>
                        <mat-form-field class="new-org-select-search" id="new-org-state">
                            <mat-select disabled="disabled" value="CO">
                                <mat-option value="CO">Colorado</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="new-org-split-input-wrapper">
                    <!-- County -->
                    <div class="new-org-half-input">
                        <label for="new-org-county">County <span class="required">*</span></label>
                        <mat-form-field class="new-org-select-search" id="new-org-county">
                            <mat-select [formControl]="countyMultiCtrl" placeholder="County" [multiple]="false">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="countyMultiFilterCtrl" placeholderLabel="County">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let county of filteredCountyMulti | async" [value]="county">
                                    {{county}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- Zip -->
                    <div class="new-org-half-input">
                        <label for="new-org-zip">Zip code <span class="required">*</span></label>
                        <input mdbInput type="text" id="new-org-zip" name="zip" class="form-control" required (invalid)="validateInput($event)" (keyup)="getInput($event)" placeholder="Zip code">
                    </div>
                </div>


                <!-- Org Website -->
                <div class="new-org-input">
                    <label for="new-org-website">Organization website</label>
                    <input mdbInput type="text" id="new-org-website" name="website" class="form-control" (keyup)="getInput($event)" placeholder="Website URL">
                </div>

                <!-- Org Role -->
                <div class="new-org-input">
                    <label for="new-org-role">Organization role <span class="required">*</span></label>
                    <mat-form-field class="new-org-select-search" id="new-org-role">
                        <mat-select [formControl]="rolesMultiCtrl" placeholder="Organization role" [multiple]="true" #multiSelect>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="rolesMultiFilterCtrl" placeholderLabel="Organization role">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let role of filteredRolesMulti | async" [value]="role">
                                {{role}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Org Activities -->
                <div class="new-org-input">
                    <label for="new-org-select-search">Activity focus <span class="required">*</span></label>
                    <mat-form-field class="new-org-select-search" id="new-org-activity">
                        <mat-select [formControl]="activityMultiCtrl" placeholder="Activity focus" [multiple]="true" #multiSelect>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="activityMultiFilterCtrl" placeholderLabel="Activity focus">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let act of filteredActivityMulti | async" [value]="act">
                                {{act}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Populations Served -->
                <div class="new-org-input">
                    <label for="new-org-select-search">Populations served <span class="required">*</span></label>
                    <mat-form-field class="new-org-select-search" id="new-org-populations">
                        <mat-select [formControl]="popMultiCtrl" placeholder="Populations served" [multiple]="true" #multiSelect>
                            <mat-option>
                                <ngx-mat-select-search [formControl]="popMultiFilterCtrl" placeholderLabel="Populations served">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let pop of filteredPopsMulti | async" [value]="pop">
                                {{pop}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- Contact Message -->
                <div class="new-org-input">
                    <label for="new-org-contact-message">Contact Message</label>
                    <textarea mdbInput id="new-org-contact-message" name="contactMessage" class="form-control" (keyup)="getInput($event)" placeholder="A warm message about always listening to a communities concern and looking for new people to connect with. Not too long about 3-5 sentences should be good! It could be telling them that phone is better than email for certain things they want to talk about such as volunteering or donations and etc." rows="6"></textarea>
                </div>


                <!-- Org Email -->
                <div class="new-org-input">
                    <label for="new-org-email">Organization contact email <span class="required">*</span></label>
                    <input mdbInput type="email" id="new-org-email" name="email" class="form-control" (invalid)="validateInput($event)" (keyup)="getInput($event)" placeholder="Contact email">
                </div>


                <!-- Work Hours -->

                <div class="new-org-input">
                    <label>Work Days and Hours (Select checkbox if if no work hours that day.) </label>
                    <table class="table table-bordered">
                        <tr>
                            <td></td>
                            <td>Start Time</td>
                            <td>End Time</td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><br>Monday</td>
                            <td><timepicker [(ngModel)]="mondayStartTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noMondayHours"></timepicker></td>
                            <td><timepicker [(ngModel)]="mondayEndTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noMondayHours"></timepicker></td>
                            <td><br><mat-checkbox [(ngModel)]="noMondayHours" [ngModelOptions]="{standalone: true}" color="primary">No Work Hours</mat-checkbox></td>
                        </tr>
                        <tr>
                            <td><br>Tuesday</td>
                            <td><timepicker [(ngModel)]="tuesdayStartTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noTuesdayHours"></timepicker></td>
                            <td><timepicker [(ngModel)]="tuesdayEndTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noTuesdayHours"></timepicker></td>
                            <td><br><mat-checkbox [(ngModel)]="noTuesdayHours" [ngModelOptions]="{standalone: true}" color="primary">No Work Hours</mat-checkbox></td>
                        </tr>
                        <tr>
                            <td><br>Wednesday</td>
                            <td><timepicker [(ngModel)]="wednesdayStartTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noWednesdayHours"></timepicker></td>
                            <td><timepicker [(ngModel)]="wednesdayEndTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noWednesdayHours"></timepicker></td>
                            <td><br><mat-checkbox [(ngModel)]="noWednesdayHours" [ngModelOptions]="{standalone: true}" color="primary">No Work Hours</mat-checkbox></td>
                        </tr>
                        <tr>
                            <td><br>Thursday</td>
                            <td><timepicker [(ngModel)]="thursdayStartTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noThursdayHours"></timepicker></td>
                            <td><timepicker [(ngModel)]="thursdayEndTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noThursdayHours"></timepicker></td>
                            <td><br><mat-checkbox [(ngModel)]="noThursdayHours" [ngModelOptions]="{standalone: true}" color="primary">No Work Hours</mat-checkbox></td>
                        </tr>
                        <tr>
                            <td><br>Friday</td>
                            <td><timepicker [(ngModel)]="fridayStartTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noFridayHours"></timepicker></td>
                            <td><timepicker [(ngModel)]="fridayEndTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noFridayHours"></timepicker></td>
                            <td><br><mat-checkbox [(ngModel)]="noFridayHours" [ngModelOptions]="{standalone: true}" color="primary">No Work Hours</mat-checkbox></td>
                        </tr>
                        <tr>
                            <td><br>Saturday</td>
                            <td><timepicker [(ngModel)]="saturdayStartTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noSaturdayHours"></timepicker></td>
                            <td><timepicker [(ngModel)]="saturdayEndTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noSaturdayHours"></timepicker></td>
                            <td><br><mat-checkbox [(ngModel)]="noSaturdayHours" [ngModelOptions]="{standalone: true}" color="primary">No Work Hours</mat-checkbox></td>
                        </tr>
                        <tr>
                            <td><br>Sunday</td>
                            <td><timepicker [(ngModel)]="sundayStartTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noSundayHours"></timepicker></td>
                            <td><timepicker [(ngModel)]="sundayEndTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noSundayHours"></timepicker></td>
                            <td><br><mat-checkbox [(ngModel)]="noSundayHours" [ngModelOptions]="{standalone: true}" color="primary">No Work Hours</mat-checkbox></td>
                        </tr>
                    </table>

                </div>


                <div class="new-org-input">
                    <label for="new-org-email">Organization Banner</label><br>
                        <button type="button" style="height: 3em;border-radius: 5px; background: #1F4E78; color: white;font-size: 1em;" (click)="processFile(imageInput)">Upload</button>
                        <label class="btn">
                            <input #imageInput type="file" accept="image/*" onchange="document.getElementById('banner-preview').src = window.URL.createObjectURL(this.files[0]); console.log(this.files[0]);">
                        </label>
                      <br>
                    <div style="border: 1px solid grey; background-color: gainsboro; min-width: 300px; min-height: 100px;">
                        <img id="banner-preview" alt="your banner" width="300" height="100">
                    </div>


                    <div *ngIf="selectedBannerFile" class="img-preview-container">

                            <div class="img-preview{{selectedBannerFile.status === 'fail' ? '-error' : ''}}" [ngStyle]="{'background-image': 'url('+ selectedBannerFile.src + ')'}">
                            </div>

                            <div *ngIf="selectedBannerFile.pending" class="img-loading-overlay">
                                <div class="img-spinning-circle"></div>
                            </div>

                            <div *ngIf="selectedBannerFile.status === 'ok'" class="alert alert-success"> Image Uploaded Successfully!</div>
                            <div *ngIf="selectedBannerFile.status === 'fail'" class="alert alert-danger"> Image Upload Failed!</div>
                        </div>
                </div>


                <div class="new-org-submit">
                    <button class="card-button">Submit</button>
                </div>
            </form>



        </div>
    </div>
</div>
