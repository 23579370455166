import { NgModule, ApplicationRef } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';

import { RouterModule, Routes } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

import { AppComponent } from './app.component';
import { MainModule } from './main/main.module';
import { AboutModule } from './about/about.module';
import {TechTeamModule} from './tech-team/tech-team.module';
import { AboutTeamModule } from './about-team/about-team.module';
import { DirectivesModule } from '../components/directives.module';
import { JwtModule } from '@auth0/angular-jwt';
import { AccountModule } from './account/account.module';
import { AdminModule } from './admin/admin.module';
// import { ContactModule } from './contact/contact.module';
import { EventsModule } from './events/events.module';
import { FundingModule } from './funding/funding.module';
import { OrganizationsModule } from './organizations/organizations.module';
import { ResourcesModule } from './resources/resources.module';
import { NewsModule } from './news/news.module';
import { JobsModule } from './jobs/jobs.module';
import {EmailVerifyModule} from './emailverify/emailverify.module';
import { MapSearchModule } from './map-search/map-search.module';
import { ManageOrganizationModule } from './manage-organization/manage-organization.module';
import { CreateOrganizationModule } from './create-organization/create-organization.module';
import { EditOrganizationModule } from './edit-organization/edit-organization.module';
import {ResetPasswordModule} from './reset-password/reset-password.module';

import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {CollapseModule} from 'ngx-bootstrap/collapse';
import {CommonModule} from '@angular/common';
import { QuillModule } from 'ngx-quill';

export function tokenGetter() {
    return localStorage.getItem('id_token');
}

const appRoutes: Routes = [
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    },
    {
        path: '**',
        redirectTo: '/home',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [
        BrowserModule,
        HttpClientModule,
        HttpClientXsrfModule.withOptions({
            headerName: 'x-csrf-token'
        }),
        BrowserAnimationsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter
            }
        }),
        ReactiveFormsModule,
        RouterModule.forRoot(appRoutes, {
            enableTracing: process.env.NODE_ENV === 'development'
        }),
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        TypeaheadModule.forRoot(),
        QuillModule.forRoot(),
        MainModule,
        AboutModule,
        TechTeamModule,
        AboutTeamModule,
        EventsModule,
        // ContactModule,
        FundingModule,
        NewsModule,
        JobsModule,
        EmailVerifyModule,
        OrganizationsModule,
        ResourcesModule,
        DirectivesModule,
        AccountModule,
        AdminModule,
        MapSearchModule,
        ManageOrganizationModule,
        CreateOrganizationModule,
        EditOrganizationModule,
        MatSelectModule,
        MatFormFieldModule,
        NgxMatSelectSearchModule,
        ResetPasswordModule
    ],
    declarations: [AppComponent],
    bootstrap: [AppComponent]
})
export class AppModule {
    static parameters = [ApplicationRef];
    constructor(public appRef: ApplicationRef) {
        this.appRef = appRef;
    }
}
