import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';

import {RouterModule, Routes} from '@angular/router';

import {TooltipModule} from 'ngx-bootstrap/tooltip';

import {TechTeamComponent} from './tech-team.component';
import {SideMenuComponent} from '../../components/side-menu/side-menu.component';

export const ROUTES: Routes = [
    {
        path: 'tech-team',
        component: TechTeamComponent,
        children: [
            {
                path: '',
                outlet: 'sidemenu',
                component: SideMenuComponent
            }
        ]
    },
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
    ],
    declarations: [
        TechTeamComponent,
    ],

    exports: [
        TechTeamComponent,
    ],
})
export class TechTeamModule {
}
