<div class="container standard-div col-12">
    <div class="title">
    <h1>All Pending Organizations</h1>
    <p>The following organizations have been created but are still pending approval by an administrator</p>
    </div>
    <table class="container col-12 org-table" style="text-align:center">
        <tr class="row header-row">
            <th class="col-5 header-content">Organization Name</th>
            <th class="col-3 header-content">Organization Details</th>
            <th class="col-2 header-content">Reject</th>
            <th class="col-2 header-content">Approve</th>
        </tr>
        <tr class="row content-rows" *ngFor="let org of pendingOrgs">
            <td class="col-5 org-name">{{org.name}}</td>
            <td class="col-3">
                <a class="btn view-btn rounded-pill" routerLink="/verify-organizations/{{org._id}}">VIEW</a>
            </td>
            <td class="col-2"><button class="reject-btn" (click)="deny(org)">X</button></td>
            <td class="col-2"><button class="approve-button" (click)="approve(org)">&#10003;</button></td>
        </tr>
    </table>
</div>
