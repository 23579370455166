import {Component, OnInit, ViewChild} from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Router, ActivatedRoute} from '@angular/router';
import {MatChipInputEvent} from '@angular/material/chips';
import {FormControl} from '@angular/forms';
import {ReplaySubject, Subject} from 'rxjs';
import {take, takeUntil} from 'rxjs/operators';
import {MatSelect} from '@angular/material/select';
import { Resource } from '../../resources/resource';
import { Organization } from 'client/app/organizations/organization';
import {ResourceService} from '../../../services/resource/resource.service';
import {phoneNormalization} from '../../../components/util';
import { OrganizationService } from '../../../services/organization/organization.service';
import {resourceTypeOptions, resourceIssueAreaOptions} from '../../../../server/config/environment/shared';

export interface Keyword {
    keyName: string;
}

@Component({
    selector: 'create-resource',
    templateUrl: './create-resource.html',
    styleUrls: ['./create-resource.scss']
})
export class CreateResourceComponent implements OnInit {
    author;
    static parameters = [ResourceService, OrganizationService, Router, ActivatedRoute];

    constructor(
        public resourceService: ResourceService,
        public organizationService: OrganizationService,
        public router: Router,
        public route: ActivatedRoute) {
    }

    public requestObject = new Resource();
    // Variables for alert feedback
    showAlert = false;
    alertType = null; // 'alert-success' or 'alert-danger'
    alertMessage = null;

    // Function to read values from input fields
    getInput(e: any) {
        if (e.target.classList.contains('invalid')) {
            e.target.classList.remove('invalid');
        }
        if (e.target.name === 'telephoneNumber') {
            this.requestObject[e.target.name] = phoneNormalization(e.target.value);
            e.target.value = phoneNormalization(e.target.value);
        }
        this.requestObject[e.target.name] = e.target.value;
    }


    // protected types: string[] = constants.resourceTypeOptions
    types: string[] = resourceTypeOptions;
    categories: string[] = resourceIssueAreaOptions;
    SDoHcats: string[] = ['Transportation', 'Housing/Neighborhood', 'Environment', 'Food Access', 'Education', 'Income', 'Stress, Trauma & Adverse Childhood Experience'];
    HEHOcats: string[] = ['Covid 19', 'Diabetes/Cardiovascular', 'Cancer', 'Environmentally related illness', 'Mental and Behavioral Health'];


    // Function to reset form inputs
    formReset() {
        (<HTMLFormElement>document.getElementById('new-resource-form')).reset();
        this.typeMultiCtrl.reset();
        this.catMultiCtrl.reset();
        this.sdohCtrl.reset();
        this.hehoCtrl.reset();
        this.keyCtrl.reset();
        this.Keywords = [];
        this.selected = [];
    }

    // Function to validate html form inputs
    validateInput(e: any) {
        e.target.classList.add('invalid');
        this.checkSelects();
    }


    // Custom function to check validity of select inputs
    checkSelects(): Boolean {
        let valid = true;
        if (!this.typeMultiCtrl.value) {
            document.getElementById('resource-type').classList.add('invalid');
            valid = false;
        }

        return valid;
    }

    public baseUrl = 'https://maps.googleapis.com/maps/api/geocode/json?address=';

    // Function to create the new resource in the database on submit
    handleSubmit() {
        if (!this.checkSelects()) {
            this.showAlert = true;
            this.alertType = 'alert-danger';
            this.alertMessage = 'Please fill out all required fields';
            window.scrollTo(0, 0);
            return;
        }

        this.requestObject.resourceTitle = this.requestObject.resourceTitle;
        //do not allow users who are not system admins to add resources from external organizations
        if (JSON.parse(localStorage.getItem('user')).role !== 'admin') {
            if (this.allOrgNames.find(o => o === this.searchOrg) === undefined) {
                this.showAlert = true;
                this.alertType = 'alert-danger';
                this.alertMessage = 'Please choose an organization name of an organization in our database';
                return;
            }
        }
        this.requestObject.organizationName = this.searchOrg;
        this.requestObject.organization = this.skinnyOrgs.find(o => o.name === this.searchOrg)?._id;
        this.requestObject.author = this.author;

        //add the type
        this.requestObject.resourceType = this.typeMultiCtrl.value;

        //add the categories to the object
        this.requestObject.category = this.catMultiCtrl.value;
        if (this.requestObject.category.includes('Structural Inequities that Impact Health')) {
            this.requestObject.sdohFilter = this.sdohCtrl.value;
        }
        if (this.requestObject.category.includes('Health Equity and Health Outcomes')) {
            this.requestObject.healthOutcomesFilter = this.hehoCtrl.value;
        }

        if (this.Keywords && this.Keywords.length > 0) {
           this.requestObject.keywords = this.Keywords.map(k => k.keyName);
        }
        console.log(this.requestObject);
        // Call to create resource on the database
        this.resourceService.create(this.requestObject).toPromise().then((org) => {
            this.formReset();
            this.showAlert = true;
            this.alertType = 'alert-success';
            this.alertMessage = `Your request to create ${this.requestObject.resourceTitle} has been submitted.`;
            window.scrollTo(0, 0);
        }).catch((err) => {
            console.error(err);
            this.showAlert = true;
            this.alertType = 'alert-danger';
            this.alertMessage = 'Sorry, there was an error trying to submit your request. Please try again later.';
            window.scrollTo(0, 0);
        });
    }

    public typeMultiCtrl: FormControl = new FormControl();
    public catMultiCtrl: FormControl = new FormControl();
    public sdohCtrl: FormControl = new FormControl();
    public hehoCtrl: FormControl = new FormControl();
    public keyCtrl: FormControl = new FormControl();
    public typeMultiFilterCtrl: FormControl = new FormControl();
    public filteredTypeMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

    public visible = true;
    public removable = true;
    public selectable = true;
    public addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    Keywords: Keyword[] = [];
    selected = [];
    skinnyOrgs: any[];
    allOrgNames: string[];
    searchOrg: string;

    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our Keywords
        if ((value || '').trim()) {
            this.Keywords.push({keyName: value.trim()});
        }

        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(keyword: Keyword): void {
        const index = this.Keywords.indexOf(keyword);

        if (index >= 0) {
            this.Keywords.splice(index, 1);
        }
    }

    @ViewChild('multiSelect', {static: true}) multiSelect: MatSelect;
    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();


    // Function to set initial values of select inputs and add change listeners
    ngOnInit() {
        // call to get info for all orgs
        this.organizationService.getAllOrgNames().toPromise()
            .then((orgs: Organization[]) => {
                this.skinnyOrgs = [...orgs];
                this.allOrgNames = [...orgs].map(o => o.name);
            })
            .catch(err => console.error(err));

        if (this.route) {
            this.route.paramMap.subscribe(params => {
                this.searchOrg = params.get('organizationName');
            });
        }
        window.scrollTo(0, 0);
    }

    // Destroy function for select inputs
    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    // Function to set initial values of select inputes
    ngAfterViewInit() {
        this.setInitialValue();

        let form = document.getElementById('new-resource-form');
        form.removeAttribute('noValidate');
    }


    //  Sets the initial value
    protected setInitialValue() {
        this.filteredTypeMulti
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.multiSelect.compareWith = this.filterCompare;
            });
    }

    filterCompare(a: string, b: string) {
        return a && b && a === b;
    }
}
