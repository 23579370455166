import { Component } from '@angular/core';

@Component({
    selector: 'oauth-buttons',
    templateUrl: './oauth-buttons.html',
    styleUrls: ['./oauth-buttons.scss'],
})
export class OauthButtonsComponent {
    loginOauth(provider) {
        window.location.href = `/auth/${provider}`;
    }
}
