import { Component, EventEmitter, Input, Output } from '@angular/core';
import {TypeaheadDirective, TypeaheadConfig} from 'ngx-bootstrap/typeahead';
import {OrganizationClaimService} from "../../../services/org-claim-request/org-claim-request.service";
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../../auth/auth.service';
import {Router} from '@angular/router';
import constants from "../../../app/app.constants";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {emailValidation} from "../../util";
import {LoginModalComponent} from "../login-modal/login-modal.component";

interface User {
    name: {
        title: string
        first: string
        middle: string
        last: string
    };
    email: string;
    password: string;
    confirmPassword: string;
}
@Component({
    selector: 'signup-modal',
    templateUrl: './signup-modal.html',
    styleUrls: ['./signup-modal.scss'],
   // providers: [{provide: TypeaheadConfig, useFactory: getTypeaheadConfig}]
})

export class SignupModalComponent {
    /*===============================
            [typeahead] Organization Roles */
    user: User = {
        name: {
            title: 'None',
            first: '',
            middle: '',
            last: '',
        },
        email: '',
        password: '',
        confirmPassword: ''
    };
    userForm: FormGroup;
    errors: { field?: any } = {};
    submitted = false;
    AuthService;
    Router;
    return = '/home';
    signupQueryParams = '';

    showAlert = false;
    alertType = '';
    alertMessage = '';

    static parameters = [BsModalRef, AuthService, Router, BsModalService, FormBuilder];

    constructor(public bsModalRef: BsModalRef, _AuthService_: AuthService, router: Router, private modalService: BsModalService, public formBuilder: FormBuilder) {
        this.AuthService = _AuthService_;
        this.Router = router;
        this.formBuilder = formBuilder;
    }
    ngOnInit() {
        if (this.Router.currentUrlTree.queryParams.return) {
            this.signupQueryParams = '?return=' + this.Router.currentUrlTree.queryParams.return;
            this.return = this.Router.currentUrlTree.queryParams.return;
        }

        this.userForm = this.formBuilder.group({
            firstName: ['', [
                Validators.required,
                Validators.pattern('[a-zA-Z]*') //ensure only letters
            ]],
            lastName: ['', [
                Validators.required,
                Validators.pattern('[a-zA-Z]*') //ensure only letters
            ]],
            email: ['', [
                Validators.required,
                Validators.pattern(/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/) //ensure valid email pattern
            ]],
            password: ['', [
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(128)
            ]],
            confirmPassword: ['', [
                Validators.required
            ]],
        }, {validator: this.matchPasswords()});
    }

    titleEnums = constants.nameTitles;

    matchPasswords() {
        return (formGroup: FormGroup) => {
            let control = formGroup.controls.password;
            let matchingControl = formGroup.controls.confirmPassword;
            if(matchingControl.errors && !matchingControl.errors.matchPassword) {
                return;
            }
            if(control.value !== matchingControl.value) {
                matchingControl.setErrors({matchPasswordValidator: true});
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    printValue(formControlName: string) {
        console.log(this.userForm);
        console.log(this.userForm.get(formControlName));
    }

    register(form) {
        if (form.invalid) {
            console.error('Invalid Form');
            return;
        }
        if(form.value.password !== form.value.confirmPassword) {
            console.error('Password Mismatch');
            return;
        }

        this.submitted = true;
        return this.AuthService.createUser({
            name: {
                first: form.value.firstName,
                last: form.value.lastName
            },
            email: form.value.email.toLowerCase(),
            password: form.value.password
        })
            .then(() => {
                // Account created, redirect to home test
                this.Router.navigateByUrl(this.return);
                this.bsModalRef.hide();
            })
            .catch((err: any) => {
                this.errors = err.error.errors;
                // Update validity of form fields that match the mongoose errors
                Object.entries(err.error.errors).forEach(([field, error]: [string, any]) => {
                    this.errors[field] = error.message;

                    if (field === 'email' && error.kind === 'user defined') {
                        form.controls[field].setErrors({ inUse: true });
                    }
                });

                this.submitted = false;
            });
    }
    closeAlert() {
        this.showAlert = false;
        this.alertType = '';
        this.alertMessage = '';
    }
    logIn(){
        this.bsModalRef.hide();
        this.bsModalRef = this.modalService.show(LoginModalComponent);
    }
}
