
<div class="container-fluid standard-div standard-div-light-gray">
    <!--TODO: if user is an admin for multiple organizations, create functionality to allow user to choose which organization they want to manage -->
    <div class="row">
        <p>Note: This page will only be accessible to users who are logged in and who are authorized to manage a particular organization</p>
        <mat-tab-group class="col-lg-12">
            <!--TODO: Create Mat Form to allow organizationAdmin to edit organization details -->
            <mat-tab label="Organization Details">
                <div class="row">
                    <div class="col-12 col-lg-10 offset-lg-1 table-responsive">
                        <table class="table">
                            <thead>
                            <tr>
                                <th class="">Date</th>
                                <th class="">Organization</th>
                                <th class="">Claimant Name</th>
                                <th class="">Role</th>
                                <th class="">Email</th>
                                <th class="">Decision</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr *ngFor="let claimRequest of claimRequests">
                                <td class="">{{claimRequest.requestDate}}</td>
                                <td class="">{{claimRequest.orgId}}</td>
                                <td class="">{{claimRequest.claimant.userId}}</td>
                                <td class="">{{claimRequest.claimant.orgRole}}</td>
                                <td class="">{{claimRequest.claimant.orgEmail}}</td>
                                <td class="">{{claimRequest.status}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </mat-tab>

            <!--TODO: Create Interface to allow organizationAdmin to add new resources, delete existing resources or edit existing resources -->
            <mat-tab label="Resources">
                <div>
                </div>
            </mat-tab>

            <!--TODO: Create Interface to allow organizationAdmin to post new Events. delete or edit existing events -->
            <mat-tab label="Events">
                <div>

                </div>
            </mat-tab>

            <!--TODO: Create Interface to allow organizationAdmin to post new Job offers. delete or edit existing job offers -->
            <mat-tab label="Jobs">
                <div>

                </div>
            </mat-tab>

            <!--TODO: Create Interface to allow organizationAdmin to post new funding opportunities. delete or edit existing funding opportunities -->
            <mat-tab label="Funding Opportunites">
                <div>

                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>

