import { Component, OnDestroy, OnInit} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import {ActivatedRoute, Router} from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {ResetPasswordModalComponent} from '../../components/modals/reset-password-modal/reset-password-modal.component';

//partially based on this https://medium.com/@zainzafar/routable-modals-in-angular-64fb213199c7

@Component({
    templateUrl: './reset-password.html',
    selector: 'reset',
    styleUrls: ['./reset-password.scss'],
})

export class ResetPasswordComponent implements OnInit, OnDestroy {
    AuthService;
    myRouter;
    myRoute;
    myBsModalRef;
    myModalService;
    destroy = new Subject<any>();

    static parameters = [Router, ActivatedRoute, BsModalRef, BsModalService];

    constructor(router: Router, route: ActivatedRoute, bsModalRef: BsModalRef, private modalService: BsModalService) {
        this.myRouter = router;
        this.myRoute = route;
        this.myBsModalRef = bsModalRef;
        this.myModalService = modalService;
        this.myRoute.params.pipe(takeUntil(this.destroy)).subscribe(params => {
            const initialState = {
                token: params.token,
                email: params.email
            };
            // Upon navigation to this component, take the params from the route, and pass them to modal then open the modal
            this.myBsModalRef.hide();
            this.myBsModalRef = this.myModalService.show(ResetPasswordModalComponent, {
                initialState,
                ignoreBackdropClick: true, //don't allow users to click out of modal because the contents of this page are unimportant
                keyboard: false //or press the keyboard to escape
            });
        });
    }
    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.destroy.next();
    }
}

