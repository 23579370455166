<div class="container standard-div">
    <!--Centering a column in bootstrap 3 https://stackoverflow.com/questions/18153234/center-a-column-using-twitter-bootstrap-3 -->

    <!-- Alert element to show feedback to user if necessary -->
    <div id="login-alert-wrapper">
        <div id="login-page-alert" role="alert" [hidden]="!showAlert" class="alert {{alertType}} alert-dismissible fade show">
            {{alertMessage}}
            <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true" (click)="closeAlert()">&times;</span>
            </button>
        </div>
    </div>

    <div class="row">
        <div class="col-xs-12 col-sm-12 col-lg-8 col-lg-offset-2">
            <h2 class="login__header">Login</h2>
            <!--<p>Accounts are reset on server restart from <code>server/config/seed.js</code>. Default account is <code>test@example.com</code> / <code>test</code></p>-->
            <!--<p>Admin account is <code>admin@example.com</code> / <code>admin</code></p>-->
        </div>

        <div class="col-sm-12 col-lg-8 col-lg-offset-2">
            <form class="needs-validation form" name="loginForm" (ngSubmit)="login(loginForm)" novalidate #loginForm="ngForm">

                <div class="form-group">
                    <label for="email">Email</label>
                    <input class="form-control" type="email" name="email" id="email" [(ngModel)]="user.email" #email="ngModel" required>
                </div>

                <div class="form-group">
                    <label for="pwd-input">Password</label>
                    <input type="password" name="password" id="pwd-input" class="form-control" [(ngModel)]="user.password" #password="ngModel" autocomplete="current-password" required>
                </div>

                <div class="form-group has-error invalid-feedback">
                    <p class="form-text text-muted help-block" [hidden]="(email.value && password.value) || !loginForm.submitted">
                        Please enter your email and password.
                    </p>
                    <p class="form-text text-muted help-block" [hidden]="email.valid || (email.pristine && !loginForm.submitted)">
                        Please enter a valid email.
                    </p>

                    <p class="form-text text-muted help-block">{{ errors.login }}</p>
                </div>

                <div>
                    <button class="btn btn-inverse btn-lg btn-login" type="submit" [disabled]="!loginForm.form.valid">
                        Login
                    </button>
                    <a class="btn btn-default btn-lg btn-register" type="register" href="/signup{{signupQueryParams}}">
                        Register
                    </a>
                </div>

                <hr>
                <div class="row">
                    <div class="col-sm-4 col-md-3">
                        <oauth-buttons classes="btn-block"></oauth-buttons>
                    </div>

                </div>
                <hr>
            </form>
        </div>
    </div>

</div>
