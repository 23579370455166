import {Component, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import { events } from '../events/events';
import { CarouselConfig } from 'ngx-bootstrap/carousel';
import { PageContentService} from '../../services/page-content/page-content.service';
import { trigger, transition, style, animate } from '@angular/animations';
import {PageContent, Card, isCard} from '../../components/interfaces/PageContent';
import {default_cards} from './default_data/homepage-cards';
import {Subject} from 'rxjs';

@Component({
    selector: 'main',
    templateUrl: './main.html',
    providers: [
        { provide: CarouselConfig, useValue: { interval: 15000, noPause: false, showIndicators: true } }
    ],
    styleUrls: ['./main.scss'],
    animations: [
        trigger('carouselAnimation', [
            transition('void => *', [
                style({ opacity: 0 }),
                animate('300ms', style({ opacity: 1 }))
            ]),
            transition('* => void', [
                animate('300ms', style({ opacity: 0 }))
            ])
        ])
    ]
})
export class MainComponent implements OnInit, OnDestroy {
    //TODO: Get use this class variable to store events from database, filter by 4 upcoming events and show these events on the homepage
    //Right now, events being manually populated from JSON array in the events module.
    destroyed = new Subject<void>();

    public allEvents = events;
    public allEventsLength = this.allEvents.length;
    Router;
    showAlert = false;
    alertType = '';
    alertMessage = '';
    cardActiveSlide: 0;
    allPageContent: PageContent[];
    cardContent: PageContent[] = [];
    closeAlert() {
        this.showAlert = false;
        this.alertType = '';
        this.alertMessage = '';
    }

    //Array to show calls to action
    callsToAction = [
        {image: 'assets/images/compLogos/denver_publicHealth&environment.png',
            title: 'Life4All', desc: 'Fundraiser for people that were heavily impacted by COVID-19'},
        {image: 'assets/images/compLogos/denver_publicHealth&environment.png',
            title: 'Health Equity', desc: 'Fundraiser for Health Equity'}
    ];
    jobs = [
        {image: 'assets/images/compLogos/denver_publicHealth&environment.png',
            title: 'Event Planner', desc: 'Help plan events for the organization'},
        {image: 'assets/images/compLogos/denver_publicHealth&environment.png',
            title: 'Volunteer manager', desc: 'Delegate work to volunteers during events'}
    ];

    // "What's New" cards
    cards: Card[] = [...default_cards];

    cardCarouselConfig = {
        itemsPerSlide: 3,
        singleSlideOffset: true,
        noWrap: false,
        interval: false,
        startFromIndex: 0,
    };

    breakpoints = {
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200
    };

    responsiveOptions;

    static parameters = [Router, PageContentService, DomSanitizer];

    constructor(public router: Router, public pageContentService: PageContentService, public domSanitizer: DomSanitizer) {
        this.Router = router;
        this.pageContentService = pageContentService;
        this.domSanitizer = domSanitizer;

        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 3,
                numScroll: 3
            },
            {
                breakpoint: '992px',
                numVisible: 2,
                numScroll: 2
            },
            {
                breakpoint: '768px',
                numVisible: 1,
                numScroll: 1
            },
            {
                breakpoint: '560px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }

    goToSearchMap(): void {
        this.Router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.Router.navigate(['/map-search']));
    }
    goToResourceSearch(): void {
        this.Router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
            this.Router.navigate(['/resources']));
    }

    public ngOnInit(): void {
        this.cardActiveSlide = 0;
        if (this.Router.currentUrlTree.queryParams.error) {
            if (this.Router.currentUrlTree.queryParams.error === 'unauthorized') {
                this.showAlert = true;
                this.alertType = 'alert-danger';
                this.alertMessage = 'Your account is not authorized to access the desired page.';
            }
        }
        this.getPageContent();
    }

    /**
     * getPageContent: Retrieve all of the PageContent for the Home Page
     */
    getPageContent() {
        this.pageContentService.getContentByPage('Home').subscribe((pageContent: any) => {
            this.allPageContent = pageContent as PageContent[];
            this.getCardContent();
        });
    }

    /**
     * getCardContent: once all of the PageContent for the page is retrieved, filter the Cards
     */
    getCardContent() {
        if (this.allPageContent) {
            // get all cards, and sort by name -> "Homepage Card 1", "Homepage Card 2", etc...
            this.cardContent = this.allPageContent
                .filter(content => isCard(content))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map(c => c as Card) as Card[];

            //TODO: Updating the cards[] is temporary until we can fully-integrate images dynamically
            this.cards = this.cards.map(c => {
                const foundCard = this.cardContent.find(card => c.name === card.name) as Card;
                return {...c, ...foundCard};
            })
                .sort((a: Card, b: Card) => {
                    // attempt to sort by relative order
                    if (!!a.relativeOrder && !!b.relativeOrder) {
                        return a.relativeOrder - b.relativeOrder;
                    } else if (a.relativeOrder) return 1;
                    else if (b.relativeOrder) return -1;
                    // if neither have a set order, sort by name
                    return a.name.localeCompare(b.name);
                });
        }
    }

    //TODO: img upload / retrieval
    getImageURL(fileKey) {
        this.pageContentService.download(fileKey).subscribe({
            next: (blob) => {
                const unsafeImgUrl = URL.createObjectURL(blob);
                const sanitizedImgUrl = this.domSanitizer.sanitize(0, this.domSanitizer.bypassSecurityTrustResourceUrl(unsafeImgUrl));
                const thisCard = this.cards.find(card => card.image === fileKey);
                thisCard.imgUrl = sanitizedImgUrl;
            },
            error: (err) => {
                console.error(err);
            }
        });
    }

    public ngOnDestroy(): void { }
}

