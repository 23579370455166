import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../../auth/auth.service';
import {Router} from '@angular/router';
import {SignupModalComponent} from '../signup-modal/signup-modal.component';
import {ForgotPasswordModalComponent} from '../forgot-password-modal/forgot-password-modal.component';

interface User {
    name: string;
    email: string;
    password: string;
}

@Component({
    selector: 'login-modal',
    templateUrl: './login-modal.html',
    styleUrls: ['./login-modal.scss'],
    // providers: [{provide: TypeaheadConfig, useFactory: getTypeaheadConfig}]
})

export class LoginModalComponent {
    /*===============================
            [typeahead] Organization Roles */
    user: User = {
        name: '',
        email: '',
        password: '',
    };
    errors = { login: undefined };
    submitted = false;
    AuthService;
    Router;
    return = '/home';
    signupQueryParams = '';

    showAlert = false;
    alertType = '';
    alertMessage = '';


    static parameters = [BsModalRef, AuthService, Router, BsModalService];

    constructor(public bsModalRef: BsModalRef, _AuthService_: AuthService, router: Router, private modalService: BsModalService) {
        this.AuthService = _AuthService_;
        this.Router = router;
    }
    ngOnInit() {
    // Get the query params for redirect url
        if (this.Router.currentUrlTree.queryParams.return) {
            this.signupQueryParams = `?return=${this.Router.currentUrlTree.queryParams.return}`;
            this.return = this.Router.currentUrlTree.queryParams.return;
        }
        if (this.Router.currentUrlTree.queryParams.alert === 'auth-required') {
            this.showAlert = true;
            this.alertMessage = 'You must be logged in to visit this page.';
            this.alertType = 'alert-info';
        }
        if (this.Router.currentUrlTree.queryParams.alert === 'verifySuccess') {
            this.showAlert = true;
            this.alertMessage = 'Your email is successfully verified.';
            this.alertType = 'alert-success';
        }
        window.scrollTo(0, 0);
    }

    login(form) {
        this.closeAlert();
        if (form.invalid) {
            // console.log(form);
            this.showAlert = true;
            this.alertType = 'warning';
            this.alertMessage = 'Please complete all fields.';
            return;
        }
        console.log(form);

        return this.AuthService.login({
            email: this.user.email.toLowerCase(),
            password: this.user.password
        })
            .then(() => {
                // Logged in, redirect to query param "return" or /home
                // this.Router.navigateByUrl(this.return);
                this.Router.navigateByUrl('/home');
                this.bsModalRef.hide();
            })
            .catch(err => {
                this.errors.login = err.message;
                console.error(err);
                console.log(this.errors.login);
                this.showAlert = true;
                this.alertType = 'alert-warning';
                this.alertMessage = 'An error occurred trying to validate your credentials';
            });
    }

    closeAlert() {
        this.showAlert = false;
        this.alertType = '';
        this.alertMessage = '';
    }
    signUp() {
        this.bsModalRef.hide();
        this.bsModalRef = this.modalService.show(SignupModalComponent);
    }

    forgotPassword() {
        this.bsModalRef.hide();
        this.bsModalRef = this.modalService.show(ForgotPasswordModalComponent);
    }
}
