export interface PageContent {
    _id?: string;
    name?: string;
    contentType?: string;
    page?: string;
    section?: string;
    text?: string;
    links?: string[];
    image?: string;
    imgUrl?: string;
}

//TODO: make text required
export interface Card extends PageContent {
    btn_link?: string;
    btn_text?: string;
    text?: string;
    image?: string;
    imgUrl?: string;
    relativeOrder?: number;
}

export interface Text extends PageContent {
    text: string;
}

export interface Button extends PageContent {
    //incase we ever want to make a button perform some function
    // btn_action?: any;
    btn_link: string;
    btn_text: string;
}

export interface StandaloneImage extends PageContent {
    image?: string;
    imgUrl?: string;
}

//functions to test PageContent instances
export function isCard(object: PageContent): object is Card {
    return object.contentType.toLowerCase() === 'card';
}

export function isText(object: PageContent): object is Text {
    return object.contentType.toLowerCase() === 'text';
}

export function isButton(object: PageContent): object is Button {
    return object.contentType.toLowerCase() === 'button';
}

export function isStandaloneImage(object: PageContent): object is StandaloneImage {
    return object.contentType.toLowerCase() === 'standaloneimage';
}
