import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {EmailVerifyComponent} from "./emailverify.component";
import {AlertModule} from 'ngx-bootstrap/alert';

export const ROUTES: Routes = [
    {
        path: 'verify/:email/:token',
        component: EmailVerifyComponent,
    }];

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AlertModule.forRoot(),
        RouterModule.forChild(ROUTES),
    ],
    declarations: [
        EmailVerifyComponent,
    ],
    exports: [
        EmailVerifyComponent,
    ],
})
export class EmailVerifyModule {
}
