import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Organization} from '../../organizations/organization';
import {OrganizationService} from '../../../services/organization/organization.service';


@Component({
    selector: 'verify-organizations',
    templateUrl: './verify-organizations.html',
    styleUrls: ['./verify-organizations.scss']
})

export class VerifyOrganizationsComponent implements OnInit {
    pendingOrgs: Organization[] = [];
    static parameters = [ActivatedRoute, OrganizationService];
    // eslint-disable-next-line no-unused-vars
    constructor(public route: ActivatedRoute, public organizationService: OrganizationService) {}

    ngOnInit() {
        this.getPendingOrgs();
    }

    getPendingOrgs() {
        this.organizationService.getOrganizationsByStatus('Pending')
            .subscribe((orgs: Organization[]) => {
                this.pendingOrgs = orgs;
            });
    }

    deny(organization: Organization) {
        this.organizationService.reject(organization).subscribe(() => {
            this.getPendingOrgs();
        });
    }
    approve(organization: Organization) {
        this.organizationService.approve(organization).subscribe(() => {
            this.getPendingOrgs();
        });
    }
}
