import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { AuthService } from '../auth/auth.service';
import { OrganizationClaimService } from '../../services/org-claim-request/org-claim-request.service';
import { CreateOrgClaimRequestComponent } from './create-org-claim-request.component';

@NgModule({
    imports: [
        ModalModule.forRoot(),
        BrowserModule,
        FormsModule,

        //Imports for typeahead
        BrowserAnimationsModule,
        TypeaheadModule.forRoot(),
    ],
    declarations: [
        CreateOrgClaimRequestComponent
    ],

    exports: [
        CreateOrgClaimRequestComponent
    ],

    providers: [
        OrganizationClaimService,
        AuthService
    ],

    entryComponents: [
        CreateOrgClaimRequestComponent
    ]
})
export class CreateOrgClaimRequestModule {}
