<div class="container-fluid standard-div standard-div-light-gray">
    <div class="create-org-wrapper">
        <div class="create-org-form">
            <div *ngIf="organization">
<!--                <h4 *ngIf="organization.name">Updating - {{organization.name}}</h4>-->
                <form class="md-form" id="new-org-form" [formGroup]="updateOrgForm" (submit)="formSubmit($event)">
<!--                <form class="md-form" id="new-org-form" [formGroup]="updateOrgForm">-->


                <!-- Org Name -->
                    <div class="new-org-input">
                        <label for="new-org-name">Organization name <span class="required">*</span></label>
                        <input mdbInput type="text" id="new-org-name" name="name" class="form-control" placeholder="Organization name" [defaultValue]="organization.name" required formControlName="name">
                    </div>

                    <!-- Org Nickname -->
                    <div class="new-org-input">
                        <label for="new-org-nickname">Organization nickname</label>
                        <input mdbInput type="text" id="new-org-nickname" name="nickname" class="form-control" [defaultValue]="organization.nickname || ''" placeholder="Organization Nickname" formControlName="nickname">
                    </div>
                    <!-- Org Initials -->
                    <div class="new-org-input">
                        <label for="new-org-initials">Organization initials</label>
                        <input mdbInput type="text" id="new-org-initials" name="initials" class="form-control" placeholder="Organization Initials" [defaultValue]="organization.initials || ''" formControlName="initials">
                    </div>

                    <!-- Org About -->
                    <div class="new-org-input">
                        <label for="new-org-about">About Organization <span class="required">*</span></label>
                        <textarea mdbInput id="new-org-about" name="about" class="form-control" [defaultValue]="organization.about || ''" placeholder="A short description of your organization" rows="6" formControlName="about">
                        </textarea>
                    </div>

                    <!-- Org Twitter -->
                    <div class="new-org-input">
                        <label for="new-org-twitter">Organization Twitter <small class="text-muted">(Full URL)</small></label>
                        <input mdbInput type="text" id="new-org-twitter" name="twitter" class="form-control" placeholder="Twitter" [defaultValue]="organization?.socialMedia?.twitter || ''" formControlName="twitter">
                        <div *ngIf="orgTwitter.invalid && (orgTwitter.dirty || orgTwitter.touched)" class="alert alert-danger">
                            <div *ngIf="orgTwitter.errors['needsHttps']">
                                <strong>Invalid URL: </strong>{{ orgTwitter.errors['needsHttps'].desc }}
                            </div>
                            <div *ngIf="orgTwitter.errors['invalidUrl']">
                                <strong>Invalid URL: </strong>{{ orgTwitter.errors['invalidUrl'].desc }}
                            </div>
                        </div>
                    </div>

                    <!-- Org Facebook -->
                    <div class="new-org-input">
                        <label for="new-org-facebook">Organization Facebook <small class="text-muted">(Full URL)</small></label>
                        <input mdbInput type="text" id="new-org-facebook" name="facebook" class="form-control" placeholder="Facebook" [defaultValue]="organization?.socialMedia?.facebook || ''" formControlName="facebook">
                        <div *ngIf="orgFacebook.invalid && (orgFacebook.dirty || orgFacebook.touched)" class="alert alert-danger">
                            <div *ngIf="orgFacebook.errors['needsHttps']">
                                <strong>Invalid URL: </strong>{{ orgFacebook.errors['needsHttps'].desc }}
                            </div>
                            <div *ngIf="orgFacebook.errors['invalidUrl']">
                                <strong>Invalid URL: </strong>{{ orgFacebook.errors['invalidUrl'].desc }}
                            </div>
                        </div>
                    </div>

                    <!-- Org LinkedIn -->
                    <div class="new-org-input">
                        <label for="new-org-linkedin">Organization Linkedin <small class="text-muted">(Full URL)</small></label>
                        <input mdbInput type="text" id="new-org-linkedin" name="linkedin" class="form-control" placeholder="Linkedin" [defaultValue]="organization?.socialMedia?.linkedin || ''" formControlName="linkedin">
                        <div *ngIf="orgLinkedIn.invalid && (orgLinkedIn.dirty || orgLinkedIn.touched)" class="alert alert-danger">
                            <div *ngIf="orgLinkedIn.errors['needsHttps']">
                                <strong>Invalid URL: </strong>{{ orgLinkedIn.errors['needsHttps'].desc }}
                            </div>
                            <div *ngIf="orgLinkedIn.errors['invalidUrl']">
                                <strong>Invalid URL: </strong>{{ orgLinkedIn.errors['invalidUrl'].desc }}
                            </div>
                        </div>
                    </div>

                    <!-- Org Instagram -->
                    <div class="new-org-input">
                        <label for="new-org-instagram">Organization Instagram <small class="text-muted">(Full URL)</small></label>
                        <input mdbInput type="text" id="new-org-instagram" name="instagram" class="form-control" placeholder="Instagram" [defaultValue]="organization?.socialMedia?.instagram || ''" formControlName="instagram">
                        <div *ngIf="orgInstagram.invalid && (orgInstagram.dirty || orgInstagram.touched)" class="alert alert-danger">
                            <div *ngIf="orgInstagram.errors['needsHttps']">
                                <strong>Invalid URL: </strong>{{ orgInstagram.errors['needsHttps'].desc }}
                            </div>
                            <div *ngIf="orgInstagram.errors['invalidUrl']">
                                <strong>Invalid URL: </strong>{{ orgInstagram.errors['invalidUrl'].desc }}
                            </div>
                        </div>
                    </div>

                    <!-- Org Phone -->
                    <div class="new-org-input">
                        <label for="new-org-phone">Organization Phone Number</label>
                        <input mdbInput type="tel" id="new-org-phone" name="telephoneNumber" class="form-control" placeholder="(###) ###-####" [defaultValue]="organization.telephoneNumber || ''" [value]="orgTelephoneNumber.value | phoneNormalization" formControlName="telephoneNumber">
                    </div>

                    <!-- Org Address -->
                    <div class="new-org-input">
                        <label for="new-org-address">Organization address <span class="required">*</span> </label>
                        <input mdbInput type="text" id="new-org-address" name="address" class="form-control" required placeholder="Organization address" [defaultValue]="organization.address || ''" formControlName="address">
                    </div>

                    <!-- City & State -->
                    <div class="new-org-split-input-wrapper">
                        <!-- City -->
                        <div class="new-org-half-input">
                            <label for="new-org-city">City <span class="required">*</span></label>
                            <input mdbInput type="text" id="new-org-city" name="city" class="form-control" required [defaultValue]="organization.city || ''" placeholder="City" formControlName="city">
                        </div>

                        <!-- State -->
                        <div class="new-org-half-input">
                            <label for="new-org-state">State <span class="required">*</span></label>
                            <mat-form-field class="new-org-select-search" id="new-org-state">
                                <mat-select [multiple]="false" formControlName="state" required>
                                    <mat-option value="CO">Colorado</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

<!--                    County & Zip code-->
                    <div class="new-org-split-input-wrapper">
                        <!-- County -->
                        <div class="new-org-half-input">
                            <label for="new-org-county">County <span class="required">*</span></label>
                            <mat-form-field class="new-org-select-search" id="new-org-county">
                                <mat-select [placeholder]="organization.county" [multiple]="false" formControlName="county" #countySelect required>
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="countyFilterCtrl" noEntriesFoundLabel="No matching county found" placeholderLabel="County">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let county of filteredCounties | async" [value]="county">
                                        {{county}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <!-- Zip -->
                        <div class="new-org-half-input">
                            <label for="new-org-zip">Zip code <span class="required">*</span></label>
                            <input mdbInput type="text" id="new-org-zip" name="zip" class="form-control" required [defaultValue]="organization.zip || ''" placeholder="Zip code" formControlName="zip">
                        </div>
                    </div>

                    <!-- Org Website -->
                    <div class="new-org-input">
                        <label for="new-org-website">Organization website</label>
                        <input mdbInput type="text" id="new-org-website" name="website" class="form-control" [defaultValue]="organization.website || ''" placeholder="Website URL" formControlName="website">
                        <div *ngIf="orgWebsite.invalid && (orgWebsite.dirty || orgWebsite.touched)" class="alert alert-danger">
                            <div *ngIf="orgWebsite.errors['invalidUrl']">
                                Invalid URL
                            </div>
                        </div>
                    </div>

                    <!-- Org Role -->
                    <div class="new-org-input">
                        <label for="new-org-role">Organization role <span class="required">*</span></label>
                        <mat-form-field class="new-org-select-search" id="new-org-role">
                            <mat-select [formControl]="rolesMultiCtrl" placeholder="Organization role" [multiple]="true" name="organizationRole" ngDefaultControl [(value)]="rolesMultiCtrl.value" #roleMultiSelect formControlName="organizationRole">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="rolesMultiFilterCtrl" placeholderLabel="Organization role">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let role of filteredRolesMulti | async" [value]="role">
                                    {{role}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- Org Activities -->
                    <div class="new-org-input">
                        <label for="new-org-activity">Activity focus <span class="required">*</span></label>
                        <mat-form-field class="new-org-select-search" id="new-org-activity">
                            <mat-select [formControl]="activityMultiCtrl" placeholder="Activity focus" name="organizationFocus" ngDefaultControl [multiple]="true" #activityMultiSelect formControlName="organizationFocus">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="activityMultiFilterCtrl" placeholderLabel="Activity focus">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let act of filteredActivityMulti | async" [value]="act">
                                    {{act}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <!-- Populations Served -->
                    <div class="new-org-input">
                        <label for="new-org-populations">Populations served <span class="required">*</span></label>
                        <mat-form-field class="new-org-select-search" id="new-org-populations">
                            <mat-select [formControl]="popMultiCtrl" placeholder="Populations served" name="populationServed" ngDefaultControl [multiple]="true" [(value)]="popMultiCtrl.value" #populationMultiSelect formControlName="populationServed">
                                <mat-option>
                                    <ngx-mat-select-search [formControl]="popMultiFilterCtrl" placeholderLabel="Populations served">
                                    </ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let pop of filteredPopMulti | async" [value]="pop">
                                    {{pop}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="orgPopulationsServed.invalid && (orgPopulationsServed.dirty || orgPopulationsServed.touched)" class="alert alert-danger">
                            <div *ngIf="orgPopulationsServed.hasError('required') ||
                            orgPopulationsServed.hasError('minLength')">
                                <strong>Required: </strong> Please select the population(s) your organization serves
                            </div>
                        </div>
                    </div>

                    <!-- Contact Message -->
                    <div class="new-org-input">
                        <label for="new-org-contact-message">Contact Message</label>
                        <textarea mdbInput id="new-org-contact-message" name="contactMessage" class="form-control" placeholder="A warm message about always listening to a communities concern and looking for new people to connect with. Not too long about 3-5 sentences should be good! It could be telling them that phone is better than email for certain things they want to talk about such as volunteering or donations and etc." rows="6" [defaultValue]="organization.contactMessage || ''" formControlName="contactMessage"></textarea>
                    </div>

                    <!-- Org Email -->
                    <div class="new-org-input">
                        <label for="new-org-email">
                            Organization contact email
                            <small class="text-muted">(Will not be displayed publicly)</small>
                        </label>
                        <input mdbInput type="email" id="new-org-email" name="email" class="form-control" placeholder="Contact email" [defaultValue]="organization.email || ''" formControlName="email">
                        <div *ngIf="orgEmail.invalid && (orgEmail.dirty || orgEmail.touched)" class="alert alert-danger">
                            <div *ngIf="orgEmail.errors['invalidEmail']">
                                Invalid Email Address
                            </div>
                        </div>
                    </div>

                    <!-- Work Hours -->
                    <div class="new-org-input">
                        <label>Work Days and Hours (Select checkbox if no work hours that day.) </label>
                        <table class="table table-bordered">
                            <tr>
                                <td></td>
                                <td>Start Time</td>
                                <td>End Time</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td><br>Monday</td>
                                <td>
                                    <timepicker [(ngModel)]="mondayStartTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noMondayHours">
                                    </timepicker>
                                </td>
                                <td>
                                    <timepicker [(ngModel)]="mondayEndTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noMondayHours">
                                    </timepicker>
                                </td>
                                <td>
                                    <br>
                                    <mat-checkbox [(ngModel)]="noMondayHours" [ngModelOptions]="{standalone: true}" color="primary">
                                        No Work Hours
                                    </mat-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td><br>Tuesday</td>
                                <td>
                                    <timepicker [(ngModel)]="tuesdayStartTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noTuesdayHours">
                                    </timepicker>
                                </td>
                                <td>
                                    <timepicker [(ngModel)]="tuesdayEndTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noTuesdayHours">
                                    </timepicker>
                                </td>
                                <td>
                                    <br>
                                    <mat-checkbox [(ngModel)]="noTuesdayHours" [ngModelOptions]="{standalone: true}" color="primary">No Work Hours</mat-checkbox>
                                </td>
                            </tr>
                            <tr>
                                <td><br>Wednesday</td>
                                <td>
                                    <timepicker [(ngModel)]="wednesdayStartTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noWednesdayHours">
                                    </timepicker>
                                </td>
                                <td>
                                    <timepicker [(ngModel)]="wednesdayEndTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noWednesdayHours">
                                    </timepicker>
                                </td>
                                <td><br><mat-checkbox [(ngModel)]="noWednesdayHours" [ngModelOptions]="{standalone: true}" color="primary">No Work Hours</mat-checkbox></td>
                            </tr>
                            <tr>
                                <td><br>Thursday</td>
                                <td><timepicker [(ngModel)]="thursdayStartTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noThursdayHours"></timepicker></td>
                                <td><timepicker [(ngModel)]="thursdayEndTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noThursdayHours"></timepicker></td>
                                <td><br><mat-checkbox [(ngModel)]="noThursdayHours" [ngModelOptions]="{standalone: true}" color="primary">No Work Hours</mat-checkbox></td>
                            </tr>
                            <tr>
                                <td><br>Friday</td>
                                <td><timepicker [(ngModel)]="fridayStartTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noFridayHours"></timepicker></td>
                                <td><timepicker [(ngModel)]="fridayEndTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noFridayHours"></timepicker></td>
                                <td><br><mat-checkbox [(ngModel)]="noFridayHours" [ngModelOptions]="{standalone: true}" color="primary">No Work Hours</mat-checkbox></td>
                            </tr>
                            <tr>
                                <td><br>Saturday</td>
                                <td><timepicker [(ngModel)]="saturdayStartTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noSaturdayHours"></timepicker></td>
                                <td><timepicker [(ngModel)]="saturdayEndTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noSaturdayHours"></timepicker></td>
                                <td><br><mat-checkbox [(ngModel)]="noSaturdayHours" [ngModelOptions]="{standalone: true}" color="primary">No Work Hours</mat-checkbox></td>
                            </tr>
                            <tr>
                                <td><br>Sunday</td>
                                <td><timepicker [(ngModel)]="sundayStartTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noSundayHours"></timepicker></td>
                                <td><timepicker [(ngModel)]="sundayEndTime" [ngModelOptions]="{standalone: true}" [mousewheel]="true" [showMeridian]="false" [showSpinners]="false" [disabled]="noSundayHours"></timepicker></td>
                                <td><br><mat-checkbox [(ngModel)]="noSundayHours" [ngModelOptions]="{standalone: true}" color="primary">No Work Hours</mat-checkbox></td>
                            </tr>
                        </table>
                    </div>

                    <!-- Edit banner -->
                    <div class="new-org-input">
                        <label for="new-org-email">Organization Banner</label><br>
                        <button type="button" style="height: 3em;border-radius: 5px; background: #1F4E78; color: white;font-size: 1em;" (click)="processFile(imageInput)">Upload</button>
                        <label class="btn">
                            <input #imageInput type="file" accept="image/*" onchange="bannerPicked = true;
                                    document.getElementById('banner-preview').src = window.URL.createObjectURL(this.files[0]);
                                    document.getElementById('banner-preview').style.display = 'block';
                                    document.getElementById('original-banner-preview').style.display = 'none';">
                        </label>
                        <br>
                        <div style="border: 1px solid grey; background-color: gainsboro; min-width: 300px; min-height: 100px;">
                            <img *ngIf="organization.bannerImage && !bannerPicked;" id="original-banner-preview" [src]="organization.bannerImage" alt="your banner" width="300" height="100">
                            <img style="display: none" id="banner-preview" alt="your banner" width="300" height="100">
                        </div>

                        <div *ngIf="selectedBannerFile" class="img-preview-container">
                            <div class="img-preview{{selectedBannerFile.status === 'fail' ? '-error' : ''}}" [ngStyle]="{'background-image': 'url('+ selectedBannerFile.src + ')'}">
                            </div>

                            <div *ngIf="selectedBannerFile.pending" class="img-loading-overlay">
                                <div class="img-spinning-circle"></div>
                            </div>

                            <div *ngIf="selectedBannerFile.status === 'ok'" class="alert alert-success"> Image Uploaded Successfully!</div>
                            <div *ngIf="selectedBannerFile.status === 'fail'" class="alert alert-danger"> Image Upload Failed!</div>
                        </div>
                    </div>

                    <div class="new-org-submit">
                        <button class="card-button" type="submit">Update</button>
                    </div>

                    <!-- Alert for user feedback -->
                    <div role="alert" id="alert" [hidden]="!showAlert" class="alert {{alertType}}">{{alertMessage}}</div>
                </form>
            </div>
        </div>
    </div>
</div>
