import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Organization } from '../../../app/organizations/organization';
import { AuthService } from '../../auth/auth.service';
import { OrgClaimRequest } from '../../interfaces/OrgClaimRequest';
import { UserService } from '../../auth/user.service';
import {TypeaheadDirective, TypeaheadConfig} from 'ngx-bootstrap/typeahead';
import {OrganizationClaimService} from "../../../services/org-claim-request/org-claim-request.service";

export function getTypeaheadConfig(): TypeaheadConfig {
    return Object.assign(new TypeaheadConfig());
}
@Component({
    selector: 'verify-claims',
    templateUrl: './verify-claims.html',
    styleUrls: ['./verify-claims.scss'],
    providers: [{provide: TypeaheadConfig, useFactory: getTypeaheadConfig}]
})
export class VerifyClaimsComponent {
    /*===============================
            [typeahead] Organization Roles */
    public selectedRole: string;
    public orgRoles: string[] = [
        'Web/Digital Content Manager', 'Administrator', 'Marketing', 'Public Relations',
    ];

    @Input()
    index: number;

    @Input()
    allClaims: OrgClaimRequest[];

    @Output()
    recentlyChangedClaims: EventEmitter<any[]> = new EventEmitter<any[]>();

    recentlyChanged: any[];

    static parameters = [BsModalRef, AuthService, OrganizationClaimService];

    constructor(public bsModalRef: BsModalRef, public authService: AuthService, public organizationClaimService: OrganizationClaimService) {
        this.authService = authService;
        console.log(this.organizationClaimService)
    }
    ngOnInit(){
        console.log(this.allClaims);
    }

    approveClaim(claim){
        this.recentlyChanged = [];
        console.log(claim);
        this.organizationClaimService.approve(claim).subscribe(()=>{
            claim.status="approved";
            this.recentlyChanged.push(claim);

            //deny all other claims for org
            this.organizationClaimService.getClaimsByOrg(claim.orgId,"pending").subscribe((orgClaims:any)=>{

                orgClaims.claims.forEach((orgClaim)=>{
                    this.organizationClaimService.deny(orgClaim).subscribe(()=> {
                            orgClaim.status="denied";
                            this.recentlyChanged.push(orgClaim);
                        }
                    );

                })
                this.recentlyChangedClaims.emit(this.recentlyChanged);

            });

        });

        this.bsModalRef.hide()
    }

    denyClaim(claim){
        console.log("denied")
        this.organizationClaimService.deny(claim).subscribe();
        claim.status="denied";
        this.recentlyChanged = [claim];
        this.recentlyChangedClaims.emit(this.recentlyChanged);
        this.bsModalRef.hide()

    }
    prev(){
        if(this.index > 0){
            this.index--;
        }
    }

    next(){
        if(this.index < this.allClaims.length-1){
            this.index++;
        }
    }

}
