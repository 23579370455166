import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router} from '@angular/router';
import { FormControl} from '@angular/forms';
import { OrganizationService } from '../../../services/organization/organization.service';
import { ResourceService } from '../../../services/resource/resource.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {MatChipInputEvent} from '@angular/material/chips';
import {MatSelect} from '@angular/material/select';
import { Resource } from '../../resources/resource';
import { Organization } from 'client/app/organizations/organization';
import {phoneNormalization} from '../../../components/util';
import {resourceTypeOptions, resourceIssueAreaOptions} from '../../../../server/config/environment/shared';

export interface Keyword {
    keyName: string;
}

@Component({
    selector: 'edit-resource-details',
    templateUrl: './edit-resource-details.html',
    styleUrls: ['./edit-resource-details.scss', '../create-resource/create-resource.scss']
})
export class EditResourceDetailsComponent implements OnInit {
    public resourceTypeFormControl: FormControl = new FormControl();
    public resourceCategoryFormControl: FormControl = new FormControl();
    public structuralInequitiesThatImpactHealthFormControl: FormControl = new FormControl();
    public healthEquityAndHealthOutcomesFormControl: FormControl = new FormControl();
    public keywordsFormControl: FormControl = new FormControl();
    public typeMultiFilterCtrl: FormControl = new FormControl();

    public visible = true;
    public removable = true;
    public selectable = true;
    public addOnBlur = true;

    public requestObject = new Resource();
    // Variables for alert feedback
    showAlert = false;
    alertType = null; // 'alert-success' or 'alert-danger'
    alertMessage = null;

    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    Keywords: Keyword[] = [];
    selected = [];
    skinnyOrgs: any[];
    allOrgNames: string[];
    searchOrg: string;

    resource: any;
    resourceId;
    resourceName;
    author;
    publicationYear;
    resourceType;
    resourceURL;

    resourceCategory;
    structuralInequitiesThatImpactHealthFilter;

    static parameters = [ActivatedRoute, OrganizationService, ResourceService, Router];
    // eslint-disable-next-line no-useless-constructor
    constructor(
        public route: ActivatedRoute,
        public organizationService: OrganizationService,
        public resourceService: ResourceService,
        public router: Router
    ) {
        this.route = route;
        this.organizationService = organizationService;
        this.resourceService = resourceService;
        this.router = router;
    }

    // Function to read values from input fields
    getInput(e: any) {
        if (e.target.classList.contains('invalid')) {
            e.target.classList.remove('invalid');
        }
        if (e.target.name === 'telephoneNumber') {
            this.requestObject[e.target.name] = phoneNormalization(e.target.value);
            e.target.value = phoneNormalization(e.target.value);
        }
        this.requestObject[e.target.name] = e.target.value;
    }
    types: string[] = resourceTypeOptions;
    categories: string[] = resourceIssueAreaOptions;
    structuralInequitiesThatImpactHealthCategories: string[] = ['Transportation', 'Housing/Neighborhood', 'Environment', 'Food Access', 'Education', 'Income', 'Stress, '
    + 'Trauma & Adverse Childhood Experience'];
    healthEquityAndHealthOutcomesCategories: string[] = ['Covid 19', 'Diabetes/Cardiovascular', 'Cancer', 'Environmentally related illness', 'Mental and Behavioral Health'];

    // Function to validate html form inputs
    validateInput(e: any) {
        e.target.classList.add('invalid');
        this.checkSelects();
    }

    // Custom function to check validity of select inputs
    checkSelects(): Boolean {
        let valid = true;
        if (!this.resourceTypeFormControl.value) {
            // eslint-disable-next-line no-undef
            document.getElementById('resource-type').classList.add('invalid');
            valid = false;
        }

        return valid;
    }

    ngOnInit() {
        if (this.route) {
            this.route.paramMap.subscribe(params => {
                this.resourceId = params.get('resourcesUrl');
            });
        }

        // call to get info for all orgs
        this.organizationService.getAllOrgNames().toPromise()
            .then((orgs: Organization[]) => {
                this.skinnyOrgs = [...orgs];
                this.allOrgNames = [...orgs].map(o => o.name);
            })
            .catch(err => console.error(err));

        if (this.route) {
            this.route.paramMap.subscribe(params => {
                this.searchOrg = params.get('organizationName');
            });
        }
        //get details about the resource being edited and populate the form
        this.resourceService.getResourceById(this.resourceId).subscribe((resource: any) => {
            this.resource = resource;
            //set default values for form controls
            this.resourceName = this.resource.resourceTitle;
            this.searchOrg = this.resource.organizationName;
            this.author = this.resource.author;
            this.resourceURL = this.resource.resourceURL;
            this.publicationYear = this.resource.year;
            this.resourceCategory = this.resource.category;
            this.structuralInequitiesThatImpactHealthFilter = this.resource.sdohFilter;
            this.resourceType = this.resource.resourceType;
            this.resourceTypeFormControl.setValue(this.resourceType);
            // Add existing Keywords
            if (resource.keywords) {
                resource.keywords.forEach(keyword => {
                    if ((keyword || '').trim()) {
                        this.Keywords.push({keyName: keyword.trim()});
                    }
                });
            }
            this.resourceCategoryFormControl.setValue(this.resource.category);
            if (this.resource.sdohFilter && this.resource.sdohFilter.length > 0) {
                this.structuralInequitiesThatImpactHealthFormControl.setValue(this.resource.sdohFilter);
            }
            if (this.resource.healthOutcomesFilter && this.resource.healthOutcomesFilter.length > 0) {
                this.healthEquityAndHealthOutcomesFormControl.setValue(this.resource.healthOutcomesFilter);
            }
        });
        // eslint-disable-next-line no-undef
        window.scrollTo(0, 0);
    }

    //manages the keyword adding and removal
    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our Keywords
        if ((value || '').trim()) {
            this.Keywords.push({keyName: value.trim()});
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
    }

    remove(keyword: Keyword): void {
        const index = this.Keywords.indexOf(keyword);

        if (index >= 0) {
            this.Keywords.splice(index, 1);
        }
    }

    @ViewChild('multiSelect', {static: true}) multiSelect: MatSelect;

    // Function to create the new resource in the database on submit
    handleSubmit() {
        // let test = constants.
        if (!this.checkSelects()) {
            this.showAlert = true;
            this.alertType = 'alert-danger';
            this.alertMessage = 'Please fill out all required fields';
            // eslint-disable-next-line no-undef
            window.scrollTo(0, 0);
            return;
        }

        //allow system admins to change organizations to non-member orgs
        if (JSON.parse(localStorage.getItem('user')).role !== 'admin') {
            if (this.allOrgNames.find(o => o === this.searchOrg) === undefined) {
                this.showAlert = true;
                this.alertType = 'alert-danger';
                this.alertMessage = 'Please choose an organization name of an organization in our database';
                return;
            }
        }
        this.requestObject.organizationName = this.searchOrg;
        this.requestObject.organization = this.skinnyOrgs.find(o => o.name === this.searchOrg)?._id;
        this.requestObject.author = this.author;
        this.requestObject.resourceURL = this.resourceURL;

        //add the type
        this.requestObject.resourceType = this.resourceTypeFormControl.value;

        //add the categories to the object
        this.requestObject.category = this.resourceCategoryFormControl.value;
        if (this.requestObject.category.includes('Structural Inequities that Impact Health')) {
            this.requestObject.sdohFilter = this.structuralInequitiesThatImpactHealthFormControl.value;
        }
        if (this.requestObject.category.includes('Health Equity and Health Outcomes')) {
            this.requestObject.healthOutcomesFilter = this.healthEquityAndHealthOutcomesFormControl.value;
        }
        //add the keywords
        if (this.Keywords && this.Keywords.length > 0) {
            this.requestObject.keywords = this.Keywords.map(k => k.keyName);
        }
        console.log(this.requestObject);
        this.requestObject._id = this.resource._id;
        // Call to create resource on the database
        this.resourceService.update(this.requestObject).toPromise().then(() => this.router.navigate(['admin'])).catch((err) => {
            console.error(err);
            this.showAlert = true;
            this.alertType = 'alert-danger';
            this.alertMessage = 'Sorry, there was an error trying to submit your request. Please try again later.';
        });
    }
}
