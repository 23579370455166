<div class="container standard-div standard-div-light-gray">
    <div class="row">
        <!--        Menu displayed on large screen widths   -->
        <div class="d-none d-lg-block col-lg-3 menu">
            <router-outlet name="sidemenu"></router-outlet>
        </div>

        <!--    Wrapper for Funding Page-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-9 funding">
            <!--        Image   -->
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 funding__img-div"></div>

            <!--        Text    -->
            <div class="col-xs-12 col-sm-8 col-md-8 col-lg-12 funding__text">
                <h4 class="funding__text-header">Funding</h4>
                <p class="funding__text-desc">
                    Funding opportunities will be accessible here from local and national foundations posted by organizations
                    and by the DU Health Equity Community Exchange student team through public search.
                </p>
            </div>

            <!--        Menu displayed on small screen widths   -->
            <div class="d-none d-sm-block col-sm-4 d-lg-none menu">
                <router-outlet name="sidemenu"></router-outlet>
            </div>

        </div>
    </div>
</div>

