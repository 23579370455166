import {Component, OnInit, SecurityContext} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {FormControl} from '@angular/forms';
import {BsDropdownConfig} from 'ngx-bootstrap/dropdown';
import {AlertConfig} from 'ngx-bootstrap/alert';
import {PageContentService} from '../../../services/page-content/page-content.service';
import {Card, isCard, isText, PageContent, Text} from '../../../components/interfaces/PageContent';
import shared from '../../../../server/config/environment/shared';

class ImageSnippet {
    pending: boolean = false;
    status: string = 'init';
    image: string = '';

    constructor(public src: string, public file: File) {}
}
export function getAlertConfig(): AlertConfig {
    return Object.assign(new AlertConfig(), {type: 'success'});
}

@Component({
    selector: 'edit-page-content',
    templateUrl: './edit-page-content.html',
    styleUrls: ['./edit-page-content.scss'],
    providers: [{provide: BsDropdownConfig, useValue: {isAnimated: true, autoClose: true}},
        {provide: AlertConfig, useFactory: getAlertConfig}]
})

export class EditPageContentComponent implements OnInit {

    //TODO: Generalize this Component / HTML to apply to Content types other than Cards

    //Using FormControls makes it easy to create two-way binding between the data and the form.
    contentNameFormControl = new FormControl('');
    contentTypeFormControl = new FormControl('');
    contentTextFormControl = new FormControl('');
    contentBtnTextControl = new FormControl('');
    contentBtnLinkControl = new FormControl('');

    selectedFile: ImageSnippet;
    pageContent: PageContent | Card | Text;
    contentTypes: string[] = shared.pageContentTypes;
    pageContentID;
    pageContentName;

    errors = {login: undefined};
    submitted = false;
    showAlert = false;
    alertType = '';
    alertMessage: any;
    alert: any = {
        type: '',
        msg: ``
    };

    quillConfig = {
        // toolbar: '.toolbar',
        toolbar: {
            container: [
                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                ['code-block'],
                [{ header: 1 }, { header: 2 }], // custom button values
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                [{ direction: 'rtl' }], // text direction
                [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ font: [] }],
                [{ align: [] }],
                ['clean'], // remove formatting button
                ['link']
                //['link', 'image', 'video']
            ]
        },
        'emoji-toolbar': true,
        'emoji-textarea': false,
        'emoji-shortname': true,
        keyboard: {
            bindings: {
                shiftEnter: {
                    key: 13,
                    shiftKey: true,
                    handler: (range, context) => {
                        // Handle shift+enter
                        // console.log('shift+enter');
                    }
                },
                enter: {
                    key: 13,
                    handler: (range, context) => {
                        // console.log('enter');
                        return true;
                    }
                }
            }
        }
    };

    alerts: any = [
        {
            type: 'success',
            msg: `<strong>Success!</strong> Content updated Successfully!`
        },
        {
            type: 'danger',
            msg: `<strong>Warning!</strong> Something went wrong when trying to update this content.`
        }
    ];

    isCard = (object: PageContent) => isCard(object);
    isText = (object: PageContent) => isText(object);

    static parameters = [ActivatedRoute, PageContentService, DomSanitizer];

    constructor(public route: ActivatedRoute,
                public pageContentService: PageContentService,
                public sanitizer: DomSanitizer
    ) {
        this.alerts = this.alerts.map((alert: any) => ({
            type: alert.type,
            msg: sanitizer.sanitize(SecurityContext.HTML, alert.msg)
        }));
    }


    ngOnInit() {
        if (this.route) {
            this.route.paramMap.subscribe(params => {
                this.pageContentID = params.get('contentID');
            });
        }
        this.pageContentService.getContentByID(this.pageContentID)
            .toPromise()
            .then((pageContent: PageContent | Card | Text) => {
                this.pageContent = pageContent;
                // set default values in the form using Data from the database
                this.contentNameFormControl.setValue(this.pageContent.name);
                this.contentTypeFormControl.setValue(this.pageContent.contentType);
                this.contentTextFormControl.setValue(this.pageContent.text);

                if (isCard(pageContent)) {
                    this.pageContent = this.pageContent as Card;
                    this.contentBtnTextControl.setValue((<Card> this.pageContent).btn_text);
                    this.contentBtnLinkControl.setValue(this.pageContent.links[0]);
                }
                if (isText(pageContent)) {
                    this.pageContent = this.pageContent as Text;
                }
            })
            .catch(err => {
                console.error(err);
            });
    }

    // TODO: this is to allow for img upload / changed for pageContent
    // updatePageContent(imageSource, pageName) {
    //     // get the values from the form-control and update the pageContent object
    //     if(imageSource !== '') {
    //         this.processFile(imageSource, pageName, function() {
    //             imageSource = this.selectedFile.image;
    //             console.log('image source: ' + imageSource);
    //             this.pageContent.name = this.contentNameFormControl.value;
    //             this.pageContent.contentType = this.contentTypeFormControl.value;
    //             this.pageContent.text = this.contentTextFormControl.value;
    //             if (this.pageContent.contentType.toLocaleLowerCase() === 'card') {
    //                 this.pageContent.btn_text = this.contentBtnTextControl.value;
    //                 this.pageContent.links[0] = this.contentBtnLinkControl.value;
    //                 this.pageContent.image = imageSource;
    //             }
    //
    //             console.log(this.pageContent);
    //             //Finally push those updates to the server using the update() method from the ImageService.
    //             this.pageContentService.update(this.pageContent).subscribe(updatedPageContent => {
    //                 // console.log('success');
    //                 console.log(updatedPageContent);
    //                 this.showAlert = true;
    //                 this.alertMessage = `<strong>Success!</strong> '${this.pageContent.name}' updated successfully.`;
    //                 this.alert.msg = this.alertMessage;
    //                 this.alertType = this.alerts[0].type;
    //                 this.alert.type = this.alertType;
    //             });
    //         });
    //
    //     }
    //     else {
    //         console.log('image source: ' + imageSource);
    //         this.pageContent.name = this.contentNameFormControl.value;
    //         this.pageContent.contentType = this.contentTypeFormControl.value;
    //         this.pageContent.text = this.contentTextFormControl.value;
    //         if (this.pageContent.contentType.toLocaleLowerCase() === 'card') {
    //             this.pageContent.btn_text = this.contentBtnTextControl.value;
    //             this.pageContent.links[0] = this.contentBtnLinkControl.value;
    //             this.pageContent.image = imageSource;
    //         }
    //
    //         console.log(this.pageContent);
    //         //Finally push those updates to the server using the update() method from the ImageService.
    //         this.pageContentService.update(this.pageContent).subscribe(updatedPageContent => {
    //             // console.log('success');
    //             console.log(updatedPageContent);
    //             this.showAlert = true;
    //             this.alertMessage = `<strong>Success!</strong> '${this.pageContent.name}' updated successfully.`;
    //             this.alert.msg = this.alertMessage;
    //             this.alertType = this.alerts[0].type;
    //             this.alert.type = this.alertType;
    //         });
    //     }
    //
    //
    //
    // }

    updatePageContent() {
        // get the values from the form-control and update the pageContent object
        this.pageContent.name = this.contentNameFormControl.value;
        this.pageContent.contentType = this.contentTypeFormControl.value;
        this.pageContent.text = this.contentTextFormControl.value;
        if (isCard(this.pageContent)) {
            this.pageContent = this.pageContent as Card;
            (<Card> this.pageContent).btn_text = this.contentBtnTextControl.value;
            this.pageContent.links[0] = this.contentBtnLinkControl.value;
            (<Card> this.pageContent).btn_link = this.contentBtnLinkControl.value;
        }

        //Finally push those updates to the server using the update() method from the ImageService.
        this.pageContentService.update(this.pageContent).subscribe(updatedPageContent => {
            this.showAlert = true;
            this.alertMessage = `<strong>Success!</strong> '${this.pageContent.name}' updated successfully.`;
            this.alert.msg = this.alertMessage;
            this.alertType = this.alerts[0].type;
            this.alert.type = this.alertType;
        });
    }

    onClosed(dismissedAlert: any): void {
        this.showAlert = false;
    }

    closeAlert() {
        this.showAlert = false;
        this.alertType = '';
        this.alertMessage = '';
    }

    private onSuccess() {
        this.selectedFile.pending = false;
        this.selectedFile.status = 'ok';
    }

    private onError() {
        this.selectedFile.pending = false;
        this.selectedFile.status = 'fail';
        this.selectedFile.src = '';
    }

    processFile(imageInput: any, newName: string, _callback) {
        const file: File = imageInput.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', (event: any) => {
            this.selectedFile = new ImageSnippet(event.target.result, file);
            this.selectedFile.pending = true;
            this.pageContentService.upsertImage(this.selectedFile.file, newName).subscribe(
                (res) => {
                    this.onSuccess();
                    this.selectedFile.image = res._id;
                },
                (err) => {
                    this.onError();
                });
        });
        reader.readAsDataURL(file);
        _callback();
    }
}


