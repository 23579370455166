<!--================ Home Banner Area =================-->
<div class="hero-home">
    <div class="container header-div" id="header-div">
        <!-- Alert element to show feedback to user if necessary -->
        <!--    <div class="main-alert-wrapper" id="main-alert-wrapper">-->
        <!--        <div id="main-page-alert" role="alert" [hidden]="!showAlert" class="alert {{alertType}} main-page-alert">-->
        <!--            {{alertMessage}} <span class="main-alert-close-icon" id="main-alert-close-icon" (click)="closeAlert()">X</span></div>-->
        <!--    </div>-->

        <div class="row">
            <div class="col-md-12">
                <div class="banner">
                    <h1 class="banner__text">Start Connecting Here</h1>
                    <div class="start-search-btn-group">
                        <button type="button" class="start-search__btn">Search Organizations</button>
                        <button type="button" class="start-search__btn">Search Resources</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!--================    END HOME BANNER AREA      =================-->

<!--================ BEGIN MAIN CONTENT AREA =================-->
<div class="container-fluid">
    <!--================ BEGIN MISSION STATEMENT AREA =================-->
    <div class="row fluid-row-mission">
        <div class="container">
            <div class="row mission-statement">
                <div class="hidden-xs quote-spacer"></div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 healthEqQuote-container">
                    <blockquote class="healthEqQuote quote" id="quote">
                        <p>
                            "<span class="colored colored-large">Health Equity</span> is the active assurance that
                            <span class="colored"> everyone is afforded the opportunities </span>
                            essential to attaining their
                            <span class="colored"> highest level of health</span>"
                        </p>
                        <!--        <i class="fas fa-quote-right"></i>-->
                        <footer class="blockquote-footer pull-right cpha" style="">
                            Colorado Public Health Association
                        </footer>
                    </blockquote>
                </div>
            </div>
        </div>
    </div>
    <!--================ END MISSION STATEMENT AREA =================-->
    <!--    <div class="col-xs-12 section-break section-break-left"></div>-->

    <!--================ BEGIN TIPS AREA =================-->
    <div class="row fluid-row-tips">
        <div class="container">
            <div class="row" style="padding: 10px; display: flex; justify-content: center;">
                <p class="tips-header">Tips:</p>
                <ul style="display: inline-block;">
                    <li class="section-desc tip"><b>Are you new here? </b>Browse <a routerLink="/map-search">organizations</a> and <a routerLink="/resources">resources</a>.</li>
                    <li class="section-desc tip"><b>Do you work for a health equity organization? </b><a routerLink="/map-search">Search for it here to claim it.</a></li>
                    <li class="section-desc tip"><b>Do you want to add a new organization? </b><a routerLink="/map-search">Search to see if it already exists before adding it.</a></li>
                    <li class="section-desc tip"><b>Do you want to manage an existing organization you have claimed? </b><a routerLink="/map-search">Search for it to access its details.</a></li>
                </ul>
            </div>
        </div>
    </div>
    <!--================ END TIPS AREA =================-->

    <!--================ BEGIN PURPOSE AREA =================-->
    <div class="row fluid-row-purpose">
        <div class="container">
            <div class="row purpose">
                <span>
                    <div class="column">
                        <p class="big-purpose">Purpose</p>

                        <p class="purpose-section"><u>Provide a platform</u> through which practitioners in many contexts can connect,
                            share resources and coordinate racial and health equity advocacy efforts</p>

                        <p class="purpose-section"><u>Emphasize the primary role of race</u> in creating and maintaining the social,
                        economic and environmental conditions that produce health inequities</p>
                    </div>

                    <div class="column">
                        <p class="purpose-section"><u>Facilitate and amplify collective action</u> by organizations across the field
                            to dismantle the systems that perpetuate racial and health inequities</p>
                        <p class="purpose-section"><u>Communicate news and announcements</u> about events, advocacy alerts,
                        funding and employment opportunities related to racial and health inequity</p>
                        <p class="purpose-section"><u>Educate the public and key stakeholders</u> about racial and health inequity
                        and engage them in advocacy efforts to eliminate inequities</p>
                    </div>
                </span>
            </div>
        </div>
    </div>
    <!--================ END PURPOSE AREA =================-->

    <!--================ BEGIN HEALTH EQ ABOUT AREA =================-->
    <div class="row fluid-row-about">
        <div class="container">
            <div class="row about">
                <div class="col-xs-12 col-sm-12 about__header">
                    Health Equity Community Exchange
                </div>
                <div class="col-xs-12 about__content">
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 image-div about__image-div">
                        <img class="image resources__image" src="/48cde061899c37f053830eff0ece9852.gif" alt="firstPicture">
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 text-justify">
                        <p class="section-desc" id="about-us__text-box">
                            The Health Equity Community Exchange is a co-produced public infrastructure for health
                            equity advocacy powered by the contribution of organizations across Colorado.
                            The site is designed to provide robust information on entities engaged in
                            health equity field work including public health agencies, non-profit organizations, clinics,
                            policy institutes and research centers for the purposes of sharing knowledge and creating
                            collaboration opportunities.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--================    END ABOUT AREA    =================-->
</div>