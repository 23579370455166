import { Component, OnDestroy} from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from '../../auth/auth.service';
import {Router} from '@angular/router';
import {LoginModalComponent} from "../login-modal/login-modal.component";
import {ForgotPasswordModalComponent} from "../forgot-password-modal/forgot-password-modal.component";

interface User {
    password: string;
    confirmPassword: string;
}

@Component({
    selector: 'forgot-password-modal',
    templateUrl: './reset-password-modal.html',
    styleUrls: ['./reset-password-modal.scss'],
})

export class ResetPasswordModalComponent {
    user: User = {
        password: '',
        confirmPassword: ''
    };
    email: string;
    token: string;
    errors = { login: undefined };
    submitted = false;
    AuthService;
    Router;
    return = '/home';
    showAlert = false;
    alertType = '';
    alertMessage = '';

    static parameters = [BsModalRef, AuthService, Router, BsModalService];

    constructor(public bsModalRef: BsModalRef, _AuthService_: AuthService, router: Router, private modalService: BsModalService) {
        this.AuthService = _AuthService_;
        this.Router = router;
    }
    ngOnInit() {
        this.user = {
            password: '',
            confirmPassword: ''
        };
    }

    resetPassword(form) {
        this.closeAlert();
        if (form.invalid) {
            this.showAlert = true;
            this.alertType = 'warning';
            this.alertMessage = 'Please complete all fields.';
            return;
        }

        //Make sure the passwords match //TODO add more validation to the passwords
        if (this.user.password !== this.user.confirmPassword) {
            this.showAlert = true;
            this.alertType = 'warning';
            this.alertMessage = 'The two passwords do not match.';
            return;
        }

        return this.AuthService.resetPassword(this.email, this.token, this.user.password)
            .then(() => {
                this.showAlert = true;
                this.alertType = 'alert-success';
                this.alertMessage = 'Password reset. Please login';
                let delay = 2000; //2 second
                setTimeout(() => {
                    this.bsModalRef.hide();
                    this.Router.navigateByUrl('/home');
                    this.bsModalRef = this.modalService.show(LoginModalComponent);
                }, delay);
            })
            .catch(err => {
                console.log(err);
                this.showAlert = true;
                this.alertType = 'alert-warning';
                this.alertMessage = err.error;
            });
    }

    closeAlert() {
        this.showAlert = false;
        this.alertType = '';
        this.alertMessage = '';
    }

    forgotPassword() {
        this.bsModalRef.hide();
        this.Router.navigateByUrl('/home');
        this.bsModalRef = this.modalService.show(ForgotPasswordModalComponent);
    }

    home() {
        this.bsModalRef.hide();
        this.Router.navigateByUrl('/home');
    }

}
