import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {CreateResourceComponent} from './create-resource.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {AuthGuard} from '../../../components/auth/auth-guard.service';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from '@angular/material/input';
import {MatChipsModule} from '@angular/material/chips';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import {SiteAdminGuard} from "../../../components/auth/site-admin-guard.service";

export const ROUTES: Routes = [
    {
        path: 'create-resource',
        component: CreateResourceComponent,
        canActivate: [SiteAdminGuard],
    }
];

@NgModule({
    imports: [
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatChipsModule,
        BrowserModule,
        TypeaheadModule.forRoot(),
        RouterModule.forChild(ROUTES)],
    declarations: [CreateResourceComponent],

    exports: [CreateResourceComponent],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class CreateResourceModule {
}
