export class Organization {
    name: String;
    _id?: String;
    isClaimed: Boolean;
    status: String;
    nickname?: String;
    initials?: String;
    website?: String;
    contactPoint: String;
    telephoneNumber: String;
    email: String;
    address: String;
    coords: {
        coordinates: Number[]
        type: 'Point'
    };
    city: String;
    contactMessage?: String;
    state: String;
    socialMedia?: {
        twitter?: String;
        facebook?: String;
        linkedin?: String;
        instagram?: String;
    };
    zip: Number;
    county: String;
    region?: String;
    country: String;
    about?: String;
    organizationFocus?: String[];
    organizationType: String[];
    activity: String[];
    population: String[];
    administrators: String[];
    organizationRole?: String[];
    populationServed?: String[];
    hoursOfOperation?: any[];
    bannerImage?: String;
}

export class NewOrganization {
    name: String;
    _id?: String;
    isClaimed: Boolean;
    status: String;
    nickname?: String;
    initials?: String;
    website?: String;
    contactPoint: String;
    telephoneNumber: String;
    email: String;
    address: String;
    coords: {
        coordinates: Number[]
        type: 'Point'
    };
    city: String;
    contactMessage?: String;
    state: String;
    socialMedia?: {
        twitter?: String;
        facebook?: String;
        linkedin?: String;
        instagram?: String;
    };
    zip: Number;
    county: String;
    region?: String;
    country: String;
    about?: String;
    organizationFocus?: String[];
    organizationType: String[];
    activity: String[];
    population: String[];
    administrators: String[];
    organizationRole?: String[];
    populationServed?: String[];
    hoursOfOperation?: any[];
    bannerImage?: String;
}

