import {Component, OnInit, ViewChild, AfterViewInit, Input, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    FormsModule,
    ReactiveFormsModule, ValidationErrors, ValidatorFn,
    Validators
} from '@angular/forms';
import { OrganizationService } from '../../services/organization/organization.service';
import { ResourceService } from '../../services/resource/resource.service';
import {emailValidation, phoneNormalization, isValidUrl} from '../../components/util';
import { Organization } from '../create-organization/new-organization';
import constants from '../app.constants';
import { MatSelect } from '@angular/material/select';
import {ReplaySubject, Subject} from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { ImageService } from '../../services/images/image.service';
import {take, takeUntil} from 'rxjs/operators';

class ImageSnippet {
    pending = false;
    status = 'init';
    image = '';

    // eslint-disable-next-line no-useless-constructor,no-unused-vars
    constructor(public src: string, public file: File) {}
}

@Component({
    selector: 'edit-organization-details',
    templateUrl: './edit-organization.html',
    styleUrls: ['./edit-organization.scss']
})
export class EditOrganizationComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() organization: Organization;

    organizationUrl;
    // Variables for alert feedback
    showAlert = false;
    alertType = null; // 'alert-success' or 'alert-danger'
    alertMessage = null;
    public requestObject = new Organization();
    // eslint-disable-next-line no-useless-constructor
    populationServedOptions: any[] = constants.populationServedOptions;
    organizationRoleOptions: any[] = constants.organizationRoleOptions;
    activityFocusOptions: any[] = constants.organizationFocusOptions;
    countyOptions: any[] = constants.coloradoCounties;

    mondayStartTime: Date = new Date('October 12, 1988 09:00:00'); mondayEndTime: Date = new Date('October 12, 1988 17:00:00'); noMondayHours: Boolean = false;
    tuesdayStartTime: Date = new Date('October 12, 1988 09:00:00'); tuesdayEndTime: Date = new Date('October 12, 1988 17:00:00'); noTuesdayHours: Boolean = false;
    wednesdayStartTime: Date = new Date('October 12, 1988 09:00:00'); wednesdayEndTime: Date = new Date('October 12, 1988 17:00:00'); noWednesdayHours: Boolean = false;
    thursdayStartTime: Date = new Date('October 12, 1988 09:00:00'); thursdayEndTime: Date = new Date('October 12, 1988 17:00:00'); noThursdayHours: Boolean = false;
    fridayStartTime: Date = new Date('October 12, 1988 09:00:00'); fridayEndTime: Date = new Date('October 12, 1988 17:00:00'); noFridayHours: Boolean = false;
    saturdayStartTime: Date; saturdayEndTime: Date; noSaturdayHours: Boolean = true;
    sundayStartTime: Date; sundayEndTime: Date; noSundayHours: Boolean = true;
    selectedBannerFile: ImageSnippet;
    bannerPicked = false;

    updateOrgForm: FormGroup;

    // SETUP - input controls for selects
    public popMultiCtrl: FormControl = new FormControl();
    public popMultiFilterCtrl: FormControl = new FormControl();
    public filteredPopMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

    public activityMultiCtrl: FormControl = new FormControl();
    public activityMultiFilterCtrl: FormControl = new FormControl();
    public filteredActivityMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

    public rolesMultiCtrl: FormControl = new FormControl();
    public rolesMultiFilterCtrl: FormControl = new FormControl();
    public filteredRolesMulti: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

    public countyCtrl: FormControl = new FormControl();
    public countyFilterCtrl: FormControl = new FormControl();
    public filteredCounties: ReplaySubject<string[]> = new ReplaySubject<string[]>(1);

    @ViewChild('multiSelect', { static: true }) multiSelect: MatSelect;
    @ViewChild('roleMultiSelect', { static: true }) roleMultiSelect: MatSelect;
    @ViewChild('populationMultiSelect', { static: true }) populationMultiSelect: MatSelect;
    @ViewChild('activityMultiSelect', { static: true }) activityMultiSelect: MatSelect;
    @ViewChild('countySelect', { static: true }) countySelect: MatSelect;

    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();

    static parameters = [ActivatedRoute, OrganizationService, ResourceService, Router, DomSanitizer, ImageService, FormBuilder];

    constructor(
        public route: ActivatedRoute,
        public organizationService: OrganizationService,
        public resourceService: ResourceService,
        public router: Router,
        public domSanitizer: DomSanitizer,
        public imageService: ImageService,
        private formBuilder: FormBuilder
    ) {
        this.updateOrgForm = this.formBuilder.group([]);
    }

    // Drop down lists
    protected populations: string[] = constants.populationServedOptions;
    protected activities: string[] = constants.organizationFocusOptions;
    protected roles: string[] = constants.organizationRoleOptions;
    protected counties: string[] = constants.coloradoCounties;

    ngOnInit() {
        if (this.route) {
            this.route.paramMap.subscribe(params => {
                this.organizationUrl = params.get('organizationUrl');
                localStorage.setItem('current_org_id', this.organizationUrl);
            });
        }

        if (this.organization) {
            this.requestObject._id = this.organization._id;
            if (this.organization.bannerImage) {
                this.getImageURL(this.organization.bannerImage);
            }
            this.initForm();
        } else {
            this.organizationService.getOrganizationByUrl(this.organizationUrl).toPromise()
                .then((org: any) => {
                    this.organization = org;
                    this.requestObject._id = this.organization._id;
                    if (this.organization.bannerImage) {
                        this.getImageURL(this.organization.bannerImage);
                    }
                })
                .then(() => this.initForm())
                .catch(e => {
                    console.error(e);
                });
        }
    }

    protected initForm() {
        this.updateOrgForm = this.formBuilder.group({
            name: [this.organization.name, [Validators.required]],
            nickname: [this.organization.nickname || ''],
            initials: [this.organization.initials || ''],
            about: [this.organization.about || '', [
                Validators.required, Validators.minLength(1)
            ]],
            email: [this.organization.email || '', [this.validateEmail]],
            contactMessage: [this.organization.contactMessage || ''],
            telephoneNumber: [this.organization.telephoneNumber || '', []],
            website: [this.organization.website || '', [this.validateOrgWebsite]],
            address: [this.organization.address || '', [
                Validators.required, Validators.minLength(1)
            ]],
            //TODO: validate county is in CO
            county: [this.organization.county || '', [Validators.required]],
            city: [this.organization.city || '', [Validators.required, Validators.minLength(1)]],
            state: [this.organization.state || '', [Validators.required, Validators.minLength(1)]],
            zip: [this.organization.zip || '', [Validators.required, Validators.minLength(1)]],
            organizationFocus: [
                this.filterUnknownFromList(this.organization.organizationFocus, 'organizationFocus'),
                [
                    Validators.required, Validators.minLength(1)
                ]],
            organizationRole: [
                this.filterUnknownFromList(this.organization.organizationRole,
                    'organizationRole'), [
                    Validators.required, Validators.minLength(1)
                ]],
            populationServed: [
                this.filterUnknownFromList(this.organization.populationServed, 'populationServed'),
                [
                    Validators.required, Validators.minLength(1)
                ]],

            //TODO: create validator functions for social media URLs
            facebook: [this.organization?.socialMedia?.facebook || '', [
                this.validateSocialMedia('facebook')
            ]],
            twitter: [this.organization?.socialMedia?.twitter || '', [
                this.validateSocialMedia('twitter')
            ]],
            linkedin: [this.organization?.socialMedia?.linkedin || '', [
                this.validateSocialMedia('linkedin')
            ]],
            instagram: [this.organization?.socialMedia?.instagram || '', [
                this.validateSocialMedia('instagram')
            ]],

            // TODO: custom validator to img upload
            bannerImage: [this.organization.bannerImage],
        });

        //set initial selections
        if (this.organization.county) {
            const idx = this.counties.indexOf(this.organization.county.toString());
            if (idx > -1) {
                this.countyCtrl.setValue(this.counties[idx]);
            }
        }
        //FIXME: this repeated use of filterUnknownFromList can probably be done better
        if (this.organization.organizationRole) {
            this.rolesMultiCtrl.setValue(this.filterUnknownFromList(this.organization.organizationRole.slice(), 'organizationRole'));
        }
        if (this.organization.populationServed) {
            this.popMultiCtrl.setValue(this.filterUnknownFromList(this.organization.populationServed.slice(), 'populationServed'));
        }
        if (this.organization.organizationFocus) {
            this.activityMultiCtrl.setValue(this.filterUnknownFromList(this.organization.organizationFocus.slice(), 'organizationFocus'));
        }

        //load initial lists of options
        this.filteredCounties.next(this.counties.slice());
        this.filteredRolesMulti.next(this.roles.slice());
        this.filteredPopMulti.next(this.populations.slice());
        this.filteredActivityMulti.next(this.activities.slice());

        //listen for search field value changes
        this.countyFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => this.filterCounties());
        this.rolesMultiFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => this.filterOrgRoles());
        this.popMultiFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => this.filterPopulationsServed());
        this.activityMultiFilterCtrl.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => this.filterOrgActivities());
    }

    ngAfterViewInit() {
        this.setInitialMultiValues();
    }

    // Destroy function for select inputs
    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    /**
     * Sets the initial values after the filtered values are loaded initially
     */
    protected setInitialMultiValues() {
        this.filteredCounties
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.countySelect.compareWith = (a: string, b: string) => !a && !b && a === b;
            });

        this.filteredRolesMulti
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.roleMultiSelect.compareWith = (a: string, b: string) => !a && !b && a === b;
            });

        this.filteredPopMulti
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.populationMultiSelect.compareWith = (a: string, b: string) => !a && !b && a === b;
            });

        this.filteredActivityMulti
            .pipe(take(1), takeUntil(this._onDestroy))
            .subscribe(() => {
                this.activityMultiSelect.compareWith = (a: string, b: string) => !a && !b && a === b;
            });
    }

    getImageURL(fileKey) {
        this.imageService.download(fileKey).subscribe({
            next: (blob) => {
                const unsafeImgUrl = URL.createObjectURL(blob);
                const sanitizedImgUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(unsafeImgUrl);
                this.organization.bannerImage = sanitizedImgUrl.toString();
            },
            error: (err) => {
                console.error(err);
            }
        });
    }

    // Form action function that checks validity then calls the handle submit function
    formSubmit(e: any) {
        if (this.updateOrgForm.invalid) {
            console.error('Form Invalid!');
            this.showAlert = true;
            this.alertType = 'alert-danger';
            this.alertMessage = 'Incomplete Form: Please fill out all required fields';
            this.scrollToFormAlert();
            return;
        }
        const test = this.updateOrgForm.controls;
        const socialSet = new Set<string>();
        ['facebook', 'instagram', 'linkedin', 'twitter'].forEach(v => socialSet.add(v));

        // copy over all fields to requestObject
        Object.entries(this.updateOrgForm.value)
            // .filter((c: [string, AbstractControl]) => c[1].touched)
            .forEach(([key, val]) => {
                if (socialSet.has(key.toLowerCase())) {
                    if (!this.requestObject.socialMedia) {
                        this.requestObject.socialMedia = {};
                    }
                    this.requestObject.socialMedia[key] = val;
                } else if (key !== 'bannerImage') {
                    this.requestObject[key] = val;
                }
            });
        //TODO: validate Hours of Operation (ensure openingTime < closing time)
        this.requestObject.hoursOfOperation = this.getHoursOfOperation();

        if (this.selectedBannerFile) {
            if (this.selectedBannerFile.status === 'ok') {
                this.requestObject.bannerImage = this.selectedBannerFile.image;
            } else {
                console.error('Error uploading new Banner image');
                return;
            }
        }

        this.handleSubmit();
    }

    /**
     * Filters the dropdown list of counties
     */
    filterCounties() {
        if (!this.counties) {
            return;
        }
        let search = this.countyFilterCtrl.value;
        if (!search) {
            this.filteredCounties.next(this.counties.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        //filter the counties
        this.filteredCounties.next(this.counties.filter(c => c.toLowerCase().indexOf(search) > -1));
    }

    filterOrgRoles() {
        if (!this.roles) return;
        let search = this.rolesMultiFilterCtrl.value;
        if (!search || search.length === 0) {
            this.filteredRolesMulti.next(this.roles.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        //filter the roles
        this.filteredRolesMulti.next(this.roles.filter(r => r.toLowerCase().indexOf(search) > -1));
    }

    filterOrgActivities() {
        if (!this.activities) {
            return;
        }
        let search = this.activityMultiFilterCtrl.value;
        if (!search || search.length === 0) {
            this.filteredActivityMulti.next(this.activities.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        //filter the activities
        this.filteredActivityMulti.next(this.activities.filter(a => a.toLowerCase().indexOf(search) > -1));
    }

    filterPopulationsServed() {
        if (!this.populations) {
            return;
        }
        let search = this.popMultiFilterCtrl.value;
        if (!search || search.length === 0) {
            this.filteredPopMulti.next(this.populations.slice());
            return;
        } else {
            search = search.toLowerCase();
        }
        //filter the roles
        this.filteredPopMulti.next(this.populations.filter(r => r.toLowerCase().indexOf(search) > -1));
    }

    handleSubmit() {
        this.organizationService.update(this.requestObject).toPromise()
            .then((updatedOrganization: any) => {
                this.showAlert = true;
                this.alertType = 'alert-success';
                this.alertMessage = `${updatedOrganization.name} updated successfully`;
                this.scrollToFormAlert();
            })
            .catch(err => {
                this.showAlert = true;
                this.alertType = 'alert-danger';
                this.alertMessage = 'Sorry, there was an error trying to submit your request. Please try again later.';
                this.scrollToFormAlert();
                console.error(err);
            });
    }

    private onSuccess() {
        this.selectedBannerFile.pending = false;
        this.selectedBannerFile.status = 'ok';
    }

    private onError() {
        this.selectedBannerFile.pending = false;
        this.selectedBannerFile.status = 'fail';
        this.selectedBannerFile.src = '';
    }

    scrollToFormAlert() {
        if (this.showAlert) {
            // eslint-disable-next-line no-undef
            let alertElem = document.getElementById('alert');
            alertElem.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
        }
    }

    processFile(imageInput: any) {
        const file: File = imageInput.files[0];
        const reader = new FileReader();

        reader.addEventListener('load', (event: any) => {
            this.selectedBannerFile = new ImageSnippet(event.target.result, file);
            this.selectedBannerFile.pending = true;
            this.imageService.upsertImage(this.selectedBannerFile.file, this.requestObject.name || undefined).subscribe(
                (res) => {
                    this.onSuccess();
                    this.selectedBannerFile.image = res._id;
                },
                (err) => {
                    this.onError();
                    console.error(err);
                });
        });
        reader.readAsDataURL(file);
    }

    // ==== VALIDATORS ====
    validateOrgWebsite(control: AbstractControl): ValidationErrors | null {
        const val = control.value;
        if (val.length === 0) {
            return null;
        }
        return isValidUrl(val) ? null : {invalidUrl: {value: val}};
    }

    validateSocialMedia(site: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            // null if valid
            const prefix = 'https://';
            const val = control.value;
            if (val.length === 0) return null;
            let url = '';
            site = site.toLowerCase();
            if (site === 'facebook') {
                url = 'www.facebook.com/';
            } else if (site === 'instagram') {
                url = 'www.instagram.com/';
            } else if (site === 'linkedin') {
                url = 'www.linkedin.com/';
            } else if (site === 'twitter') {
                url = 'twitter.com/';
            }
            if (!val.startsWith(prefix)) {
                return {
                    needsHttps: {
                        value: control.value,
                        desc: 'URL must begin with \'https://\''
                    }
                };
            }
            return isValidUrl(val) && val.startsWith(`${prefix}${url}`) ? null : {
                invalidUrl: {
                    value: control.value,
                    desc: `URL must begin with '${prefix}${url}'`
                }
            };
        };
    }

    /**
     * validateEmail - ensures that a valid email is entered
     * @param control
     */
    validateEmail(control: AbstractControl): ValidationErrors | null {
        if (control.value.length === 0) return null;
        return emailValidation(control.value) ? null : {invalidEmail: {value: control.value}};
    }

    filterUnknownFromList(arr: String[] | null, controlName?: string): String[] {
        if (!arr || arr.length === 0) return [];
        const unknownIdx = arr.indexOf('Unknown');
        if (unknownIdx >= 0) {
            arr = arr.slice(0, unknownIdx).concat(arr.slice(unknownIdx + 1));
        }
        const undeterminedIdx = arr.indexOf('undetermined');
        if (undeterminedIdx >= 0) {
            arr = arr.slice(0, undeterminedIdx).concat(arr.slice(undeterminedIdx + 1));
        }
        return arr;
    }

    // === GETTERS ===
    private getHoursOfOperation(): any[] {
        let hoursOfOperation = [];
        let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        let startTimes = [this.mondayStartTime, this.tuesdayStartTime, this.wednesdayStartTime, this.thursdayStartTime,
            this.fridayStartTime, this.saturdayStartTime, this.sundayStartTime];
        let endTimes = [this.mondayEndTime, this.tuesdayEndTime, this.wednesdayEndTime, this.thursdayEndTime,
            this.fridayEndTime, this.saturdayEndTime, this.sundayEndTime];
        let noWorkHours = [this.noMondayHours, this.noTuesdayHours, this.noWednesdayHours, this.noThursdayHours,
            this.noFridayHours, this.noSaturdayHours, this.noSundayHours];
        for (let i = 0; i < days.length; i++) {
            if (!noWorkHours[i]) {
                hoursOfOperation.push({
                    dayOfWeek: days[i],
                    noWorkHours: noWorkHours[i],
                    openingTime: startTimes[i].toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false }) || 'N/A',
                    closingTime: endTimes[i].toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false }) || 'N/A'
                });
            } else {
                hoursOfOperation.push({
                    dayOfWeek: days[i],
                    noWorkHours: noWorkHours[i]
                });
            }
        }
        return hoursOfOperation;
    }

    get orgFacebook() {
        return this.updateOrgForm.get('facebook');
    }

    get orgTwitter() {
        return this.updateOrgForm.get('twitter');
    }

    get orgInstagram() {
        return this.updateOrgForm.get('instagram');
    }

    get orgLinkedIn() {
        return this.updateOrgForm.get('linkedin');
    }

    get orgWebsite() {
        return this.updateOrgForm.get('website');
    }

    get orgEmail() {
        return this.updateOrgForm.get('email');
    }

    get orgTelephoneNumber() {
        return this.updateOrgForm.get('telephoneNumber');
    }

    get orgFocus() {
        return this.updateOrgForm.get('organizationFocus');
    }

    get orgRole() {
        return this.updateOrgForm.get('organizationRole');
    }

    get orgPopulationsServed() {
        return this.updateOrgForm.get('populationServed');
    }
}
