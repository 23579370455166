import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from "../../components/auth/user.service";
import {HttpClient} from '@angular/common/http';
import {AuthService} from '../../components/auth/auth.service';

@Component({
    selector: 'emailverify',
    templateUrl: './emailverify.html',
    styleUrls: ['./emailverify.scss']
})

export class EmailVerifyComponent {

    //By default, the alert message is not shown
    public defaultAlert = {
        show: false,
        message: undefined,
        type: undefined,
    };
    public alert = this.defaultAlert;
    public email;
    public token;
    private sub: any;

    static parameters = [ActivatedRoute, UserService, HttpClient, Router, AuthService];

    constructor(private route: ActivatedRoute, private userService: UserService, private http: HttpClient,
                private router: Router, private authService: AuthService) {
        this.route = route;
        this.userService = userService;
        this.http = http;
        this.router = router;
        this.authService = authService;
    }

    ngOnInit() {
        this.sub = this.route.params.subscribe(params => {
            this.email = params.email;
            this.token = params.token;
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe;
    }

    verfiy() {
        this.userService.verifyEmail(this.email, this.token).subscribe({
            next: (data) => {
                let currentUser = localStorage.getItem('user');
                let isLogin = currentUser != null;
                let currentUser_JSON = JSON.parse(currentUser);
                //If the verfication succeeds,
                //redirect to the login if no account logged in,
                //or it depended on wheteher the loggined acount match with the verified account
                if (isLogin) {
                    //if the loggined account match with the email,
                    //then redirect to the homepage.
                    //otherwise, log out current account redirect to the login page
                    if (currentUser_JSON.email == this.email) {
                        this.router.navigateByUrl('');
                    } else {
                        this.authService.logout();
                        this.router.navigateByUrl('/login?alert=verifySuccess');
                    }
                } else {
                    this.router.navigateByUrl('/login?alert=verifySuccess');
                }
            },
            error: (error) => {
                let message = 'Error';
                //and if there's an error, pop up an alert
                if (error.statusText && error.statusText === "Not Found") message = "Email not found!";
                if (error.statusText && error.statusText === "Bad Request") message = "This token is invalid!";
                if (error.error && error.error.message) message = error.error.message;
                if (error && error.status) {
                    this.alert = {
                        show: true,
                        message: String(message),
                        type: 'danger',
                    }
                }
            },
        })
    }

    signupAgain(): void {
        this.router.navigateByUrl('/signup');
    }

    onClosed(): void {
        this.alert = this.defaultAlert;
    }
}
