// @flow
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../components/auth/auth.service';

interface User {
    name: string;
    email: string;
    password: string;
}

@Component({
    selector: 'login',
    templateUrl: './login.html',
    styleUrls: ['./login.scss']
})
export class LoginComponent {
    user: User = {
        name: '',
        email: '',
        password: '',
    };
    errors = { login: undefined };
    submitted = false;
    AuthService;
    Router;
    return = '/home';
    signupQueryParams = '';

    showAlert = false;
    alertType = '';
    alertMessage = '';


    static parameters = [AuthService, Router];
    constructor(_AuthService_: AuthService, router: Router) {
        this.AuthService = _AuthService_;
        this.Router = router;
    }

    ngOnInit() {
        // Get the query params for redirect url
        if(this.Router.currentUrlTree.queryParams.return) {
            this.signupQueryParams = `?return=${this.Router.currentUrlTree.queryParams.return}`;
            this.return = this.Router.currentUrlTree.queryParams.return;
        }
        if(this.Router.currentUrlTree.queryParams.alert === 'auth-required') {
            this.showAlert = true;
            this.alertMessage = 'You must be logged in to visit this page.';
            this.alertType = 'alert-info';
        }
        if(this.Router.currentUrlTree.queryParams.alert === 'verifySuccess') {
            this.showAlert = true;
            this.alertMessage = 'Your email is successfully verified.';
            this.alertType = 'alert-success';
        }
        window.scrollTo(0, 0);
    }

    login(form) {
        this.closeAlert();
        if(form.invalid) {
            console.log(form);
            this.showAlert = true;
            this.alertType = 'warning';
            this.alertMessage = 'Please complete all fields.';
            return;
        }
        console.log(form);

        return this.AuthService.login({
            email: this.user.email.toLowerCase(),
            password: this.user.password
        })
            .then(() => {
                // Logged in, redirect to query param "return" or /home
                this.Router.navigateByUrl(this.return);
            })
            .catch(err => {
                this.errors.login = err.message;
                console.error(err);
                console.log(this.errors.login);
                this.showAlert = true;
                this.alertType = 'alert-warning';
                this.alertMessage = 'An error occurred trying to validate your credentials';
            });
    }

    closeAlert() {
        this.showAlert = false;
        this.alertType = '';
        this.alertMessage = '';
    }
}
