<div class="container standard-div col-12" xmlns="http://www.w3.org/1999/html">
    <h1>Request for a New Organization</h1>
    <p>
        This request was received for the following organization to be added to the database.
        Please reject or accept this pending organization.
    </p>

    <h2 class="text-center section-header">Organization Information</h2>
    <div class="row org-info">
        <section class="org-overview">
            <h3>Overview:</h3>
            <ng-container *ngIf="!pendingOrg">
                <mat-spinner></mat-spinner>
            </ng-container>
            <ng-container *ngIf="pendingOrg">
                <p class="orgName">Name: <strong>{{pendingOrg?.name || 'Organization Name Unknown'}}</strong></p>
                <p class="org-focus">Organization Type:
                    <span *ngFor="let type of pendingOrg?.organizationType; let i = index">
                <strong>{{type}}</strong>
                <span *ngIf="i < pendingOrg?.organizationType.length - 1">, </span>
            </span>
                </p>
                <p class="org-focus">Organization focus(es):
                    <span *ngFor="let focus of pendingOrg.organizationFocus; let i = index">
                <strong>{{focus}}</strong>
                <span *ngIf="i < pendingOrg.organizationFocus.length - 1">, </span>
            </span>
                </p>
                <p class="org-focus">Population(s) served:
                    <span *ngFor="let population of pendingOrg.populationServed; let i = index">
                <strong>{{population}}</strong>
                <span *ngIf="i < pendingOrg.populationServed.length - 1">, </span>
            </span>
                </p>
                <p class="org-focus">Organization Role:
                    <span *ngFor="let role of pendingOrg.organizationRole; let i = index">
                <strong>{{role}}</strong>
                <span *ngIf="i < pendingOrg.organizationRole.length - 1">, </span>
            </span>
                </p>
                <p class="org-focus">
                    Organization Description:
                    <quill-view [content]="pendingOrg.about" format="html" theme="snow"></quill-view>
                </p>
            </ng-container>
        </section>

        <section class="contactInfo-container">
            <ng-container *ngIf="!pendingOrg">
                <mat-spinner></mat-spinner>
            </ng-container>
            <ng-container *ngIf="pendingOrg">
                <h3>Contact Information:</h3>
                <p class="org-address">Address:
                    {{pendingOrg.address}} {{pendingOrg.city}}, {{pendingOrg.state}} {{pendingOrg.zip}}
                </p>
                <p>
                    Website:
                    <a *ngIf="pendingOrg.website" [href]="pendingOrg.website" target="_blank">
                        {{pendingOrg?.website}}
                    </a>
                    <strong *ngIf="!pendingOrg.website">Unknown</strong>
                </p>
                <p>
                    Organization Email:
                    <a *ngIf="pendingOrg.email && !pendingOrg.email.startsWith('N/A')" [href]="'mailto:'+pendingOrg.email">{{pendingOrg.email}}</a>
                    <strong *ngIf="pendingOrg.email && pendingOrg.email.startsWith('N/A')">{{pendingOrg.email}}</strong>
                    <strong *ngIf="!pendingOrg.email">Unknown</strong>
                </p>
                <p>
                    Organization Phone Number:
                    <a *ngIf="pendingOrg.telephoneNumber" [href]="'tel:'+pendingOrg.telephoneNumber">{{pendingOrg.telephoneNumber}}</a>
                    <strong *ngIf="!pendingOrg.telephoneNumber">Unknown</strong>
                </p>
                <p>
                    Admin Name: {{formatFullAdminName(this.pendingOrg.administrators[0].name)}}
                </p>
                <p>
                    Admin Email:
                    <a [href]="'mailto:'+pendingOrg.administrators[0].email">{{pendingOrg.administrators[0].email}}</a>
                </p>
                <p>
                    Contact Point: <strong>{{pendingOrg.contactPoint || 'Unknown'}}</strong>
                </p>
            </ng-container>
        </section>
    </div>

    <div class="btn-area">
        <div>
            <a routerLink="/verify-organizations">&lt; Back to All Pending Organizations
            </a>
        </div>
        <div class="decisionBtns">
            <button class="denyOrg btn-danger" (click)="deny(pendingOrg)">Reject</button>
            <button class="approveOrg btn-success" (click)="approve(pendingOrg)">Approve</button>
        </div>
    </div>
</div>

