import { Component, Input } from '@angular/core';
import { Organization} from '../../organizations/organization';

import { BsModalService } from 'ngx-bootstrap/modal';
import { OrganizationService } from '../../../services/organization/organization.service';
import { ResourceService } from '../../../services/resource/resource.service';
import { AuthService } from '../../../components/auth/auth.service';
import { CreateOrgClaimRequestComponent } from '../../../components/modals/create-org-claim-request.component';
import { OrganizationClaimService } from '../../../services/org-claim-request/org-claim-request.service';
import {LoginPromptComponent} from '../../../components/modals/login-prompt.component';

@Component({
    selector: 'map-search-items',
    templateUrl: './map-search-items.html',
    styleUrls: ['./map-search-items.scss'],
})

export class MapSearchItemsComponent {
    @Input() allOrganizations: Organization[];

    @Input() offset: number;

    @Input() perPage: number;

    static parameters = [OrganizationService, ResourceService,
        AuthService, OrganizationClaimService, BsModalService];
    constructor(
        public organizationService: OrganizationService,
        public resourceService: ResourceService,
        public authService: AuthService,
        public claimService: OrganizationClaimService,
        public modalService: BsModalService
    ) {
        this.modalService = modalService;
        this.authService = authService;
        this.claimService = claimService;
    }

    /**
     * createOrgClaimReq: When a user wants to claim an Org, verify their login status and
     *                      display CreateOrgClaimRequest modal.
     *                      Once that form is submitted, send it to claimService.
     * @param organization
     */
    public createOrgClaimReq(organization: Organization) {
        //Only allow a claim to be submitted by users that are signed in
        if (this.authService.isLoggedInSync()) {
            const claimant = this.authService.getCurrentUserSync();
            const initialState = {
                organization,
                claimant
            };
            const modalRef = this.modalService.show(CreateOrgClaimRequestComponent, {initialState});
            modalRef.content.newOrgClaimReq.subscribe(claimRequest => {
                this.claimService.create(claimRequest)
                    .then(createdClaim => {
                        modalRef.content.formInfo = `Claim request for \'${organization.name}\' submitted!`;
                    })
                    .catch(err => {
                        console.error(err);
                        modalRef.content.formError = err.error.message;
                    });
            });
        } else {
            //If User is not logged in, show prompt
            const initialState = {organization};
            const modalRef = this.modalService.show(LoginPromptComponent, {initialState});
        }
    }
}
