import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


import {RouterModule, Routes} from '@angular/router';

import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {PaginationModule} from 'ngx-bootstrap/pagination';

import {MapSearchComponent} from './map-search.component';
import {SideMenuComponent} from '../../components/side-menu/side-menu.component';

import {AgmCoreModule} from '@agm/core';

import {MapSearchItemsComponent} from './map-search-items/map-search-items.component';

import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';

import {CreateOrgClaimRequestModule} from '../../components/modals/create-org-claim-request.module';
import {CreateOrgClaimRequestComponent} from '../../components/modals/create-org-claim-request.component';
import {PopoverModule} from 'ngx-bootstrap/popover';

import constants from '../../app/app.constants';

export const ROUTES: Routes = [
    {
        path: 'map-search/search',
        component: MapSearchComponent,
    },
    {
        path: 'map-search',
        component: MapSearchComponent,
        children: [
            {
                path: '',
                outlet: 'sidemenu',
                component: SideMenuComponent
            },
            {
                path: 'organizations/:organizationUrl/claim',
                component: CreateOrgClaimRequestComponent,
            },
        ]
    }
];


@NgModule({
    imports: [
        AgmCoreModule.forRoot({
            apiKey: constants.gcp.clientAPIKey,
            libraries: ['places']
        }),
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(ROUTES),
        MatSelectModule,
        MatCheckboxModule,
        MatButtonModule,
        TooltipModule.forRoot(),
        PaginationModule.forRoot(),
        PopoverModule.forRoot(),
        CreateOrgClaimRequestModule,

    ],
    declarations: [
        MapSearchComponent,
        MapSearchItemsComponent
    ],

    exports: [
        MapSearchComponent,
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA,
        NO_ERRORS_SCHEMA
    ]
})
export class MapSearchModule {
}
