import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {JwPaginationComponent} from 'jw-angular-pagination';
import {RouterModule, Routes} from '@angular/router';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {OrganizationDetailsComponent} from './organization-details/organization-details.component';

import {SideMenuComponent} from '../../components/side-menu/side-menu.component';
import {CreateOrgClaimRequestModule} from '../../components/modals/create-org-claim-request.module';
import {CreateOrgClaimRequestComponent} from '../../components/modals/create-org-claim-request.component';
import {LoginPromptModule} from '../../components/modals/login-prompt.module';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {LoginPromptComponent} from '../../components/modals/login-prompt.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatOptionModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TimepickerModule} from 'ngx-bootstrap/timepicker';
import {MatTabsModule} from '@angular/material/tabs';
import {EditOrganizationModule} from '../edit-organization/edit-organization.module';

export const ROUTES: Routes = [
    {
        path: 'organizations/:organizationUrl',
        component: OrganizationDetailsComponent,
        children: [
            {
                path: '',
                outlet: 'sidemenu',
                component: SideMenuComponent
            },
            {
                path: 'organizations/:organizationUrl/claim',
                component: CreateOrgClaimRequestComponent,
            },
            {
                path: 'organizations/:organizationUrl/login',
                component: LoginPromptComponent,
            },
        ]
    }
];


@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        RouterModule.forChild(ROUTES),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        PaginationModule.forRoot(),
        CreateOrgClaimRequestModule,
        LoginPromptModule,
        MatFormFieldModule,
        MatInputModule,
        MatTabsModule,
        ReactiveFormsModule,
        NgxMatSelectSearchModule,
        MatOptionModule,
        MatSelectModule,
        MatCheckboxModule,
        TimepickerModule.forRoot(),
        EditOrganizationModule,
    ],
    declarations: [
        OrganizationDetailsComponent
    ],

    exports: [
        OrganizationDetailsComponent
    ],

    providers: [
        JwPaginationComponent
    ]
})
export class OrganizationsModule {
}
