import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, map, tap} from 'rxjs/operators';
import {CustomEncoder} from '../customEncoder';
import { Organization } from '../../app/organizations/organization';

@Injectable({
    providedIn: 'root'
})
//methods to communicate with the organizations API
export class OrganizationService {
    public baseUrl = '/api/organizations';
    static parameters = [HttpClient];
    constructor(public http: HttpClient) { }

    getAllOrganizations() {
        return this.http.get(this.baseUrl);
    }

    getAllOrgNames() {
        return this.http.get(`${this.baseUrl}/searchByName`);
    }

    getAllOrganizationsPaginated(query: {limit: number, offset: number, searchString: string, filters: any}) {
        return this.http.get(`${this.baseUrl}/paginate`, {
            params: new HttpParams({encoder: new CustomEncoder()})
                .set('limit', JSON.stringify(query.limit))
                .set('offset', JSON.stringify(query.offset))
                .set('searchString', query.searchString)
                .set('filters', JSON.stringify(query.filters))
        });
    }

    getOrganizationByUrl(url) {
        return this.http.get(`${this.baseUrl}/${url}`);
    }

    getOrganizationsByStatus(status) {
        return this.http.get(`${this.baseUrl}/status/${status}`);
    }

    searchForOrganizations(query) {
        return this.http.get(`${this.baseUrl}/search/${query}`);
    }

    //TODO: Add method to perform advanced search for organization
    // https://stackoverflow.com/questions/45428842/angular-url-plus-sign-converting-to-space
    // https://github.com/angular/angular/issues/18261
    searchForOrganizationByLongLat(query) {
        return this.http.get(`${this.baseUrl}/geospatial`, {
            params: new HttpParams({encoder: new CustomEncoder()}).set('filter', JSON.stringify(query))
        });
    }

    //Assumes some organizations might have duplicate names (e.g different branches of same org).
    // Allows us to get resources for that org regardless of its branch
    getResourcesForOrganization(organizationId) {
        return this.http.get(this.baseUrl + '/resources/' + organizationId);
    }

    remove(organization) {
        return this.http.delete(`/api/organizations/${organization.id || organization._id}`)
            .pipe(map(() => organization));
    }

    create(organization) {
        return this.http.post(this.baseUrl, organization);
    }

    update(organization) {
        return this.http.put(`${this.baseUrl}/${organization._id}`, organization, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })

        }).pipe(
            tap(_ =>
                console.log(`updated organization=${organization._id}`))
        );
    }

    approve(organization) {
        return this.http.put(`${this.baseUrl}/${organization._id}/approve`, organization);
    }

    reject(organization) {
        return this.http.put(`${this.baseUrl}/${organization._id}/reject`, organization);
    }

    getKmlCounties() {
        return this.http.get(`${this.baseUrl}/counties`, {responseType: 'blob'});
    }
}


