import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule, Routes} from '@angular/router';
import {CreateOrganizationComponent} from './create-organization.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {AuthGuard} from '../../components/auth/auth-guard.service';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';


export const ROUTES: Routes = [
    {
        path: 'create-organization',
        component: CreateOrganizationComponent,
        canActivate: [AuthGuard],
    }
];

@NgModule({
    imports: [
        NgxMatSelectSearchModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatSelectModule,
        BrowserModule,
        TimepickerModule.forRoot(),
        RouterModule.forChild(ROUTES)],

    declarations: [CreateOrganizationComponent],

    exports: [CreateOrganizationComponent]
})
export class CreateOrganizationModule {
}
