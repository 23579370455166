<div *ngIf="isBrowseMode">
    <div class="col-lg-12">
        <button *ngIf="isStartPage" class="browse-search-toggle-button" (click)="toggleBrowseMode()">{{simpleOrBrowseButtonText}}</button>
        <button *ngIf="!isStartPage" class="browse-search-toggle-button" style="position: fixed; z-index: 200000" (click)="toggleBrowseMode()">{{simpleOrBrowseButtonText}}</button>
    </div>
<!-- THE FOLLOWING PAGE IS SHOWN WHEN THE RESOURCES TAB IS SELECTED -->
    <div *ngIf="isStartPage" class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="min-height: 300px; padding: 20;">
        <!-- <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="margin: auto;">
             <img src="../../assets/images/Network-diagram.png" alt ="firstPicture" width="100%"/>
         </div>-->
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding-top: 20px;">
            <h3>Browse Resources by Issue Area</h3>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="margin: auto;">
            <p class="section-description">
                The Health Equity Community Exchange resource library is built through a robust public search and contribution of subscribing organizations with the goal of facilitating accessibility to all publications related to advancing health equity.
            </p>
        </div>

        <ul class="issues-container">
            <li *ngFor="let issue of issueAreas" class="issue-container" (click)="currentCategory = issue" [routerLink]="" [queryParams]="{category: issue.area, pageNum: 1}">
                <div class="icon-box">
                    <fa-icon [icon]="issue.icon" style="color: #4E94D1; font-size: 64px; line-height: 74px;"></fa-icon>
                </div>
                <div class="text-box">
                    <p class="issue-text">{{issue.area}}</p>
                </div>

            </li>
        </ul>
    </div>

    <!-- THE FOLLOWING PAGE IS SHOWN WHEN ONE OF THE RESOURCE BUCKETS IS CLICKED ON -->
    <div *ngIf="!isStartPage">

        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="margin: auto; padding-bottom: 50px; padding-top: 30px; border-bottom: 1px solid black;">
            <p style="float: left;">Choose an issue area...</p>
            <div class="btn-group cats-dropdown" style="float: right;" dropdown>
                <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle cats-dropdown" aria-controls="dropdown-basic">
                    {{currentCategory.area}}
                    <span class="caret"></span>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li role="menuitem" class="dropdown-item" *ngFor="let issue of issueAreas" (click)="currentCategory = issue; currentSubCategory = DEFAULT_SUBCATEGORY_TEXT" [routerLink]="" [queryParams]="{category: issue.area, pageNum: 1}">{{issue.area}}</li>
                </ul>
            </div>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding: auto;">
            <h3 style="color: #4E94D1;">{{currentCategory.area}}</h3>
        </div>

        <!--THE FOLLOWING DROPDOWN IS SHOWN WHEN A ISSUE AREA HAS SUBCATEGORIES-->
        <div *ngIf="currentCategory.subCategories.length > 0" class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="margin: auto; padding-bottom: 50px;border-bottom: 1px solid black">
            <p style="float: left;">Filter your results</p>
            <div class="btn-group cats-dropdown" style="float: right;" dropdown>
                <button id="button-basic-sub" dropdownToggle type="button" class="btn btn-primary dropdown-toggle cats-dropdown" aria-controls="dropdown-basic-sub">
                    {{currentSubCategory}}
                    <span class="caret"></span>
                </button>
                <ul id="dropdown-basic-sub" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic-sub">
                    <li role="menuitem" class="dropdown-item" (click)="currentSubCategory = DEFAULT_SUBCATEGORY_TEXT" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: 1}">{{DEFAULT_SUBCATEGORY_TEXT}}</li>
                    <li role="menuitem" class="dropdown-item" *ngFor="let subCat of currentCategory.subCategories" (click)="updateSubCategory(subCat)" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: subCat, pageNum: 1}">{{subCat}}</li>
                </ul>
            </div>
        </div>

        <div *ngIf="currentResults.length > 0; else noResults" style="padding-top: 50px; text-align: center">
            <ul class="resources-container">
                <li *ngFor="let result of currentResults" class="resource-container">
                    <p class="resource-title">{{result.resourceTitle}}</p>
                    <p class="resource-content">{{result.organizationName}} - <i>{{result.year}}</i></p>
                    <a href="{{result.resourceURL}}" target="_blank" type="button" class="read-more">Read More </a>
                </li>
            </ul>
        </div>
        <div *ngIf="numPages > 0" class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="margin: auto; padding-bottom: 50px; padding-top: 20px; text-align: center;">
            <ul *ngIf="currentCategory.subCategories.length == 0 || (currentCategory.subCategories.length > 0 && currentSubCategory == DEFAULT_SUBCATEGORY_TEXT)" class="pagination" style="display:inline-table">
                <li *ngIf="currentPageNum < 3" style="display: inline-block;">
                    <a *ngIf="currentPageNum > 1" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: currentPageNum - 1}">&laquo;</a>
                    <a [style.color]="isCurrentPage(1) ? '#FC9A6A' : 'black'" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: 1}">1</a>
                    <a [style.color]="isCurrentPage(2) ? '#FC9A6A' : 'black'" *ngIf="numPages >= 2" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: 2}">2</a>
                    <a *ngIf="numPages >= 3" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: 3}">3</a>
                    <a *ngIf="numPages >= 4" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: 4}">4</a>
                    <a *ngIf="numPages >= 5" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: 5}">5</a>
                    <a *ngIf="currentPageNum < numPages" style="display: inline-block;" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: currentPageNum + 1}">&raquo;</a>
                </li>

                <li *ngIf="currentPageNum >= 3 && currentPageNum <= numPages - 2" style="display: inline-block;">
                    <a *ngIf="currentPageNum > 1" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: currentPageNum - 1}">&laquo;</a>
                    <a style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: currentPageNum - 2}">{{currentPageNum - 2}}</a>
                    <a style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: currentPageNum - 1}">{{currentPageNum - 1}}</a>
                    <a style="display: inline-block; color: #FC9A6A;">{{currentPageNum}}</a>
                    <a *ngIf="numPages >= currentPageNum + 1" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: currentPageNum + 1}">{{currentPageNum + 1}}</a>
                    <a *ngIf="numPages >= currentPageNum + 2" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: currentPageNum + 2}">{{currentPageNum + 2}}</a>
                    <a *ngIf="currentPageNum < numPages" style="display: inline-block;" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: currentPageNum + 1}">&raquo;</a>
                </li>

                <li *ngIf="currentPageNum > numPages - 2 && numPages > 5" style="display: inline-block;">
                    <a *ngIf="currentPageNum > 1" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: currentPageNum - 1}">&laquo;</a>
                    <a style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: numPages - 4}">{{numPages - 4}}</a>
                    <a *ngIf="numPages >= 2" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: numPages - 3}">{{numPages - 3}}</a>
                    <a *ngIf="numPages >= 3" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: numPages - 2}">{{numPages - 2}}</a>
                    <a [style.color]="isCurrentPage(numPages - 1) ? '#FC9A6A' : 'black'" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: numPages - 1}">{{numPages - 1}}</a>
                    <a [style.color]="isCurrentPage(numPages) ? '#FC9A6A' : 'black'" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: numPages}">{{numPages}}</a>
                    <a *ngIf="currentPageNum < numPages" style="display: inline-block;" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, pageNum: currentPageNum + 1}">&raquo;</a>
                </li>
            </ul>

            <ul *ngIf="currentCategory.subCategories.length > 0 && currentSubCategory != DEFAULT_SUBCATEGORY_TEXT" class="pagination" style="display:inline-table">
                <li *ngIf="currentPageNum < 3" style="display: inline-block;">
                    <a *ngIf="currentPageNum > 1" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: currentPageNum - 1}">&laquo;</a>
                    <a [style.color]="isCurrentPage(1) ? '#FC9A6A' : 'black'" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: 1}">1</a>
                    <a [style.color]="isCurrentPage(2) ? '#FC9A6A' : 'black'" *ngIf="numPages >= 2" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: 2}">2</a>
                    <a *ngIf="numPages >= 3" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: 3}">3</a>
                    <a *ngIf="numPages >= 4" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: 4}">4</a>
                    <a *ngIf="numPages >= 5" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: 5}">5</a>
                    <a *ngIf="currentPageNum < numPages" style="display: inline-block;" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: currentPageNum + 1}">&raquo;</a>
                </li>

                <li *ngIf="currentPageNum >= 3 && currentPageNum <= numPages - 2" style="display: inline-block;">
                    <a *ngIf="currentPageNum > 1" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: currentPageNum - 1}">&laquo;</a>
                    <a style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: currentPageNum - 2}">{{currentPageNum - 2}}</a>
                    <a style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: currentPageNum - 1}">{{currentPageNum - 1}}</a>
                    <a style="display: inline-block; color: #FC9A6A;">{{currentPageNum}}</a>
                    <a *ngIf="numPages >= currentPageNum + 1" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: currentPageNum + 1}">{{currentPageNum + 1}}</a>
                    <a *ngIf="numPages >= currentPageNum + 2" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: currentPageNum + 2}">{{currentPageNum + 2}}</a>
                    <a *ngIf="currentPageNum < numPages" style="display: inline-block;" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: currentPageNum + 1}">&raquo;</a>
                </li>

                <li *ngIf="currentPageNum > numPages - 2 && numPages > 5" style="display: inline-block;">
                    <a *ngIf="currentPageNum > 1" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: currentPageNum - 1}">&laquo;</a>
                    <a style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: numPages - 4}">{{numPages - 4}}</a>
                    <a *ngIf="numPages >= 2" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: numPages - 3}">{{numPages - 3}}</a>
                    <a *ngIf="numPages >= 3" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: numPages - 2}">{{numPages - 2}}</a>
                    <a [style.color]="isCurrentPage(numPages - 1) ? '#FC9A6A' : 'black'" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: numPages - 1}">{{numPages - 1}}</a>
                    <a [style.color]="isCurrentPage(numPages) ? '#FC9A6A' : 'black'" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: numPages}">{{numPages}}</a>
                    <a *ngIf="currentPageNum < numPages" style="display: inline-block;" style="display: inline-block;" [routerLink]="" [queryParams]="{category: currentCategory.area, subCat: currentSubCategory, pageNum: currentPageNum + 1}">&raquo;</a>
                </li>
            </ul>
        </div>
        <ng-template #noResults>
            <h2 style="text-align: center">No Results!</h2>
        </ng-template>
    </div>

</div>

<div *ngIf="!isBrowseMode">
    <div class="col-lg-12">
        <button class="browse-search-toggle-button" style="position: fixed; z-index: 200000" (click)="toggleBrowseMode()">{{simpleOrBrowseButtonText}}</button>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="min-height: 500px; padding: 20px;">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="margin: auto;">
            <div class="search_box">
                <div class="searchbar justify-content-center pull-right">
                    <input class="search_input" type="text" name="" [placeholder]="placeholder" [value]="searchBarValue ? searchBarValue : ''" (keydown.enter)="onKeyDown(searchTerm)" #searchTerm>
                    <button (click)="search(searchTerm)" class="search_icon"><i class="fa fa-search"></i></button>
                </div>
            </div>
        </div>

        <div *ngIf="!simpleSearchResults || (simpleSearchResults && simpleSearchResults.length < 1)">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12" style="padding-top: 20px;">
                <h3>Simple Search for Resources</h3>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6" style="margin: auto;">
                <p class="section-description">
                    The Health Equity Community Exchange resource library is built through a robust public search and contribution of subscribing organizations with the goal of facilitating accessibility to all publications related to advancing health equity.
                </p>
            </div>
        </div>
        <div *ngIf="simpleSearchResults && simpleSearchResults.length > 0" style="padding-top: 50px; text-align: center">
            <ul class="resources-container">
                <li *ngFor="let result of simpleSearchResults | paginate: {itemsPerPage: 25, currentPage: currentSearchPage }" class="resource-container">
                    <p class="resource-title">{{result.resourceTitle}}</p>
                    <p class="resource-content">{{result.organizationName}} - <i>{{result.year}}</i></p>
                    <a href="{{result.resourceURL}}" target="_blank" type="button" class="read-more">Read More </a>
                </li>
            </ul>
            <pagination-controls class="my-pagination" (pageChange)="currentSearchPage = $event" previousLabel="" nextLabel="">
            </pagination-controls>
        </div>

        <div *ngIf="simpleSearchResults && simpleSearchResults.length < 1">
            <h2 style="text-align: center">No Results!</h2>
        </div>

    </div>

</div>

