// @flow
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

type UserType = {
    // TODO: use Mongoose model
    id?: string;
    _id?: string;
    name?: string;
    email?: string;
};

@Injectable()
export class UserService {
    baseUrl = '/api/users';
    static parameters = [HttpClient];
    constructor(public http: HttpClient) {
        this.http = http;
    }

    query(): Observable<UserType[]> {
        return this.http.get(this.baseUrl) as Observable<UserType[]>;
    }
    get(user: UserType = {id: 'me'}): Observable<UserType> {
        return this.http.get(`${this.baseUrl}/${user.id || user._id}`) as Observable<UserType>;
    }
    create(user: UserType) {
        return this.http.post(this.baseUrl, user);
    }
    changePassword(user, oldPassword, newPassword) {
        return this.http.put(`${this.baseUrl}/${user.id || user._id}/password`, {oldPassword, newPassword});
    }

    getMe() {
        const id_token = localStorage.getItem('id_token');
        return this.http.get(`${this.baseUrl}/me`, {
            headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: `Bearer ${id_token}` })
        });
    }

    getMyOrg() {
        return this.http.get(`${this.baseUrl}/me/organizations`);
    }
    forgotPassword(email) {
        return this.http.put(`${this.baseUrl}/${email}/forgot`, {});
    }

    resetPassword(email, token, newPassword) {
        return this.http.put(`${this.baseUrl}/${email}/passwordreset`, {token, password: newPassword});
    }
    remove(user) {
        return this.http.delete(`${this.baseUrl}/${user.id || user._id}`)
            .pipe(map(() => user));
    }
    upsert(user) {
        return this.http.put(this.baseUrl, user);
    }
    verifyEmail(email, token) {
        // return this.http.put(`api/users/`+email+`/`+token+`/verify`, {});
        return this.http.put(`${this.baseUrl}/${email}/verify/${token}`, {});
    }
}
